import {get,post} from './axios'
// import qs from 'qs'
//获取商品分类列表
export const apiAddressList = p => get('/api/front/address/list',p)

export const apiAddAddress = p =>post('/api/front/address/edit',p)
export const apiSetDefault = p =>post('/api/front/address/default/set',p)
export const apiDeleteAddress = p =>post('/api/front/address/del',p)

//实名认证
export const apiTruthName = p =>post('/api/front/user/bindMoreInfo',p )

//推荐
export const apiGetTJInfo = p =>get('/api/front/commission',p)