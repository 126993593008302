<template>
    <div class="index">
        <ul class="menu">
            <li @click="openMenu('account')">我的资料</li>
            <li @click="openMenu('accountTruthName')">实名认证</li>
            <!-- <li @click="openMenu('accountNews')">消息中心</li> -->
            <li @click="openMenu('accountAddress')">我的地址</li>
            <li @click="openMenu('accountOrder')">我的订单</li>
            <li @click="openMenu('accountCart')">我的购物车</li>
            <li @click="openMenu('accountTuijian')">我的推荐</li>
        </ul>
        <div class="mainOrder" v-if="showOrder">
            <div class="address" v-if="!isOwner">
                <div v-if="orderinfosVal">
                    <span>选择收货地址：</span>
                </div>
                <add-address :addressId='addressId' @callbackAddress='callbackAddress'/>
            </div>
            <el-row class="store" v-if="isOwner">
                <el-col :span="12" @click.native="chooseStore(item.id)" :class="storeId==item.id?'storeItem storeItemActive':'storeItem'" v-for="item in storeList" :key="item.id">
                    <div class="name">
                       <span>{{item.name}}</span> {{item.phone}}
                    </div>
                    <div class="storeaddress">
                        {{item.address}},{{item.detailedAddress}}
                    </div>
                   
                </el-col>
            </el-row>
            <div class="list">
                <div class="count" v-if="orderinfosVal">商品清单</div>
                <div class="listItem" v-for="item in orderinfosVal.orderDetailList" :key="item.productId">
                    <img :src="item.image" alt="">
                    <div class="listItemInfo">
                        <div class="name"><span>{{item.productName}}</span><span>X{{item.payNum}}</span></div>
                        <div class="sku">{{item.sku}}</div>
                        <div>{{item.price}}</div>
                    </div>
                </div>
            </div>
            <div style="height:15px;background: #fff;borderBottom: 1px solid #eee"></div>
            <div class="note">
                <div class="title">使用优惠券</div>
                <div class="couponTitle">
                    <div  class="couponTitleAction">
                        <div @click="handleCoupon(1)" :class="couponIndex==1?'couponTitleActionActive':''">可用优惠券</div>
                        <div @click="handleCoupon(2)" :class="couponIndex==2?'couponTitleActionActive':''">不可用优惠券</div>
                    </div>
                    <el-input placeholder="请输入兑换码" v-model="couponNum"><el-button @click="exchangeCoupon" slot="append">兑换</el-button></el-input>
                </div>
                <div class="couponBox" v-if="couponIndex==1">
                    <el-row v-if="couponUse.length">
                        <template v-for="(item,index) in couponUse">
                            <el-col :class="couponId==item.id?'activedCoupon':''" :span="5" v-if="index%4==0" :key='index' @click.native="chooseCoupon(item)">
                                <div class="name">{{item.name}}</div>
                                <div class="cou">满 <span>{{item.minPrice}}</span> 元减 <span>{{item.money}}</span>元</div>
                            </el-col>
                            <el-col :class="couponId==item.id?'activedCoupon':''" :span="5" v-else :offset="1"  :key='index' @click.native="chooseCoupon(item)">
                                <div class="name">{{item.name}}</div>
                                <div class="cou">满 <span>{{item.minPrice}}</span> 元减 <span>{{item.money}}</span>元</div>
                            </el-col>
                        </template>
                        
                    </el-row>
                    <div v-else>暂未优惠券</div>
                </div>
                <div class="couponBox" v-if="couponIndex==2">
                    <div>暂未优惠券</div>
                </div>
            </div>
            <div style="height:15px;background: #fff;borderBottom: 1px solid #eee"></div>
            <div class="note">
                <div class="title">配送方式</div>
                <div>快递配送 (￥{{orderinfosVal.freightFee}}元)</div>
            </div>
            <div style="height:15px;background: #fff;borderBottom: 1px solid #eee"></div>
            <div class="payType">
                <span class="title">支付方式</span>
                <el-row class="paytypes">
                    <el-col :span="7" :class="isCash=='weixin'?'paytypesActive':''" @click.native="choosePayType('weixin')">
                        <div class="elCol">
                            <span>微信支付</span> 
                            <img :src="require('@/assets/images/wxPay.png')" alt="">
                        </div>
                    </el-col>
                    <el-col :span="7" :offset="1" :class="isCash=='yue'?'paytypesActive':''" @click.native="choosePayType('yue')">
                        <div class="elCol">
                            <span>余额支付</span> 
                            <span>可用余额：{{userInfo.nowMoney}}</span>
                        </div>
                    </el-col>
                    <el-col :span="7" :offset="1" :class="isCash=='brokerage'?'paytypesActive':''" @click.native="choosePayType('brokerage')">
                        <div class="elCol">
                            <span>奖励金支付</span> 
                            <span>奖励金余额：{{userInfo.brokeragePrice}}</span>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <div style="height:15px;background: #fff;borderBottom: 1px solid #eee"></div>
            <div class="price">
                <span>商品数量：</span>
                <span>{{orderinfosVal.orderProNum}}件</span>
            </div>
            <div class="price">
                <span>商品总价：</span>
                <span>￥{{orderinfosVal.proTotalFee}}元</span>
            </div>
            <div class="price">
                <span>运费：</span>
                <span>￥{{orderinfosVal.freightFee}}元</span>
            </div>
            <div class="price" v-if="couponFee">
                <span>优惠券抵扣：</span>
                <span>￥{{couponFee}}元</span>
            </div>
            <div class="price">
                <span style="fontSize: 18px;">应付总额：</span>
                <span>￥{{orderinfosVal.payFee}}元</span>
            </div>
            <div style="height:15px;background: #fff;borderBottom: 1px solid #eee"></div>
            <div class="submit">
                <!-- <span>合计：<span>￥{{orderinfosVal.payFee}}</span></span> -->
                <el-button type="danger" size="medium" @click="addOrder">立即结算</el-button>
            </div>
        </div>
      
        <pay-dialog @finshed='finshed' :show='showPay' :Image='payUrl' :orderNo='orderNo' :price='orderinfosVal.payFee' :totalInfo='orderinfosVal' :type = '2' />
    </div>
</template>
<script>
import {apiAddressList} from '@/api/account'
import {apiGetOrderInfo,apiCreateNewOrder,apiGetStoreList} from '@/api/goods'
import PayDialog from '@/components/PayDialog.vue'
import AddAddress from '@/components/AddAddress.vue'
import { aoiGetUserinfo } from "@/api/login";

export default {
    name: 'basePAge',
    data(){
        return{
            tableData:[],
            total: 0,
            page: 1,
            limit: 99,
            chooseNum: 0,
            choosedItem: [],
            showOrder: true,
            isOwner: false, //是否到店自提
            note: '',
            orderinfos:{},
            userInfo:{},
            preOrderNo: '',
            preOrderType: '',
            preOrderparams: {},
            storeList: [],//门店列表
            storeId: '',
            isCash: 'weixin',
            phone: '',
            realName: '',
            showPay: false,
            payUrl: '',
            orderNo: '',
            addressList: [],
            addressId: '',
            couponIndex: 1,
            couponUse:[],
            couponListOut: [],
            couponId: 0,
            couponNum: '',
            totalSale: 0,
            couponFee: 0
        }
    },
    components:{PayDialog,AddAddress},
    watch:{
        addressId(val){
            if(val){
                this.addressList.forEach(item => {
                    if(item.id == val){
                        this.phone = item.phone
                        this.realName = item.realName
                    }
                });
            }
        }
    },
    mounted(){
        this.preOrderNo = this.$route.query.orderNo
        this.preOrderType = this.$route.query.preOrderType
        this.preOrderparams = this.$route.query.params
        this.getAddressList()
        this.getOrderInfo(this.preOrderNo)
        this.getUserInfo()
        // this.userInfo = JSON.parse(localStorage.getItem('userInfo')) 
        this.getCouponList('usable')
    },
    computed:{
        orderinfosVal(){
            if(this.orderinfos.orderInfoVo){
                return this.orderinfos.orderInfoVo
            }else{
                return {}
            }
            
        }
    },
    methods:{
        chooseCoupon(item){
            
            if(item.minPrice*1>this.orderinfosVal.payFee*1){
                this.couponFee = 0
                this.$message.warning('当前订单未满足使用优惠券的标准')
            }else{
                this.couponId = item.id
                this.getCouponePrice()
            }
        },
        getCouponePrice(){
            let params = {
                "addressId": this.addressId,
                "couponId": this.couponId,
                "preOrderNo": this.preOrderNo,
                "shippingType": this.isOwner?2:1,
                "useIntegral": false
            }
            this.https.post('/api/front/order/computed/price',params).then(res=>{
                console.log(res);
                if(res.data.code == 200){
                    this.$nextTick(()=>{
                    this.orderinfos.orderInfoVo.payFee = res.data.data.payFee

                    })
                    this.couponFee = res.data.data.couponFee
                }
            })
        },
        //获取用户信息
        getUserInfo() {
            aoiGetUserinfo().then((res) => {
                if (res.code == 200) {
                    this.userInfo = res.data;
                }
            });
        },
        choosePayType(str){
            this.isCash = str
        },
        //获取可以优惠券
        getCouponList(type){
            this.https.get('/api/front/coupon/list?type='+type).then(res=>{
                if(res.data.code == 200){
                    if(type == 'usable'){
                        this.couponUse = res.data.data.list ? res.data.data.list: []
                    }else{
                        this.couponListOut = res.data.data.list ? res.data.data.list: []
                    }
                }
            })
        },
        handleCoupon(index){
            this.couponIndex = index
        },
        //兑换优惠券
        exchangeCoupon(){
            if(this.couponNum.length){
                this.$message.warning({
                    message: '功能开发中',
                    duration: 3000
                })
            }
        },
        //选择收货地址 并重新预下单
        callbackAddress(item){
            this.addressId = item.id
            let params = JSON.parse(this.preOrderparams)
            params.addressId = item.id
            this.https.post('/api/front/order/pre/order',params).then(res=>{
                if(res.data.code == 200){
                    this.preOrderNo = res.data.data.preOrderNo
                    this.getOrderInfo(this.preOrderNo)
                }else{
                this.$message.warning(res.message)
                }
            })
        },
        async getAddressList(){
            const result = await apiAddressList()
            if(result.code == 200){
                this.addressList = result.data.list
            }
        },
        finshed(index){
            this.showPay = false
            if(index == 1){
                this.$message.warning({
                    message: '订单支付超时',
                    duration: 3000
                })
            }else if(index == 3){
                this.$message.warning({
                    message: '取消支付',
                    duration: 3000
                })
                this.$router.replace({name: 'accountOrder'})
            }else{
                   this.$router.replace({name: 'accountOrder'})
                this.$message.success({
                    message: '订单支付完成',
                    duration: 3000
                })
            }
        },
        openMenu(index){
            localStorage.setItem('accountIndex','accountCart')
            this.$router.push({name: index})
        },
 
        //
        changeOwner(index){
            this.isOwner = index
            if(index){
                this.getStoreList()
            }else{
                this.phone = ''
                this.realName = ''
            }
        },
        //门店列表
        getStoreList(){
            apiGetStoreList({}).then(res=>{
                if(res.code == 200){
                    this.storeList = res.data.list
                    this.storeId = res.data.list[0].id
                }
            })
        },
        //选择门店
        chooseStore(id){
            this.storeId = id
        },

        //获取预下单信息
        getOrderInfo(id){
            apiGetOrderInfo({id}).then(res=>{
                if(res.code == 200){
                    this.showOrder = true
                    this.orderinfos = res.data
                    this.addressId = res.data.orderInfoVo.addressId
                    this.phone = res.data.orderInfoVo.phone
                    this.realName = res.data.orderInfoVo.realName
                }
            })
        },
        addOrder(){
            if(!this.addressId){
                this.$message.warning('请选择收货地址')
                return false
            }
         
            if(this.isCash == 'weixin'){
                let params = {
                addressId: this.addressId,
                couponId: this.couponId,
                mark: this.note,
                payChannel: "native",
                payType: this.isCash,
                phone: this.phone,
                preOrderNo: this.preOrderNo,
                realName: this.realName,
                shippingType: this.isOwner?2:1,
                storeId: this.storeId,
                useIntegral: false
                }
           
                apiCreateNewOrder(params).then(res=>{
                    if(res.code == 200){
                        let params1 = {
                            orderNo: res.data.orderNo,
                            payChannel: "native",
                            payType: "weixin",
                            scene: 0
                        }
                        this.https.post('api/front/pay/payment',params1).then(res=>{
                            if(res.data.code == 200){
                                this.showPay = true
                                this.payUrl = res.data.data.qrCodeImageBase64
                                this.orderNo = res.data.data.orderNo
                            }else{
                                this.$message.warning({
                                    message: res.message,
                                    duration: 3000
                                })
                            }
                        })
                    }else{
                        this.$message.warning({
                                message: res.message,
                                duration: 3000
                            })
                    }
                    
                })
            }else {
                  let params = {
                addressId: this.addressId,
                couponId: this.couponId,
                mark: this.note,
                payChannel: "routine",
                payType: this.isCash,
                phone: this.phone,
                preOrderNo: this.preOrderNo,
                realName: this.realName,
                shippingType: this.isOwner?2:1,
                storeId: this.storeId,
                useIntegral: false
                }
           
                apiCreateNewOrder(params).then(res=>{
                    if(res.code == 200){
                        this.https.post('/api/front/pay/payment',{
                            orderNo: res.data.orderNo,
                            payChannel: "routine",
                            payType: this.isCash,
                            scene: 0,
                        }).then(ress=>{
                            if(ress.data.code == 200){
                                this.$message.success('支付成功')
                                this.$router.replace({name:'accountOrder'})
                            }else{
                                this.$message.warning(ress.data.message)
                            }
                        })
                    }else{
                        this.$message.warning({
                                message: res.message,
                                duration: 3000
                            })
                    }
                })
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.index {
  width: 1200px;
  margin: 0 auto;
  background-color: #f5f6f7;
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  .menu{
      width: 200px;
      background-color: #fff;
      li{
          line-height: 60px;
          border-bottom: 1px solid #ccc;
          font-size: 16px;
      }
  }
  .main{
      width: 980px;
      background-color: #fff;
      .title{
          height: 70px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 20px;
          border-bottom: 1px dashed blue;
          .titleLeft{
              display: flex;
              align-items: center;
              justify-content: flex-start;
              img{
                  margin-left: 15px;
                  height: 50px!important;
                  width: 100px!important;
              }
              span{
                  margin-left: 15px;
              }
          }
      }
      .goods{
          display: flex;
          align-items: center;
          span{
              line-height: 36px;
          }
      }
  }
}
.mainBox{
    padding: 20px;
    background-color: #fff;
    .tableBar{
        margin-top: 15px;
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        height: 40px;
        align-items: center;
        padding: 0 20px;
        .tableBarR{
             display: flex;
             align-items: center;
             justify-content: flex-start;
             .money{
                 font-size: 20px;
                 color: orange;
             }
             .el-button{
                 margin-left: 15px;
                 width: 150px;
                 background-color: orange;
                 color: #fff;
                 &:hover{
                     color: #fff!important;
                 }
             }
        }
    }
}
.cartTable{
    border: 1px solid #eee;
}
.mainOrder{
    text-align: left;
    width: 980px;
      background-color: #fff;
      .getType{
          height: 40px;
          line-height: 40px;
          text-align: center;
          border: 1px solid #f3f4f5;
          cursor: pointer;
      }
      .getTypeActive{
          background-color:  #f7c1bd;
          color: #E93323;
          border-radius: 5px;
      }
    .address{
        background-color: #fff;
        border: 1px solid #f3f4f5;
        padding: 10px 20px;
        line-height: 36px;
        span{
            margin-right: 15px;
        }
        .sku{
            color: red;
        }
    }
    .store{
        .storeItem{
            padding: 20px;
            border: 1px solid #f3f4f5;
            line-height: 28px;
            cursor: pointer;
            .name{
                font-size: 16px;
                font-weight: bold;
            }
        }
        .storeItemActive{
             background-color:  #f7c1bd;
          color: #E93323;
          border-radius: 5px;
        }
    }
    .list{
        padding: 15px 25px;
        .count{
            line-height: 50px;
            font-size: 16px;
        }
        .listItem{
            display: flex;
            align-items: center;
            img{
                width: 100px;
                height: 100px;
                margin-right: 20px;
            }
            .listItemInfo{
                line-height: 36px;
                .name{
                    width: 400px;
                    display: flex;
                    justify-content: space-between;
                }
                .sku{
                    font-size: 14px;
                    color: red;
                }
            }
        }
        
    }
    .note{
        padding: 0 25px 15px;
        .title{
            line-height: 50px;
            font-size: 16px;
        }
        .couponTitle{
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 46px;
            .couponTitleAction{
                display: flex;
                align-items: center;
                text-align: center;
                div{
                    height: 40px;
                    line-height: 40px;
                    width: 120px;
                    border: 1px solid #eee;
                    cursor: pointer;
                }
                .couponTitleActionActive{
                    border: 1px solid orangered;
                    color: orangered;
                }
            }
            .el-input{
                width: 220px;
            }
        }
        .couponBox{
            min-height: 100px;
            width: 100%;
            display: flex;
            align-items: center;
            .el-row{
                width: 100%;
                .el-col{
                    border: 1px solid #eee;
                    padding: 10px;
                    cursor: pointer;
                    .name{
                        line-height: 26px;
                        color: #666;
                    }
                    .cou{
                        line-height: 26px;
                        color: #999;
                        span{
                            color: orange;
                        }
                    }
                }
            }
            .activedCoupon{
                border: orange 1px solid!important;
            }
        }
    }
    .payType{
        padding: 0 25px 15px;
        .title{
            line-height: 40px;
        }
        .paytypes{
            .elCol{
                line-height: 50px;
                height: 50px;
                text-align: center;
                border: 1px solid #f3f4f5;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                span{
                    margin-right: 15px;
                }
                img{
                    width: 32px;
                    height: 32px;
                }
            }
        }
        .paytypesActive{
            // background-color:  #f7c1bd;
          color: orangered;
          border: 1px solid orangered;
        //   border-radius: 5px;
        }
    }
    .price{
        padding: 0 25px;
        line-height: 40px;
        text-align: right;
        span:last-child{
            display: inline-block;
            color: orange;
            width: 150px;
        }
    }
    .submit{
        padding: 0 25px;
        line-height: 50px;
        height: 50px;
        align-items: center;
        display: flex;
        justify-content: flex-end;
    }
}
</style>