export const AreaJson =
  [{
      "cityId": 1,
      "parentId": 0,
      "name": "北京",
      "child": [{
        "cityId": 2,
        "parentId": 1,
        "name": "北京市",
        "child": [{
            "cityId": 3,
            "parentId": 2,
            "name": "东城区"
          },
          {
            "cityId": 203,
            "parentId": 2,
            "name": "西城区"
          },
          {
            "cityId": 480,
            "parentId": 2,
            "name": "朝阳区"
          },
          {
            "cityId": 1122,
            "parentId": 2,
            "name": "丰台区"
          },
          {
            "cityId": 1533,
            "parentId": 2,
            "name": "石景山区"
          },
          {
            "cityId": 1692,
            "parentId": 2,
            "name": "海淀区"
          },
          {
            "cityId": 2376,
            "parentId": 2,
            "name": "门头沟区"
          },
          {
            "cityId": 2689,
            "parentId": 2,
            "name": "房山区"
          },
          {
            "cityId": 3324,
            "parentId": 2,
            "name": "通州区"
          },
          {
            "cityId": 3927,
            "parentId": 2,
            "name": "顺义区"
          },
          {
            "cityId": 4498,
            "parentId": 2,
            "name": "昌平区"
          },
          {
            "cityId": 5042,
            "parentId": 2,
            "name": "大兴区"
          },
          {
            "cityId": 5800,
            "parentId": 2,
            "name": "怀柔区"
          },
          {
            "cityId": 6138,
            "parentId": 2,
            "name": "平谷区"
          },
          {
            "cityId": 6466,
            "parentId": 2,
            "name": "密云区"
          },
          {
            "cityId": 6921,
            "parentId": 2,
            "name": "延庆区"
          }
        ]
      }]
    },
    {
      "cityId": 7362,
      "parentId": 0,
      "name": "天津",
      "child": [{
        "cityId": 7363,
        "parentId": 7362,
        "name": "天津市",
        "child": [{
            "cityId": 7364,
            "parentId": 7363,
            "name": "和平区"
          },
          {
            "cityId": 7434,
            "parentId": 7363,
            "name": "河东区"
          },
          {
            "cityId": 7607,
            "parentId": 7363,
            "name": "河西区"
          },
          {
            "cityId": 7763,
            "parentId": 7363,
            "name": "南开区"
          },
          {
            "cityId": 7954,
            "parentId": 7363,
            "name": "河北区"
          },
          {
            "cityId": 8079,
            "parentId": 7363,
            "name": "红桥区"
          },
          {
            "cityId": 8298,
            "parentId": 7363,
            "name": "东丽区"
          },
          {
            "cityId": 8563,
            "parentId": 7363,
            "name": "西青区"
          },
          {
            "cityId": 8835,
            "parentId": 7363,
            "name": "津南区"
          },
          {
            "cityId": 9102,
            "parentId": 7363,
            "name": "北辰区"
          },
          {
            "cityId": 9378,
            "parentId": 7363,
            "name": "武清区"
          },
          {
            "cityId": 10143,
            "parentId": 7363,
            "name": "宝坻区"
          },
          {
            "cityId": 10983,
            "parentId": 7363,
            "name": "滨海新区"
          },
          {
            "cityId": 11454,
            "parentId": 7363,
            "name": "宁河区"
          },
          {
            "cityId": 11795,
            "parentId": 7363,
            "name": "静海区"
          },
          {
            "cityId": 12251,
            "parentId": 7363,
            "name": "蓟州区"
          }
        ]
      }]
    },
    {
      "cityId": 13267,
      "parentId": 0,
      "name": "河北省",
      "child": [{
          "cityId": 13268,
          "parentId": 13267,
          "name": "石家庄市",
          "child": [{
              "cityId": 13269,
              "parentId": 13268,
              "name": "市辖区"
            },
            {
              "cityId": 13270,
              "parentId": 13268,
              "name": "长安区"
            },
            {
              "cityId": 13444,
              "parentId": 13268,
              "name": "桥西区"
            },
            {
              "cityId": 13601,
              "parentId": 13268,
              "name": "新华区"
            },
            {
              "cityId": 13728,
              "parentId": 13268,
              "name": "井陉矿区"
            },
            {
              "cityId": 13788,
              "parentId": 13268,
              "name": "裕华区"
            },
            {
              "cityId": 13895,
              "parentId": 13268,
              "name": "藁城区"
            },
            {
              "cityId": 14142,
              "parentId": 13268,
              "name": "鹿泉区"
            },
            {
              "cityId": 14377,
              "parentId": 13268,
              "name": "栾城区"
            },
            {
              "cityId": 14562,
              "parentId": 13268,
              "name": "井陉县"
            },
            {
              "cityId": 14902,
              "parentId": 13268,
              "name": "正定县"
            },
            {
              "cityId": 15100,
              "parentId": 13268,
              "name": "行唐县"
            },
            {
              "cityId": 15447,
              "parentId": 13268,
              "name": "灵寿县"
            },
            {
              "cityId": 15747,
              "parentId": 13268,
              "name": "高邑县"
            },
            {
              "cityId": 15860,
              "parentId": 13268,
              "name": "深泽县"
            },
            {
              "cityId": 15993,
              "parentId": 13268,
              "name": "赞皇县"
            },
            {
              "cityId": 16218,
              "parentId": 13268,
              "name": "无极县"
            },
            {
              "cityId": 16447,
              "parentId": 13268,
              "name": "平山县"
            },
            {
              "cityId": 17188,
              "parentId": 13268,
              "name": "元氏县"
            },
            {
              "cityId": 17417,
              "parentId": 13268,
              "name": "赵县"
            },
            {
              "cityId": 17710,
              "parentId": 13268,
              "name": "晋州市"
            },
            {
              "cityId": 17945,
              "parentId": 13268,
              "name": "新乐市"
            }
          ]
        },
        {
          "cityId": 18128,
          "parentId": 13267,
          "name": "唐山市",
          "child": [{
              "cityId": 18129,
              "parentId": 18128,
              "name": "市辖区"
            },
            {
              "cityId": 18130,
              "parentId": 18128,
              "name": "路南区"
            },
            {
              "cityId": 18297,
              "parentId": 18128,
              "name": "路北区"
            },
            {
              "cityId": 18542,
              "parentId": 18128,
              "name": "古冶区"
            },
            {
              "cityId": 18754,
              "parentId": 18128,
              "name": "开平区"
            },
            {
              "cityId": 18938,
              "parentId": 18128,
              "name": "丰南区"
            },
            {
              "cityId": 19436,
              "parentId": 18128,
              "name": "丰润区"
            },
            {
              "cityId": 19987,
              "parentId": 18128,
              "name": "曹妃甸区"
            },
            {
              "cityId": 20152,
              "parentId": 18128,
              "name": "滦县"
            },
            {
              "cityId": 20696,
              "parentId": 18128,
              "name": "滦南县"
            },
            {
              "cityId": 21321,
              "parentId": 18128,
              "name": "乐亭县"
            },
            {
              "cityId": 21825,
              "parentId": 18128,
              "name": "迁西县"
            },
            {
              "cityId": 22273,
              "parentId": 18128,
              "name": "玉田县"
            },
            {
              "cityId": 23067,
              "parentId": 18128,
              "name": "遵化市"
            },
            {
              "cityId": 23787,
              "parentId": 18128,
              "name": "迁安市"
            }
          ]
        },
        {
          "cityId": 24371,
          "parentId": 13267,
          "name": "秦皇岛市",
          "child": [{
              "cityId": 24372,
              "parentId": 24371,
              "name": "市辖区"
            },
            {
              "cityId": 24373,
              "parentId": 24371,
              "name": "海港区"
            },
            {
              "cityId": 24760,
              "parentId": 24371,
              "name": "山海关区"
            },
            {
              "cityId": 24891,
              "parentId": 24371,
              "name": "北戴河区"
            },
            {
              "cityId": 24951,
              "parentId": 24371,
              "name": "抚宁区"
            },
            {
              "cityId": 25332,
              "parentId": 24371,
              "name": "青龙满族自治县"
            },
            {
              "cityId": 25758,
              "parentId": 24371,
              "name": "昌黎县"
            },
            {
              "cityId": 26216,
              "parentId": 24371,
              "name": "卢龙县"
            }
          ]
        },
        {
          "cityId": 26779,
          "parentId": 13267,
          "name": "邯郸市",
          "child": [{
              "cityId": 26780,
              "parentId": 26779,
              "name": "市辖区"
            },
            {
              "cityId": 26781,
              "parentId": 26779,
              "name": "邯山区"
            },
            {
              "cityId": 26877,
              "parentId": 26779,
              "name": "丛台区"
            },
            {
              "cityId": 27018,
              "parentId": 26779,
              "name": "复兴区"
            },
            {
              "cityId": 27135,
              "parentId": 26779,
              "name": "峰峰矿区"
            },
            {
              "cityId": 27372,
              "parentId": 26779,
              "name": "邯郸县"
            },
            {
              "cityId": 27538,
              "parentId": 26779,
              "name": "临漳县"
            },
            {
              "cityId": 27978,
              "parentId": 26779,
              "name": "成安县"
            },
            {
              "cityId": 28222,
              "parentId": 26779,
              "name": "大名县"
            },
            {
              "cityId": 28894,
              "parentId": 26779,
              "name": "涉县"
            },
            {
              "cityId": 29226,
              "parentId": 26779,
              "name": "磁县"
            },
            {
              "cityId": 29613,
              "parentId": 26779,
              "name": "肥乡县"
            },
            {
              "cityId": 29888,
              "parentId": 26779,
              "name": "永年县"
            },
            {
              "cityId": 30335,
              "parentId": 26779,
              "name": "邱县"
            },
            {
              "cityId": 30568,
              "parentId": 26779,
              "name": "鸡泽县"
            },
            {
              "cityId": 30747,
              "parentId": 26779,
              "name": "广平县"
            },
            {
              "cityId": 30924,
              "parentId": 26779,
              "name": "馆陶县"
            },
            {
              "cityId": 31210,
              "parentId": 26779,
              "name": "魏县"
            },
            {
              "cityId": 31793,
              "parentId": 26779,
              "name": "曲周县"
            },
            {
              "cityId": 32146,
              "parentId": 26779,
              "name": "武安市"
            }
          ]
        },
        {
          "cityId": 32688,
          "parentId": 13267,
          "name": "邢台市",
          "child": [{
              "cityId": 32689,
              "parentId": 32688,
              "name": "市辖区"
            },
            {
              "cityId": 32690,
              "parentId": 32688,
              "name": "桥东区"
            },
            {
              "cityId": 32795,
              "parentId": 32688,
              "name": "桥西区"
            },
            {
              "cityId": 32922,
              "parentId": 32688,
              "name": "邢台县"
            },
            {
              "cityId": 33459,
              "parentId": 32688,
              "name": "临城县"
            },
            {
              "cityId": 33688,
              "parentId": 32688,
              "name": "内丘县"
            },
            {
              "cityId": 34011,
              "parentId": 32688,
              "name": "柏乡县"
            },
            {
              "cityId": 34139,
              "parentId": 32688,
              "name": "隆尧县"
            },
            {
              "cityId": 34431,
              "parentId": 32688,
              "name": "任县"
            },
            {
              "cityId": 34635,
              "parentId": 32688,
              "name": "南和县"
            },
            {
              "cityId": 34866,
              "parentId": 32688,
              "name": "宁晋县"
            },
            {
              "cityId": 35240,
              "parentId": 32688,
              "name": "巨鹿县"
            },
            {
              "cityId": 35542,
              "parentId": 32688,
              "name": "新河县"
            },
            {
              "cityId": 35718,
              "parentId": 32688,
              "name": "广宗县"
            },
            {
              "cityId": 35940,
              "parentId": 32688,
              "name": "平乡县"
            },
            {
              "cityId": 36202,
              "parentId": 32688,
              "name": "威县"
            },
            {
              "cityId": 36748,
              "parentId": 32688,
              "name": "清河县"
            },
            {
              "cityId": 37086,
              "parentId": 32688,
              "name": "临西县"
            },
            {
              "cityId": 37403,
              "parentId": 32688,
              "name": "南宫市"
            },
            {
              "cityId": 37883,
              "parentId": 32688,
              "name": "沙河市"
            }
          ]
        },
        {
          "cityId": 38160,
          "parentId": 13267,
          "name": "保定市",
          "child": [{
              "cityId": 38161,
              "parentId": 38160,
              "name": "市辖区"
            },
            {
              "cityId": 38162,
              "parentId": 38160,
              "name": "竞秀区"
            },
            {
              "cityId": 38327,
              "parentId": 38160,
              "name": "莲池区"
            },
            {
              "cityId": 38578,
              "parentId": 38160,
              "name": "满城区"
            },
            {
              "cityId": 38786,
              "parentId": 38160,
              "name": "清苑区"
            },
            {
              "cityId": 39082,
              "parentId": 38160,
              "name": "徐水区"
            },
            {
              "cityId": 39426,
              "parentId": 38160,
              "name": "涞水县"
            },
            {
              "cityId": 39736,
              "parentId": 38160,
              "name": "阜平县"
            },
            {
              "cityId": 39960,
              "parentId": 38160,
              "name": "定兴县"
            },
            {
              "cityId": 40258,
              "parentId": 38160,
              "name": "唐县"
            },
            {
              "cityId": 40632,
              "parentId": 38160,
              "name": "高阳县"
            },
            {
              "cityId": 40825,
              "parentId": 38160,
              "name": "容城县"
            },
            {
              "cityId": 40961,
              "parentId": 38160,
              "name": "涞源县"
            },
            {
              "cityId": 41272,
              "parentId": 38160,
              "name": "望都县"
            },
            {
              "cityId": 41433,
              "parentId": 38160,
              "name": "安新县"
            },
            {
              "cityId": 41658,
              "parentId": 38160,
              "name": "易县"
            },
            {
              "cityId": 42155,
              "parentId": 38160,
              "name": "曲阳县"
            },
            {
              "cityId": 42550,
              "parentId": 38160,
              "name": "蠡县"
            },
            {
              "cityId": 42802,
              "parentId": 38160,
              "name": "顺平县"
            },
            {
              "cityId": 43054,
              "parentId": 38160,
              "name": "博野县"
            },
            {
              "cityId": 43196,
              "parentId": 38160,
              "name": "雄县"
            },
            {
              "cityId": 43441,
              "parentId": 38160,
              "name": "涿州市"
            },
            {
              "cityId": 43905,
              "parentId": 38160,
              "name": "安国市"
            },
            {
              "cityId": 44124,
              "parentId": 38160,
              "name": "高碑店市"
            }
          ]
        },
        {
          "cityId": 44570,
          "parentId": 13267,
          "name": "张家口市",
          "child": [{
              "cityId": 44571,
              "parentId": 44570,
              "name": "市辖区"
            },
            {
              "cityId": 44572,
              "parentId": 44570,
              "name": "桥东区"
            },
            {
              "cityId": 44634,
              "parentId": 44570,
              "name": "桥西区"
            },
            {
              "cityId": 44704,
              "parentId": 44570,
              "name": "宣化区"
            },
            {
              "cityId": 45133,
              "parentId": 44570,
              "name": "下花园区"
            },
            {
              "cityId": 45197,
              "parentId": 44570,
              "name": "万全区"
            },
            {
              "cityId": 45395,
              "parentId": 44570,
              "name": "崇礼区"
            },
            {
              "cityId": 45623,
              "parentId": 44570,
              "name": "张北县"
            },
            {
              "cityId": 46018,
              "parentId": 44570,
              "name": "康保县"
            },
            {
              "cityId": 46371,
              "parentId": 44570,
              "name": "沽源县"
            },
            {
              "cityId": 46619,
              "parentId": 44570,
              "name": "尚义县"
            },
            {
              "cityId": 46812,
              "parentId": 44570,
              "name": "蔚县"
            },
            {
              "cityId": 47396,
              "parentId": 44570,
              "name": "阳原县"
            },
            {
              "cityId": 47718,
              "parentId": 44570,
              "name": "怀安县"
            },
            {
              "cityId": 48013,
              "parentId": 44570,
              "name": "怀来县"
            },
            {
              "cityId": 48331,
              "parentId": 44570,
              "name": "涿鹿县"
            },
            {
              "cityId": 48731,
              "parentId": 44570,
              "name": "赤城县"
            }
          ]
        },
        {
          "cityId": 49190,
          "parentId": 13267,
          "name": "承德市",
          "child": [{
              "cityId": 49191,
              "parentId": 49190,
              "name": "市辖区"
            },
            {
              "cityId": 49192,
              "parentId": 49190,
              "name": "双桥区"
            },
            {
              "cityId": 49328,
              "parentId": 49190,
              "name": "双滦区"
            },
            {
              "cityId": 49425,
              "parentId": 49190,
              "name": "鹰手营子矿区"
            },
            {
              "cityId": 49456,
              "parentId": 49190,
              "name": "承德县"
            },
            {
              "cityId": 49866,
              "parentId": 49190,
              "name": "兴隆县"
            },
            {
              "cityId": 50185,
              "parentId": 49190,
              "name": "平泉县"
            },
            {
              "cityId": 50455,
              "parentId": 49190,
              "name": "滦平县"
            },
            {
              "cityId": 50690,
              "parentId": 49190,
              "name": "隆化县"
            },
            {
              "cityId": 51081,
              "parentId": 49190,
              "name": "丰宁满族自治县"
            },
            {
              "cityId": 51428,
              "parentId": 49190,
              "name": "宽城满族自治县"
            },
            {
              "cityId": 51657,
              "parentId": 49190,
              "name": "围场满族蒙古族自治县"
            }
          ]
        },
        {
          "cityId": 52018,
          "parentId": 13267,
          "name": "沧州市",
          "child": [{
              "cityId": 52019,
              "parentId": 52018,
              "name": "市辖区"
            },
            {
              "cityId": 52020,
              "parentId": 52018,
              "name": "新华区"
            },
            {
              "cityId": 52086,
              "parentId": 52018,
              "name": "运河区"
            },
            {
              "cityId": 52214,
              "parentId": 52018,
              "name": "沧县"
            },
            {
              "cityId": 52753,
              "parentId": 52018,
              "name": "青县"
            },
            {
              "cityId": 53128,
              "parentId": 52018,
              "name": "东光县"
            },
            {
              "cityId": 53595,
              "parentId": 52018,
              "name": "海兴县"
            },
            {
              "cityId": 53810,
              "parentId": 52018,
              "name": "盐山县"
            },
            {
              "cityId": 54277,
              "parentId": 52018,
              "name": "肃宁县"
            },
            {
              "cityId": 54547,
              "parentId": 52018,
              "name": "南皮县"
            },
            {
              "cityId": 54875,
              "parentId": 52018,
              "name": "吴桥县"
            },
            {
              "cityId": 55369,
              "parentId": 52018,
              "name": "献县"
            },
            {
              "cityId": 55902,
              "parentId": 52018,
              "name": "孟村回族自治县"
            },
            {
              "cityId": 56037,
              "parentId": 52018,
              "name": "泊头市"
            },
            {
              "cityId": 56764,
              "parentId": 52018,
              "name": "任丘市"
            },
            {
              "cityId": 57238,
              "parentId": 52018,
              "name": "黄骅市"
            },
            {
              "cityId": 57596,
              "parentId": 52018,
              "name": "河间市"
            }
          ]
        },
        {
          "cityId": 58247,
          "parentId": 13267,
          "name": "廊坊市",
          "child": [{
              "cityId": 58248,
              "parentId": 58247,
              "name": "市辖区"
            },
            {
              "cityId": 58249,
              "parentId": 58247,
              "name": "安次区"
            },
            {
              "cityId": 58567,
              "parentId": 58247,
              "name": "广阳区"
            },
            {
              "cityId": 58789,
              "parentId": 58247,
              "name": "固安县"
            },
            {
              "cityId": 59228,
              "parentId": 58247,
              "name": "永清县"
            },
            {
              "cityId": 59639,
              "parentId": 58247,
              "name": "香河县"
            },
            {
              "cityId": 59963,
              "parentId": 58247,
              "name": "大城县"
            },
            {
              "cityId": 60378,
              "parentId": 58247,
              "name": "文安县"
            },
            {
              "cityId": 60784,
              "parentId": 58247,
              "name": "大厂回族自治县"
            },
            {
              "cityId": 60904,
              "parentId": 58247,
              "name": "霸州市"
            },
            {
              "cityId": 61311,
              "parentId": 58247,
              "name": "三河市"
            }
          ]
        },
        {
          "cityId": 61804,
          "parentId": 13267,
          "name": "衡水市",
          "child": [{
              "cityId": 61805,
              "parentId": 61804,
              "name": "市辖区"
            },
            {
              "cityId": 61806,
              "parentId": 61804,
              "name": "桃城区"
            },
            {
              "cityId": 62086,
              "parentId": 61804,
              "name": "冀州区"
            },
            {
              "cityId": 62479,
              "parentId": 61804,
              "name": "枣强县"
            },
            {
              "cityId": 63050,
              "parentId": 61804,
              "name": "武邑县"
            },
            {
              "cityId": 63584,
              "parentId": 61804,
              "name": "武强县"
            },
            {
              "cityId": 63832,
              "parentId": 61804,
              "name": "饶阳县"
            },
            {
              "cityId": 64040,
              "parentId": 61804,
              "name": "安平县"
            },
            {
              "cityId": 64281,
              "parentId": 61804,
              "name": "故城县"
            },
            {
              "cityId": 64833,
              "parentId": 61804,
              "name": "景县"
            },
            {
              "cityId": 65702,
              "parentId": 61804,
              "name": "阜城县"
            },
            {
              "cityId": 66323,
              "parentId": 61804,
              "name": "深州市"
            }
          ]
        },
        {
          "cityId": 66813,
          "parentId": 13267,
          "name": "直辖县",
          "child": [{
              "cityId": 66814,
              "parentId": 66813,
              "name": "定州市"
            },
            {
              "cityId": 67371,
              "parentId": 66813,
              "name": "辛集市"
            }
          ]
        }
      ]
    },
    {
      "cityId": 67747,
      "parentId": 0,
      "name": "山西省",
      "child": [{
          "cityId": 67748,
          "parentId": 67747,
          "name": "太原市",
          "child": [{
              "cityId": 67749,
              "parentId": 67748,
              "name": "市辖区"
            },
            {
              "cityId": 67750,
              "parentId": 67748,
              "name": "小店区"
            },
            {
              "cityId": 67921,
              "parentId": 67748,
              "name": "迎泽区"
            },
            {
              "cityId": 68043,
              "parentId": 67748,
              "name": "杏花岭区"
            },
            {
              "cityId": 68203,
              "parentId": 67748,
              "name": "尖草坪区"
            },
            {
              "cityId": 68368,
              "parentId": 67748,
              "name": "万柏林区"
            },
            {
              "cityId": 68542,
              "parentId": 67748,
              "name": "晋源区"
            },
            {
              "cityId": 68671,
              "parentId": 67748,
              "name": "清徐县"
            },
            {
              "cityId": 68893,
              "parentId": 67748,
              "name": "阳曲县"
            },
            {
              "cityId": 69032,
              "parentId": 67748,
              "name": "娄烦县"
            },
            {
              "cityId": 69190,
              "parentId": 67748,
              "name": "古交市"
            }
          ]
        },
        {
          "cityId": 69390,
          "parentId": 67747,
          "name": "大同市",
          "child": [{
              "cityId": 69391,
              "parentId": 69390,
              "name": "市辖区"
            },
            {
              "cityId": 69392,
              "parentId": 69390,
              "name": "城区"
            },
            {
              "cityId": 69546,
              "parentId": 69390,
              "name": "矿区"
            },
            {
              "cityId": 69691,
              "parentId": 69390,
              "name": "南郊区"
            },
            {
              "cityId": 69891,
              "parentId": 69390,
              "name": "新荣区"
            },
            {
              "cityId": 70045,
              "parentId": 69390,
              "name": "阳高县"
            },
            {
              "cityId": 70336,
              "parentId": 69390,
              "name": "天镇县"
            },
            {
              "cityId": 70582,
              "parentId": 69390,
              "name": "广灵县"
            },
            {
              "cityId": 70778,
              "parentId": 69390,
              "name": "灵丘县"
            },
            {
              "cityId": 71059,
              "parentId": 69390,
              "name": "浑源县"
            },
            {
              "cityId": 71402,
              "parentId": 69390,
              "name": "左云县"
            },
            {
              "cityId": 71649,
              "parentId": 69390,
              "name": "大同县"
            }
          ]
        },
        {
          "cityId": 71850,
          "parentId": 67747,
          "name": "阳泉市",
          "child": [{
              "cityId": 71851,
              "parentId": 71850,
              "name": "市辖区"
            },
            {
              "cityId": 71852,
              "parentId": 71850,
              "name": "城区"
            },
            {
              "cityId": 71905,
              "parentId": 71850,
              "name": "矿区"
            },
            {
              "cityId": 71950,
              "parentId": 71850,
              "name": "郊区"
            },
            {
              "cityId": 72145,
              "parentId": 71850,
              "name": "平定县"
            },
            {
              "cityId": 72497,
              "parentId": 71850,
              "name": "盂县"
            }
          ]
        },
        {
          "cityId": 72975,
          "parentId": 67747,
          "name": "长治市",
          "child": [{
              "cityId": 72976,
              "parentId": 72975,
              "name": "市辖区"
            },
            {
              "cityId": 72977,
              "parentId": 72975,
              "name": "城区"
            },
            {
              "cityId": 73071,
              "parentId": 72975,
              "name": "郊区"
            },
            {
              "cityId": 73222,
              "parentId": 72975,
              "name": "长治县"
            },
            {
              "cityId": 73495,
              "parentId": 72975,
              "name": "襄垣县"
            },
            {
              "cityId": 73840,
              "parentId": 72975,
              "name": "屯留县"
            },
            {
              "cityId": 74151,
              "parentId": 72975,
              "name": "平顺县"
            },
            {
              "cityId": 74429,
              "parentId": 72975,
              "name": "黎城县"
            },
            {
              "cityId": 74694,
              "parentId": 72975,
              "name": "壶关县"
            },
            {
              "cityId": 75101,
              "parentId": 72975,
              "name": "长子县"
            },
            {
              "cityId": 75515,
              "parentId": 72975,
              "name": "武乡县"
            },
            {
              "cityId": 75911,
              "parentId": 72975,
              "name": "沁县"
            },
            {
              "cityId": 76237,
              "parentId": 72975,
              "name": "沁源县"
            },
            {
              "cityId": 76512,
              "parentId": 72975,
              "name": "潞城市"
            }
          ]
        },
        {
          "cityId": 76726,
          "parentId": 67747,
          "name": "晋城市",
          "child": [{
              "cityId": 76727,
              "parentId": 76726,
              "name": "市辖区"
            },
            {
              "cityId": 76728,
              "parentId": 76726,
              "name": "城区"
            },
            {
              "cityId": 76874,
              "parentId": 76726,
              "name": "沁水县"
            },
            {
              "cityId": 77140,
              "parentId": 76726,
              "name": "阳城县"
            },
            {
              "cityId": 77632,
              "parentId": 76726,
              "name": "陵川县"
            },
            {
              "cityId": 78024,
              "parentId": 76726,
              "name": "泽州县"
            },
            {
              "cityId": 78678,
              "parentId": 76726,
              "name": "高平市"
            }
          ]
        },
        {
          "cityId": 79163,
          "parentId": 67747,
          "name": "朔州市",
          "child": [{
              "cityId": 79164,
              "parentId": 79163,
              "name": "市辖区"
            },
            {
              "cityId": 79165,
              "parentId": 79163,
              "name": "朔城区"
            },
            {
              "cityId": 79533,
              "parentId": 79163,
              "name": "平鲁区"
            },
            {
              "cityId": 79843,
              "parentId": 79163,
              "name": "山阴县"
            },
            {
              "cityId": 80134,
              "parentId": 79163,
              "name": "应县"
            },
            {
              "cityId": 80454,
              "parentId": 79163,
              "name": "右玉县"
            },
            {
              "cityId": 80799,
              "parentId": 79163,
              "name": "怀仁县"
            }
          ]
        },
        {
          "cityId": 81000,
          "parentId": 67747,
          "name": "晋中市",
          "child": [{
              "cityId": 81001,
              "parentId": 81000,
              "name": "市辖区"
            },
            {
              "cityId": 81002,
              "parentId": 81000,
              "name": "榆次区"
            },
            {
              "cityId": 81361,
              "parentId": 81000,
              "name": "榆社县"
            },
            {
              "cityId": 81649,
              "parentId": 81000,
              "name": "左权县"
            },
            {
              "cityId": 81872,
              "parentId": 81000,
              "name": "和顺县"
            },
            {
              "cityId": 82183,
              "parentId": 81000,
              "name": "昔阳县"
            },
            {
              "cityId": 82535,
              "parentId": 81000,
              "name": "寿阳县"
            },
            {
              "cityId": 82765,
              "parentId": 81000,
              "name": "太谷县"
            },
            {
              "cityId": 82986,
              "parentId": 81000,
              "name": "祁县"
            },
            {
              "cityId": 83165,
              "parentId": 81000,
              "name": "平遥县"
            },
            {
              "cityId": 83472,
              "parentId": 81000,
              "name": "灵石县"
            },
            {
              "cityId": 83810,
              "parentId": 81000,
              "name": "介休市"
            }
          ]
        },
        {
          "cityId": 84092,
          "parentId": 67747,
          "name": "运城市",
          "child": [{
              "cityId": 84093,
              "parentId": 84092,
              "name": "市辖区"
            },
            {
              "cityId": 84094,
              "parentId": 84092,
              "name": "盐湖区"
            },
            {
              "cityId": 84483,
              "parentId": 84092,
              "name": "临猗县"
            },
            {
              "cityId": 84893,
              "parentId": 84092,
              "name": "万荣县"
            },
            {
              "cityId": 85194,
              "parentId": 84092,
              "name": "闻喜县"
            },
            {
              "cityId": 85569,
              "parentId": 84092,
              "name": "稷山县"
            },
            {
              "cityId": 85780,
              "parentId": 84092,
              "name": "新绛县"
            },
            {
              "cityId": 86023,
              "parentId": 84092,
              "name": "绛县"
            },
            {
              "cityId": 86238,
              "parentId": 84092,
              "name": "垣曲县"
            },
            {
              "cityId": 86448,
              "parentId": 84092,
              "name": "夏县"
            },
            {
              "cityId": 86706,
              "parentId": 84092,
              "name": "平陆县"
            },
            {
              "cityId": 86949,
              "parentId": 84092,
              "name": "芮城县"
            },
            {
              "cityId": 87129,
              "parentId": 84092,
              "name": "永济市"
            },
            {
              "cityId": 87434,
              "parentId": 84092,
              "name": "河津市"
            }
          ]
        },
        {
          "cityId": 87613,
          "parentId": 67747,
          "name": "忻州市",
          "child": [{
              "cityId": 87614,
              "parentId": 87613,
              "name": "市辖区"
            },
            {
              "cityId": 87615,
              "parentId": 87613,
              "name": "忻府区"
            },
            {
              "cityId": 88061,
              "parentId": 87613,
              "name": "定襄县"
            },
            {
              "cityId": 88227,
              "parentId": 87613,
              "name": "五台县"
            },
            {
              "cityId": 88754,
              "parentId": 87613,
              "name": "代县"
            },
            {
              "cityId": 89144,
              "parentId": 87613,
              "name": "繁峙县"
            },
            {
              "cityId": 89570,
              "parentId": 87613,
              "name": "宁武县"
            },
            {
              "cityId": 90066,
              "parentId": 87613,
              "name": "静乐县"
            },
            {
              "cityId": 90462,
              "parentId": 87613,
              "name": "神池县"
            },
            {
              "cityId": 90715,
              "parentId": 87613,
              "name": "五寨县"
            },
            {
              "cityId": 90978,
              "parentId": 87613,
              "name": "岢岚县"
            },
            {
              "cityId": 91193,
              "parentId": 87613,
              "name": "河曲县"
            },
            {
              "cityId": 91548,
              "parentId": 87613,
              "name": "保德县"
            },
            {
              "cityId": 91912,
              "parentId": 87613,
              "name": "偏关县"
            },
            {
              "cityId": 92172,
              "parentId": 87613,
              "name": "原平市"
            }
          ]
        },
        {
          "cityId": 92738,
          "parentId": 67747,
          "name": "临汾市",
          "child": [{
              "cityId": 92739,
              "parentId": 92738,
              "name": "市辖区"
            },
            {
              "cityId": 92740,
              "parentId": 92738,
              "name": "尧都区"
            },
            {
              "cityId": 93208,
              "parentId": 92738,
              "name": "曲沃县"
            },
            {
              "cityId": 93375,
              "parentId": 92738,
              "name": "翼城县"
            },
            {
              "cityId": 93604,
              "parentId": 92738,
              "name": "襄汾县"
            },
            {
              "cityId": 93972,
              "parentId": 92738,
              "name": "洪洞县"
            },
            {
              "cityId": 94457,
              "parentId": 92738,
              "name": "古县"
            },
            {
              "cityId": 94580,
              "parentId": 92738,
              "name": "安泽县"
            },
            {
              "cityId": 94696,
              "parentId": 92738,
              "name": "浮山县"
            },
            {
              "cityId": 94893,
              "parentId": 92738,
              "name": "吉县"
            },
            {
              "cityId": 94981,
              "parentId": 92738,
              "name": "乡宁县"
            },
            {
              "cityId": 95181,
              "parentId": 92738,
              "name": "大宁县"
            },
            {
              "cityId": 95272,
              "parentId": 92738,
              "name": "隰县"
            },
            {
              "cityId": 95381,
              "parentId": 92738,
              "name": "永和县"
            },
            {
              "cityId": 95472,
              "parentId": 92738,
              "name": "蒲县"
            },
            {
              "cityId": 95579,
              "parentId": 92738,
              "name": "汾西县"
            },
            {
              "cityId": 95715,
              "parentId": 92738,
              "name": "侯马市"
            },
            {
              "cityId": 95828,
              "parentId": 92738,
              "name": "霍州市"
            }
          ]
        },
        {
          "cityId": 96064,
          "parentId": 67747,
          "name": "吕梁市",
          "child": [{
              "cityId": 96065,
              "parentId": 96064,
              "name": "市辖区"
            },
            {
              "cityId": 96066,
              "parentId": 96064,
              "name": "离石区"
            },
            {
              "cityId": 96332,
              "parentId": 96064,
              "name": "文水县"
            },
            {
              "cityId": 96548,
              "parentId": 96064,
              "name": "交城县"
            },
            {
              "cityId": 96711,
              "parentId": 96064,
              "name": "兴县"
            },
            {
              "cityId": 97113,
              "parentId": 96064,
              "name": "临县"
            },
            {
              "cityId": 97779,
              "parentId": 96064,
              "name": "柳林县"
            },
            {
              "cityId": 98056,
              "parentId": 96064,
              "name": "石楼县"
            },
            {
              "cityId": 98201,
              "parentId": 96064,
              "name": "岚县"
            },
            {
              "cityId": 98383,
              "parentId": 96064,
              "name": "方山县"
            },
            {
              "cityId": 98562,
              "parentId": 96064,
              "name": "中阳县"
            },
            {
              "cityId": 98670,
              "parentId": 96064,
              "name": "交口县"
            },
            {
              "cityId": 98774,
              "parentId": 96064,
              "name": "孝义市"
            },
            {
              "cityId": 99219,
              "parentId": 96064,
              "name": "汾阳市"
            }
          ]
        }
      ]
    },
    {
      "cityId": 99537,
      "parentId": 0,
      "name": "内蒙古自治区",
      "child": [{
          "cityId": 99538,
          "parentId": 99537,
          "name": "呼和浩特市",
          "child": [{
              "cityId": 99539,
              "parentId": 99538,
              "name": "市辖区"
            },
            {
              "cityId": 99540,
              "parentId": 99538,
              "name": "新城区"
            },
            {
              "cityId": 99624,
              "parentId": 99538,
              "name": "回民区"
            },
            {
              "cityId": 99696,
              "parentId": 99538,
              "name": "玉泉区"
            },
            {
              "cityId": 99807,
              "parentId": 99538,
              "name": "赛罕区"
            },
            {
              "cityId": 100011,
              "parentId": 99538,
              "name": "土默特左旗"
            },
            {
              "cityId": 100358,
              "parentId": 99538,
              "name": "托克托县"
            },
            {
              "cityId": 100502,
              "parentId": 99538,
              "name": "和林格尔县"
            },
            {
              "cityId": 100672,
              "parentId": 99538,
              "name": "清水河县"
            },
            {
              "cityId": 100790,
              "parentId": 99538,
              "name": "武川县"
            }
          ]
        },
        {
          "cityId": 100904,
          "parentId": 99537,
          "name": "包头市",
          "child": [{
              "cityId": 100905,
              "parentId": 100904,
              "name": "市辖区"
            },
            {
              "cityId": 100906,
              "parentId": 100904,
              "name": "东河区"
            },
            {
              "cityId": 101033,
              "parentId": 100904,
              "name": "昆都仑区"
            },
            {
              "cityId": 101148,
              "parentId": 100904,
              "name": "青山区"
            },
            {
              "cityId": 101235,
              "parentId": 100904,
              "name": "石拐区"
            },
            {
              "cityId": 101268,
              "parentId": 100904,
              "name": "白云鄂博矿区"
            },
            {
              "cityId": 101275,
              "parentId": 100904,
              "name": "九原区"
            },
            {
              "cityId": 101359,
              "parentId": 100904,
              "name": "土默特右旗"
            },
            {
              "cityId": 101583,
              "parentId": 100904,
              "name": "固阳县"
            },
            {
              "cityId": 101673,
              "parentId": 100904,
              "name": "达尔罕茂明安联合旗"
            }
          ]
        },
        {
          "cityId": 101772,
          "parentId": 99537,
          "name": "乌海市",
          "child": [{
              "cityId": 101773,
              "parentId": 101772,
              "name": "市辖区"
            },
            {
              "cityId": 101774,
              "parentId": 101772,
              "name": "海勃湾区"
            },
            {
              "cityId": 101824,
              "parentId": 101772,
              "name": "海南区"
            },
            {
              "cityId": 101850,
              "parentId": 101772,
              "name": "乌达区"
            }
          ]
        },
        {
          "cityId": 101884,
          "parentId": 99537,
          "name": "赤峰市",
          "child": [{
              "cityId": 101885,
              "parentId": 101884,
              "name": "市辖区"
            },
            {
              "cityId": 101886,
              "parentId": 101884,
              "name": "红山区"
            },
            {
              "cityId": 101986,
              "parentId": 101884,
              "name": "元宝山区"
            },
            {
              "cityId": 102110,
              "parentId": 101884,
              "name": "松山区"
            },
            {
              "cityId": 102434,
              "parentId": 101884,
              "name": "阿鲁科尔沁旗"
            },
            {
              "cityId": 102714,
              "parentId": 101884,
              "name": "巴林左旗"
            },
            {
              "cityId": 102913,
              "parentId": 101884,
              "name": "巴林右旗"
            },
            {
              "cityId": 103097,
              "parentId": 101884,
              "name": "林西县"
            },
            {
              "cityId": 103225,
              "parentId": 101884,
              "name": "克什克腾旗"
            },
            {
              "cityId": 103385,
              "parentId": 101884,
              "name": "翁牛特旗"
            },
            {
              "cityId": 103647,
              "parentId": 101884,
              "name": "喀喇沁旗"
            },
            {
              "cityId": 103835,
              "parentId": 101884,
              "name": "宁城县"
            },
            {
              "cityId": 104189,
              "parentId": 101884,
              "name": "敖汉旗"
            }
          ]
        },
        {
          "cityId": 104457,
          "parentId": 99537,
          "name": "通辽市",
          "child": [{
              "cityId": 104458,
              "parentId": 104457,
              "name": "市辖区"
            },
            {
              "cityId": 104459,
              "parentId": 104457,
              "name": "科尔沁区"
            },
            {
              "cityId": 104954,
              "parentId": 104457,
              "name": "科尔沁左翼中旗"
            },
            {
              "cityId": 105531,
              "parentId": 104457,
              "name": "科尔沁左翼后旗"
            },
            {
              "cityId": 105865,
              "parentId": 104457,
              "name": "开鲁县"
            },
            {
              "cityId": 106150,
              "parentId": 104457,
              "name": "库伦旗"
            },
            {
              "cityId": 106356,
              "parentId": 104457,
              "name": "奈曼旗"
            },
            {
              "cityId": 106746,
              "parentId": 104457,
              "name": "扎鲁特旗"
            },
            {
              "cityId": 107028,
              "parentId": 104457,
              "name": "霍林郭勒市"
            }
          ]
        },
        {
          "cityId": 107065,
          "parentId": 99537,
          "name": "鄂尔多斯市",
          "child": [{
              "cityId": 107066,
              "parentId": 107065,
              "name": "市辖区"
            },
            {
              "cityId": 107067,
              "parentId": 107065,
              "name": "东胜区"
            },
            {
              "cityId": 107193,
              "parentId": 107065,
              "name": "康巴什区"
            },
            {
              "cityId": 107213,
              "parentId": 107065,
              "name": "达拉特旗"
            },
            {
              "cityId": 107388,
              "parentId": 107065,
              "name": "准格尔旗"
            },
            {
              "cityId": 107593,
              "parentId": 107065,
              "name": "鄂托克前旗"
            },
            {
              "cityId": 107684,
              "parentId": 107065,
              "name": "鄂托克旗"
            },
            {
              "cityId": 107792,
              "parentId": 107065,
              "name": "杭锦旗"
            },
            {
              "cityId": 107892,
              "parentId": 107065,
              "name": "乌审旗"
            },
            {
              "cityId": 107982,
              "parentId": 107065,
              "name": "伊金霍洛旗"
            }
          ]
        },
        {
          "cityId": 108166,
          "parentId": 99537,
          "name": "呼伦贝尔市",
          "child": [{
              "cityId": 108167,
              "parentId": 108166,
              "name": "市辖区"
            },
            {
              "cityId": 108168,
              "parentId": 108166,
              "name": "海拉尔区"
            },
            {
              "cityId": 108232,
              "parentId": 108166,
              "name": "扎赉诺尔区"
            },
            {
              "cityId": 108260,
              "parentId": 108166,
              "name": "阿荣旗"
            },
            {
              "cityId": 108483,
              "parentId": 108166,
              "name": "莫力达瓦达斡尔族自治旗"
            },
            {
              "cityId": 108785,
              "parentId": 108166,
              "name": "鄂伦春自治旗"
            },
            {
              "cityId": 109030,
              "parentId": 108166,
              "name": "鄂温克族自治旗"
            },
            {
              "cityId": 109113,
              "parentId": 108166,
              "name": "陈巴尔虎旗"
            },
            {
              "cityId": 109205,
              "parentId": 108166,
              "name": "新巴尔虎左旗"
            },
            {
              "cityId": 109281,
              "parentId": 108166,
              "name": "新巴尔虎右旗"
            },
            {
              "cityId": 109355,
              "parentId": 108166,
              "name": "满洲里市"
            },
            {
              "cityId": 109393,
              "parentId": 108166,
              "name": "牙克石市"
            },
            {
              "cityId": 109490,
              "parentId": 108166,
              "name": "扎兰屯市"
            },
            {
              "cityId": 109738,
              "parentId": 108166,
              "name": "额尔古纳市"
            },
            {
              "cityId": 109813,
              "parentId": 108166,
              "name": "根河市"
            }
          ]
        },
        {
          "cityId": 109847,
          "parentId": 99537,
          "name": "巴彦淖尔市",
          "child": [{
              "cityId": 109848,
              "parentId": 109847,
              "name": "市辖区"
            },
            {
              "cityId": 109849,
              "parentId": 109847,
              "name": "临河区"
            },
            {
              "cityId": 110095,
              "parentId": 109847,
              "name": "五原县"
            },
            {
              "cityId": 110255,
              "parentId": 109847,
              "name": "磴口县"
            },
            {
              "cityId": 110372,
              "parentId": 109847,
              "name": "乌拉特前旗"
            },
            {
              "cityId": 110557,
              "parentId": 109847,
              "name": "乌拉特中旗"
            },
            {
              "cityId": 110676,
              "parentId": 109847,
              "name": "乌拉特后旗"
            },
            {
              "cityId": 110743,
              "parentId": 109847,
              "name": "杭锦后旗"
            }
          ]
        },
        {
          "cityId": 110889,
          "parentId": 99537,
          "name": "乌兰察布市",
          "child": [{
              "cityId": 110890,
              "parentId": 110889,
              "name": "市辖区"
            },
            {
              "cityId": 110891,
              "parentId": 110889,
              "name": "集宁区"
            },
            {
              "cityId": 110998,
              "parentId": 110889,
              "name": "卓资县"
            },
            {
              "cityId": 111127,
              "parentId": 110889,
              "name": "化德县"
            },
            {
              "cityId": 111237,
              "parentId": 110889,
              "name": "商都县"
            },
            {
              "cityId": 111481,
              "parentId": 110889,
              "name": "兴和县"
            },
            {
              "cityId": 111666,
              "parentId": 110889,
              "name": "凉城县"
            },
            {
              "cityId": 111824,
              "parentId": 110889,
              "name": "察哈尔右翼前旗"
            },
            {
              "cityId": 111969,
              "parentId": 110889,
              "name": "察哈尔右翼中旗"
            },
            {
              "cityId": 112164,
              "parentId": 110889,
              "name": "察哈尔右翼后旗"
            },
            {
              "cityId": 112277,
              "parentId": 110889,
              "name": "四子王旗"
            },
            {
              "cityId": 112427,
              "parentId": 110889,
              "name": "丰镇市"
            }
          ]
        },
        {
          "cityId": 112552,
          "parentId": 99537,
          "name": "兴安盟",
          "child": [{
              "cityId": 112553,
              "parentId": 112552,
              "name": "乌兰浩特市"
            },
            {
              "cityId": 112694,
              "parentId": 112552,
              "name": "阿尔山市"
            },
            {
              "cityId": 112734,
              "parentId": 112552,
              "name": "科尔沁右翼前旗"
            },
            {
              "cityId": 113051,
              "parentId": 112552,
              "name": "科尔沁右翼中旗"
            },
            {
              "cityId": 113294,
              "parentId": 112552,
              "name": "扎赉特旗"
            },
            {
              "cityId": 113554,
              "parentId": 112552,
              "name": "突泉县"
            }
          ]
        },
        {
          "cityId": 113777,
          "parentId": 99537,
          "name": "锡林郭勒盟",
          "child": [{
              "cityId": 113778,
              "parentId": 113777,
              "name": "二连浩特市"
            },
            {
              "cityId": 113796,
              "parentId": 113777,
              "name": "锡林浩特市"
            },
            {
              "cityId": 113912,
              "parentId": 113777,
              "name": "阿巴嘎旗"
            },
            {
              "cityId": 113999,
              "parentId": 113777,
              "name": "苏尼特左旗"
            },
            {
              "cityId": 114065,
              "parentId": 113777,
              "name": "苏尼特右旗"
            },
            {
              "cityId": 114152,
              "parentId": 113777,
              "name": "东乌珠穆沁旗"
            },
            {
              "cityId": 114239,
              "parentId": 113777,
              "name": "西乌珠穆沁旗"
            },
            {
              "cityId": 114358,
              "parentId": 113777,
              "name": "太仆寺旗"
            },
            {
              "cityId": 114557,
              "parentId": 113777,
              "name": "镶黄旗"
            },
            {
              "cityId": 114628,
              "parentId": 113777,
              "name": "正镶白旗"
            },
            {
              "cityId": 114722,
              "parentId": 113777,
              "name": "正蓝旗"
            },
            {
              "cityId": 114859,
              "parentId": 113777,
              "name": "多伦县"
            }
          ]
        },
        {
          "cityId": 114940,
          "parentId": 99537,
          "name": "阿拉善盟",
          "child": [{
              "cityId": 114941,
              "parentId": 114940,
              "name": "阿拉善左旗"
            },
            {
              "cityId": 115132,
              "parentId": 114940,
              "name": "阿拉善右旗"
            },
            {
              "cityId": 115189,
              "parentId": 114940,
              "name": "额济纳旗"
            }
          ]
        }
      ]
    },
    {
      "cityId": 115224,
      "parentId": 0,
      "name": "辽宁省",
      "child": [{
          "cityId": 115225,
          "parentId": 115224,
          "name": "沈阳市",
          "child": [{
              "cityId": 115226,
              "parentId": 115225,
              "name": "市辖区"
            },
            {
              "cityId": 115227,
              "parentId": 115225,
              "name": "和平区"
            },
            {
              "cityId": 115347,
              "parentId": 115225,
              "name": "沈河区"
            },
            {
              "cityId": 115474,
              "parentId": 115225,
              "name": "大东区"
            },
            {
              "cityId": 115612,
              "parentId": 115225,
              "name": "皇姑区"
            },
            {
              "cityId": 115752,
              "parentId": 115225,
              "name": "铁西区"
            },
            {
              "cityId": 115964,
              "parentId": 115225,
              "name": "苏家屯区"
            },
            {
              "cityId": 116159,
              "parentId": 115225,
              "name": "浑南区"
            },
            {
              "cityId": 116399,
              "parentId": 115225,
              "name": "沈北新区"
            },
            {
              "cityId": 116614,
              "parentId": 115225,
              "name": "于洪区"
            },
            {
              "cityId": 116820,
              "parentId": 115225,
              "name": "辽中区"
            },
            {
              "cityId": 117075,
              "parentId": 115225,
              "name": "康平县"
            },
            {
              "cityId": 117268,
              "parentId": 115225,
              "name": "法库县"
            },
            {
              "cityId": 117530,
              "parentId": 115225,
              "name": "新民市"
            }
          ]
        },
        {
          "cityId": 117932,
          "parentId": 115224,
          "name": "大连市",
          "child": [{
              "cityId": 117933,
              "parentId": 117932,
              "name": "市辖区"
            },
            {
              "cityId": 117934,
              "parentId": 117932,
              "name": "中山区"
            },
            {
              "cityId": 117997,
              "parentId": 117932,
              "name": "西岗区"
            },
            {
              "cityId": 118050,
              "parentId": 117932,
              "name": "沙河口区"
            },
            {
              "cityId": 118150,
              "parentId": 117932,
              "name": "甘井子区"
            },
            {
              "cityId": 118405,
              "parentId": 117932,
              "name": "旅顺口区"
            },
            {
              "cityId": 118523,
              "parentId": 117932,
              "name": "金州区"
            },
            {
              "cityId": 118884,
              "parentId": 117932,
              "name": "普兰店区"
            },
            {
              "cityId": 119090,
              "parentId": 117932,
              "name": "长海县"
            },
            {
              "cityId": 119126,
              "parentId": 117932,
              "name": "瓦房店市"
            },
            {
              "cityId": 119483,
              "parentId": 117932,
              "name": "庄河市"
            }
          ]
        },
        {
          "cityId": 119789,
          "parentId": 115224,
          "name": "鞍山市",
          "child": [{
              "cityId": 119790,
              "parentId": 119789,
              "name": "市辖区"
            },
            {
              "cityId": 119791,
              "parentId": 119789,
              "name": "铁东区"
            },
            {
              "cityId": 119915,
              "parentId": 119789,
              "name": "铁西区"
            },
            {
              "cityId": 120021,
              "parentId": 119789,
              "name": "立山区"
            },
            {
              "cityId": 120150,
              "parentId": 119789,
              "name": "千山区"
            },
            {
              "cityId": 120221,
              "parentId": 119789,
              "name": "台安县"
            },
            {
              "cityId": 120401,
              "parentId": 119789,
              "name": "岫岩满族自治县"
            },
            {
              "cityId": 120634,
              "parentId": 119789,
              "name": "海城市"
            }
          ]
        },
        {
          "cityId": 121081,
          "parentId": 115224,
          "name": "抚顺市",
          "child": [{
              "cityId": 121082,
              "parentId": 121081,
              "name": "市辖区"
            },
            {
              "cityId": 121083,
              "parentId": 121081,
              "name": "新抚区"
            },
            {
              "cityId": 121181,
              "parentId": 121081,
              "name": "东洲区"
            },
            {
              "cityId": 121326,
              "parentId": 121081,
              "name": "望花区"
            },
            {
              "cityId": 121484,
              "parentId": 121081,
              "name": "顺城区"
            },
            {
              "cityId": 121604,
              "parentId": 121081,
              "name": "抚顺县"
            },
            {
              "cityId": 121710,
              "parentId": 121081,
              "name": "新宾满族自治县"
            },
            {
              "cityId": 121920,
              "parentId": 121081,
              "name": "清原满族自治县"
            }
          ]
        },
        {
          "cityId": 122142,
          "parentId": 115224,
          "name": "本溪市",
          "child": [{
              "cityId": 122143,
              "parentId": 122142,
              "name": "市辖区"
            },
            {
              "cityId": 122144,
              "parentId": 122142,
              "name": "平山区"
            },
            {
              "cityId": 122216,
              "parentId": 122142,
              "name": "溪湖区"
            },
            {
              "cityId": 122310,
              "parentId": 122142,
              "name": "明山区"
            },
            {
              "cityId": 122400,
              "parentId": 122142,
              "name": "南芬区"
            },
            {
              "cityId": 122444,
              "parentId": 122142,
              "name": "本溪满族自治县"
            },
            {
              "cityId": 122591,
              "parentId": 122142,
              "name": "桓仁满族自治县"
            }
          ]
        },
        {
          "cityId": 122730,
          "parentId": 115224,
          "name": "丹东市",
          "child": [{
              "cityId": 122731,
              "parentId": 122730,
              "name": "市辖区"
            },
            {
              "cityId": 122732,
              "parentId": 122730,
              "name": "元宝区"
            },
            {
              "cityId": 122785,
              "parentId": 122730,
              "name": "振兴区"
            },
            {
              "cityId": 122886,
              "parentId": 122730,
              "name": "振安区"
            },
            {
              "cityId": 122958,
              "parentId": 122730,
              "name": "宽甸满族自治县"
            },
            {
              "cityId": 123177,
              "parentId": 122730,
              "name": "东港市"
            },
            {
              "cityId": 123438,
              "parentId": 122730,
              "name": "凤城市"
            }
          ]
        },
        {
          "cityId": 123698,
          "parentId": 115224,
          "name": "锦州市",
          "child": [{
              "cityId": 123699,
              "parentId": 123698,
              "name": "市辖区"
            },
            {
              "cityId": 123700,
              "parentId": 123698,
              "name": "古塔区"
            },
            {
              "cityId": 123778,
              "parentId": 123698,
              "name": "凌河区"
            },
            {
              "cityId": 123869,
              "parentId": 123698,
              "name": "太和区"
            },
            {
              "cityId": 124044,
              "parentId": 123698,
              "name": "黑山县"
            },
            {
              "cityId": 124398,
              "parentId": 123698,
              "name": "义县"
            },
            {
              "cityId": 124670,
              "parentId": 123698,
              "name": "凌海市"
            },
            {
              "cityId": 124980,
              "parentId": 123698,
              "name": "北镇市"
            }
          ]
        },
        {
          "cityId": 125249,
          "parentId": 115224,
          "name": "营口市",
          "child": [{
              "cityId": 125250,
              "parentId": 125249,
              "name": "市辖区"
            },
            {
              "cityId": 125251,
              "parentId": 125249,
              "name": "站前区"
            },
            {
              "cityId": 125305,
              "parentId": 125249,
              "name": "西市区"
            },
            {
              "cityId": 125353,
              "parentId": 125249,
              "name": "鲅鱼圈区"
            },
            {
              "cityId": 125454,
              "parentId": 125249,
              "name": "老边区"
            },
            {
              "cityId": 125520,
              "parentId": 125249,
              "name": "盖州市"
            },
            {
              "cityId": 125849,
              "parentId": 125249,
              "name": "大石桥市"
            }
          ]
        },
        {
          "cityId": 126156,
          "parentId": 115224,
          "name": "阜新市",
          "child": [{
              "cityId": 126157,
              "parentId": 126156,
              "name": "市辖区"
            },
            {
              "cityId": 126158,
              "parentId": 126156,
              "name": "海州区"
            },
            {
              "cityId": 126236,
              "parentId": 126156,
              "name": "新邱区"
            },
            {
              "cityId": 126264,
              "parentId": 126156,
              "name": "太平区"
            },
            {
              "cityId": 126314,
              "parentId": 126156,
              "name": "清河门区"
            },
            {
              "cityId": 126349,
              "parentId": 126156,
              "name": "细河区"
            },
            {
              "cityId": 126417,
              "parentId": 126156,
              "name": "阜新蒙古族自治县"
            },
            {
              "cityId": 126845,
              "parentId": 126156,
              "name": "彰武县"
            }
          ]
        },
        {
          "cityId": 127070,
          "parentId": 115224,
          "name": "辽阳市",
          "child": [{
              "cityId": 127071,
              "parentId": 127070,
              "name": "市辖区"
            },
            {
              "cityId": 127072,
              "parentId": 127070,
              "name": "白塔区"
            },
            {
              "cityId": 127152,
              "parentId": 127070,
              "name": "文圣区"
            },
            {
              "cityId": 127218,
              "parentId": 127070,
              "name": "宏伟区"
            },
            {
              "cityId": 127266,
              "parentId": 127070,
              "name": "弓长岭区"
            },
            {
              "cityId": 127305,
              "parentId": 127070,
              "name": "太子河区"
            },
            {
              "cityId": 127386,
              "parentId": 127070,
              "name": "辽阳县"
            },
            {
              "cityId": 127612,
              "parentId": 127070,
              "name": "灯塔市"
            }
          ]
        },
        {
          "cityId": 127834,
          "parentId": 115224,
          "name": "盘锦市",
          "child": [{
              "cityId": 127835,
              "parentId": 127834,
              "name": "市辖区"
            },
            {
              "cityId": 127836,
              "parentId": 127834,
              "name": "双台子区"
            },
            {
              "cityId": 127896,
              "parentId": 127834,
              "name": "兴隆台区"
            },
            {
              "cityId": 128037,
              "parentId": 127834,
              "name": "大洼区"
            },
            {
              "cityId": 128231,
              "parentId": 127834,
              "name": "盘山县"
            }
          ]
        },
        {
          "cityId": 128421,
          "parentId": 115224,
          "name": "铁岭市",
          "child": [{
              "cityId": 128422,
              "parentId": 128421,
              "name": "市辖区"
            },
            {
              "cityId": 128423,
              "parentId": 128421,
              "name": "银州区"
            },
            {
              "cityId": 128530,
              "parentId": 128421,
              "name": "清河区"
            },
            {
              "cityId": 128591,
              "parentId": 128421,
              "name": "铁岭县"
            },
            {
              "cityId": 128839,
              "parentId": 128421,
              "name": "西丰县"
            },
            {
              "cityId": 129043,
              "parentId": 128421,
              "name": "昌图县"
            },
            {
              "cityId": 129592,
              "parentId": 128421,
              "name": "调兵山市"
            },
            {
              "cityId": 129661,
              "parentId": 128421,
              "name": "开原市"
            }
          ]
        },
        {
          "cityId": 129977,
          "parentId": 115224,
          "name": "朝阳市",
          "child": [{
              "cityId": 129978,
              "parentId": 129977,
              "name": "市辖区"
            },
            {
              "cityId": 129979,
              "parentId": 129977,
              "name": "双塔区"
            },
            {
              "cityId": 130102,
              "parentId": 129977,
              "name": "龙城区"
            },
            {
              "cityId": 130205,
              "parentId": 129977,
              "name": "朝阳县"
            },
            {
              "cityId": 130534,
              "parentId": 129977,
              "name": "建平县"
            },
            {
              "cityId": 130860,
              "parentId": 129977,
              "name": "喀喇沁左翼蒙古族自治县"
            },
            {
              "cityId": 131089,
              "parentId": 129977,
              "name": "北票市"
            },
            {
              "cityId": 131425,
              "parentId": 129977,
              "name": "凌源市"
            }
          ]
        },
        {
          "cityId": 131738,
          "parentId": 115224,
          "name": "葫芦岛市",
          "child": [{
              "cityId": 131739,
              "parentId": 131738,
              "name": "市辖区"
            },
            {
              "cityId": 131740,
              "parentId": 131738,
              "name": "连山区"
            },
            {
              "cityId": 131976,
              "parentId": 131738,
              "name": "龙港区"
            },
            {
              "cityId": 132073,
              "parentId": 131738,
              "name": "南票区"
            },
            {
              "cityId": 132258,
              "parentId": 131738,
              "name": "绥中县"
            },
            {
              "cityId": 132590,
              "parentId": 131738,
              "name": "建昌县"
            },
            {
              "cityId": 132912,
              "parentId": 131738,
              "name": "兴城市"
            }
          ]
        }
      ]
    },
    {
      "cityId": 133208,
      "parentId": 0,
      "name": "吉林省",
      "child": [{
          "cityId": 133209,
          "parentId": 133208,
          "name": "长春市",
          "child": [{
              "cityId": 133210,
              "parentId": 133209,
              "name": "市辖区"
            },
            {
              "cityId": 133211,
              "parentId": 133209,
              "name": "南关区"
            },
            {
              "cityId": 133289,
              "parentId": 133209,
              "name": "宽城区"
            },
            {
              "cityId": 133453,
              "parentId": 133209,
              "name": "朝阳区"
            },
            {
              "cityId": 133543,
              "parentId": 133209,
              "name": "二道区"
            },
            {
              "cityId": 133631,
              "parentId": 133209,
              "name": "绿园区"
            },
            {
              "cityId": 133719,
              "parentId": 133209,
              "name": "双阳区"
            },
            {
              "cityId": 133881,
              "parentId": 133209,
              "name": "九台区"
            },
            {
              "cityId": 134236,
              "parentId": 133209,
              "name": "农安县"
            },
            {
              "cityId": 134628,
              "parentId": 133209,
              "name": "榆树市"
            },
            {
              "cityId": 135061,
              "parentId": 133209,
              "name": "德惠市"
            }
          ]
        },
        {
          "cityId": 135392,
          "parentId": 133208,
          "name": "吉林市",
          "child": [{
              "cityId": 135393,
              "parentId": 135392,
              "name": "市辖区"
            },
            {
              "cityId": 135394,
              "parentId": 135392,
              "name": "昌邑区"
            },
            {
              "cityId": 135568,
              "parentId": 135392,
              "name": "龙潭区"
            },
            {
              "cityId": 135755,
              "parentId": 135392,
              "name": "船营区"
            },
            {
              "cityId": 135885,
              "parentId": 135392,
              "name": "丰满区"
            },
            {
              "cityId": 135986,
              "parentId": 135392,
              "name": "永吉县"
            },
            {
              "cityId": 136136,
              "parentId": 135392,
              "name": "蛟河市"
            },
            {
              "cityId": 136439,
              "parentId": 135392,
              "name": "桦甸市"
            },
            {
              "cityId": 136629,
              "parentId": 135392,
              "name": "舒兰市"
            },
            {
              "cityId": 136889,
              "parentId": 135392,
              "name": "磐石市"
            }
          ]
        },
        {
          "cityId": 137206,
          "parentId": 133208,
          "name": "四平市",
          "child": [{
              "cityId": 137207,
              "parentId": 137206,
              "name": "市辖区"
            },
            {
              "cityId": 137208,
              "parentId": 137206,
              "name": "铁西区"
            },
            {
              "cityId": 137275,
              "parentId": 137206,
              "name": "铁东区"
            },
            {
              "cityId": 137386,
              "parentId": 137206,
              "name": "梨树县"
            },
            {
              "cityId": 137756,
              "parentId": 137206,
              "name": "伊通满族自治县"
            },
            {
              "cityId": 137973,
              "parentId": 137206,
              "name": "公主岭市"
            },
            {
              "cityId": 138477,
              "parentId": 137206,
              "name": "双辽市"
            }
          ]
        },
        {
          "cityId": 138721,
          "parentId": 133208,
          "name": "辽源市",
          "child": [{
              "cityId": 138722,
              "parentId": 138721,
              "name": "市辖区"
            },
            {
              "cityId": 138723,
              "parentId": 138721,
              "name": "龙山区"
            },
            {
              "cityId": 138780,
              "parentId": 138721,
              "name": "西安区"
            },
            {
              "cityId": 138818,
              "parentId": 138721,
              "name": "东丰县"
            },
            {
              "cityId": 139072,
              "parentId": 138721,
              "name": "东辽县"
            }
          ]
        },
        {
          "cityId": 139340,
          "parentId": 133208,
          "name": "通化市",
          "child": [{
              "cityId": 139341,
              "parentId": 139340,
              "name": "市辖区"
            },
            {
              "cityId": 139342,
              "parentId": 139340,
              "name": "东昌区"
            },
            {
              "cityId": 139405,
              "parentId": 139340,
              "name": "二道江区"
            },
            {
              "cityId": 139453,
              "parentId": 139340,
              "name": "通化县"
            },
            {
              "cityId": 139654,
              "parentId": 139340,
              "name": "辉南县"
            },
            {
              "cityId": 139824,
              "parentId": 139340,
              "name": "柳河县"
            },
            {
              "cityId": 140077,
              "parentId": 139340,
              "name": "梅河口市"
            },
            {
              "cityId": 140432,
              "parentId": 139340,
              "name": "集安市"
            }
          ]
        },
        {
          "cityId": 140601,
          "parentId": 133208,
          "name": "白山市",
          "child": [{
              "cityId": 140602,
              "parentId": 140601,
              "name": "市辖区"
            },
            {
              "cityId": 140603,
              "parentId": 140601,
              "name": "浑江区"
            },
            {
              "cityId": 140714,
              "parentId": 140601,
              "name": "江源区"
            },
            {
              "cityId": 140816,
              "parentId": 140601,
              "name": "抚松县"
            },
            {
              "cityId": 141003,
              "parentId": 140601,
              "name": "靖宇县"
            },
            {
              "cityId": 141140,
              "parentId": 140601,
              "name": "长白朝鲜族自治县"
            },
            {
              "cityId": 141237,
              "parentId": 140601,
              "name": "临江市"
            }
          ]
        },
        {
          "cityId": 141361,
          "parentId": 133208,
          "name": "松原市",
          "child": [{
              "cityId": 141362,
              "parentId": 141361,
              "name": "市辖区"
            },
            {
              "cityId": 141363,
              "parentId": 141361,
              "name": "宁江区"
            },
            {
              "cityId": 141560,
              "parentId": 141361,
              "name": "前郭尔罗斯蒙古族自治县"
            },
            {
              "cityId": 141870,
              "parentId": 141361,
              "name": "长岭县"
            },
            {
              "cityId": 142157,
              "parentId": 141361,
              "name": "乾安县"
            },
            {
              "cityId": 142353,
              "parentId": 141361,
              "name": "扶余市"
            }
          ]
        },
        {
          "cityId": 142786,
          "parentId": 133208,
          "name": "白城市",
          "child": [{
              "cityId": 142787,
              "parentId": 142786,
              "name": "市辖区"
            },
            {
              "cityId": 142788,
              "parentId": 142786,
              "name": "洮北区"
            },
            {
              "cityId": 143021,
              "parentId": 142786,
              "name": "镇赉县"
            },
            {
              "cityId": 143209,
              "parentId": 142786,
              "name": "通榆县"
            },
            {
              "cityId": 143428,
              "parentId": 142786,
              "name": "洮南市"
            },
            {
              "cityId": 143702,
              "parentId": 142786,
              "name": "大安市"
            }
          ]
        },
        {
          "cityId": 143990,
          "parentId": 133208,
          "name": "延边朝鲜族自治州",
          "child": [{
              "cityId": 143991,
              "parentId": 143990,
              "name": "延吉市"
            },
            {
              "cityId": 144147,
              "parentId": 143990,
              "name": "图们市"
            },
            {
              "cityId": 144226,
              "parentId": 143990,
              "name": "敦化市"
            },
            {
              "cityId": 144665,
              "parentId": 143990,
              "name": "珲春市"
            },
            {
              "cityId": 144844,
              "parentId": 143990,
              "name": "龙井市"
            },
            {
              "cityId": 144953,
              "parentId": 143990,
              "name": "和龙市"
            },
            {
              "cityId": 145096,
              "parentId": 143990,
              "name": "汪清县"
            },
            {
              "cityId": 145421,
              "parentId": 143990,
              "name": "安图县"
            }
          ]
        }
      ]
    },
    {
      "cityId": 145665,
      "parentId": 0,
      "name": "黑龙江省",
      "child": [{
          "cityId": 145666,
          "parentId": 145665,
          "name": "哈尔滨市",
          "child": [{
              "cityId": 145667,
              "parentId": 145666,
              "name": "市辖区"
            },
            {
              "cityId": 145668,
              "parentId": 145666,
              "name": "道里区"
            },
            {
              "cityId": 145864,
              "parentId": 145666,
              "name": "南岗区"
            },
            {
              "cityId": 146071,
              "parentId": 145666,
              "name": "道外区"
            },
            {
              "cityId": 146266,
              "parentId": 145666,
              "name": "平房区"
            },
            {
              "cityId": 146313,
              "parentId": 145666,
              "name": "松北区"
            },
            {
              "cityId": 146399,
              "parentId": 145666,
              "name": "香坊区"
            },
            {
              "cityId": 146587,
              "parentId": 145666,
              "name": "呼兰区"
            },
            {
              "cityId": 146825,
              "parentId": 145666,
              "name": "阿城区"
            },
            {
              "cityId": 147032,
              "parentId": 145666,
              "name": "双城区"
            },
            {
              "cityId": 147391,
              "parentId": 145666,
              "name": "依兰县"
            },
            {
              "cityId": 147571,
              "parentId": 145666,
              "name": "方正县"
            },
            {
              "cityId": 147676,
              "parentId": 145666,
              "name": "宾县"
            },
            {
              "cityId": 147884,
              "parentId": 145666,
              "name": "巴彦县"
            },
            {
              "cityId": 148060,
              "parentId": 145666,
              "name": "木兰县"
            },
            {
              "cityId": 148178,
              "parentId": 145666,
              "name": "通河县"
            },
            {
              "cityId": 148351,
              "parentId": 145666,
              "name": "延寿县"
            },
            {
              "cityId": 148502,
              "parentId": 145666,
              "name": "尚志市"
            },
            {
              "cityId": 148755,
              "parentId": 145666,
              "name": "五常市"
            }
          ]
        },
        {
          "cityId": 149079,
          "parentId": 145665,
          "name": "齐齐哈尔市",
          "child": [{
              "cityId": 149080,
              "parentId": 149079,
              "name": "市辖区"
            },
            {
              "cityId": 149081,
              "parentId": 149079,
              "name": "龙沙区"
            },
            {
              "cityId": 149129,
              "parentId": 149079,
              "name": "建华区"
            },
            {
              "cityId": 149173,
              "parentId": 149079,
              "name": "铁锋区"
            },
            {
              "cityId": 149231,
              "parentId": 149079,
              "name": "昂昂溪区"
            },
            {
              "cityId": 149266,
              "parentId": 149079,
              "name": "富拉尔基区"
            },
            {
              "cityId": 149317,
              "parentId": 149079,
              "name": "碾子山区"
            },
            {
              "cityId": 149338,
              "parentId": 149079,
              "name": "梅里斯达斡尔族区"
            },
            {
              "cityId": 149404,
              "parentId": 149079,
              "name": "龙江县"
            },
            {
              "cityId": 149588,
              "parentId": 149079,
              "name": "依安县"
            },
            {
              "cityId": 149767,
              "parentId": 149079,
              "name": "泰来县"
            },
            {
              "cityId": 149896,
              "parentId": 149079,
              "name": "甘南县"
            },
            {
              "cityId": 150046,
              "parentId": 149079,
              "name": "富裕县"
            },
            {
              "cityId": 150168,
              "parentId": 149079,
              "name": "克山县"
            },
            {
              "cityId": 150337,
              "parentId": 149079,
              "name": "克东县"
            },
            {
              "cityId": 150471,
              "parentId": 149079,
              "name": "拜泉县"
            },
            {
              "cityId": 150680,
              "parentId": 149079,
              "name": "讷河市"
            }
          ]
        },
        {
          "cityId": 150915,
          "parentId": 145665,
          "name": "鸡西市",
          "child": [{
              "cityId": 150916,
              "parentId": 150915,
              "name": "市辖区"
            },
            {
              "cityId": 150917,
              "parentId": 150915,
              "name": "鸡冠区"
            },
            {
              "cityId": 150984,
              "parentId": 150915,
              "name": "恒山区"
            },
            {
              "cityId": 151039,
              "parentId": 150915,
              "name": "滴道区"
            },
            {
              "cityId": 151084,
              "parentId": 150915,
              "name": "梨树区"
            },
            {
              "cityId": 151113,
              "parentId": 150915,
              "name": "城子河区"
            },
            {
              "cityId": 151154,
              "parentId": 150915,
              "name": "麻山区"
            },
            {
              "cityId": 151177,
              "parentId": 150915,
              "name": "鸡东县"
            },
            {
              "cityId": 151347,
              "parentId": 150915,
              "name": "虎林市"
            },
            {
              "cityId": 151577,
              "parentId": 150915,
              "name": "密山市"
            }
          ]
        },
        {
          "cityId": 151830,
          "parentId": 145665,
          "name": "鹤岗市",
          "child": [{
              "cityId": 151831,
              "parentId": 151830,
              "name": "市辖区"
            },
            {
              "cityId": 151832,
              "parentId": 151830,
              "name": "向阳区"
            },
            {
              "cityId": 151855,
              "parentId": 151830,
              "name": "工农区"
            },
            {
              "cityId": 151880,
              "parentId": 151830,
              "name": "南山区"
            },
            {
              "cityId": 151912,
              "parentId": 151830,
              "name": "兴安区"
            },
            {
              "cityId": 151947,
              "parentId": 151830,
              "name": "东山区"
            },
            {
              "cityId": 152026,
              "parentId": 151830,
              "name": "兴山区"
            },
            {
              "cityId": 152047,
              "parentId": 151830,
              "name": "萝北县"
            },
            {
              "cityId": 152237,
              "parentId": 151830,
              "name": "绥滨县"
            }
          ]
        },
        {
          "cityId": 152413,
          "parentId": 145665,
          "name": "双鸭山市",
          "child": [{
              "cityId": 152414,
              "parentId": 152413,
              "name": "市辖区"
            },
            {
              "cityId": 152415,
              "parentId": 152413,
              "name": "尖山区"
            },
            {
              "cityId": 152469,
              "parentId": 152413,
              "name": "岭东区"
            },
            {
              "cityId": 152508,
              "parentId": 152413,
              "name": "四方台区"
            },
            {
              "cityId": 152544,
              "parentId": 152413,
              "name": "宝山区"
            },
            {
              "cityId": 152590,
              "parentId": 152413,
              "name": "集贤县"
            },
            {
              "cityId": 152810,
              "parentId": 152413,
              "name": "友谊县"
            },
            {
              "cityId": 152910,
              "parentId": 152413,
              "name": "宝清县"
            },
            {
              "cityId": 153142,
              "parentId": 152413,
              "name": "饶河县"
            }
          ]
        },
        {
          "cityId": 153313,
          "parentId": 145665,
          "name": "大庆市",
          "child": [{
              "cityId": 153314,
              "parentId": 153313,
              "name": "市辖区"
            },
            {
              "cityId": 153315,
              "parentId": 153313,
              "name": "萨尔图区"
            },
            {
              "cityId": 153371,
              "parentId": 153313,
              "name": "龙凤区"
            },
            {
              "cityId": 153423,
              "parentId": 153313,
              "name": "让胡路区"
            },
            {
              "cityId": 153513,
              "parentId": 153313,
              "name": "红岗区"
            },
            {
              "cityId": 153561,
              "parentId": 153313,
              "name": "大同区"
            },
            {
              "cityId": 153659,
              "parentId": 153313,
              "name": "肇州县"
            },
            {
              "cityId": 153791,
              "parentId": 153313,
              "name": "肇源县"
            },
            {
              "cityId": 153974,
              "parentId": 153313,
              "name": "林甸县"
            },
            {
              "cityId": 154082,
              "parentId": 153313,
              "name": "杜尔伯特蒙古族自治县"
            }
          ]
        },
        {
          "cityId": 154201,
          "parentId": 145665,
          "name": "伊春市",
          "child": [{
              "cityId": 154202,
              "parentId": 154201,
              "name": "市辖区"
            },
            {
              "cityId": 154203,
              "parentId": 154201,
              "name": "伊春区"
            },
            {
              "cityId": 154252,
              "parentId": 154201,
              "name": "南岔区"
            },
            {
              "cityId": 154342,
              "parentId": 154201,
              "name": "友好区"
            },
            {
              "cityId": 154389,
              "parentId": 154201,
              "name": "西林区"
            },
            {
              "cityId": 154404,
              "parentId": 154201,
              "name": "翠峦区"
            },
            {
              "cityId": 154434,
              "parentId": 154201,
              "name": "新青区"
            },
            {
              "cityId": 154473,
              "parentId": 154201,
              "name": "美溪区"
            },
            {
              "cityId": 154511,
              "parentId": 154201,
              "name": "金山屯区"
            },
            {
              "cityId": 154547,
              "parentId": 154201,
              "name": "五营区"
            },
            {
              "cityId": 154579,
              "parentId": 154201,
              "name": "乌马河区"
            },
            {
              "cityId": 154616,
              "parentId": 154201,
              "name": "汤旺河区"
            },
            {
              "cityId": 154659,
              "parentId": 154201,
              "name": "带岭区"
            },
            {
              "cityId": 154695,
              "parentId": 154201,
              "name": "乌伊岭区"
            },
            {
              "cityId": 154729,
              "parentId": 154201,
              "name": "红星区"
            },
            {
              "cityId": 154759,
              "parentId": 154201,
              "name": "上甘岭区"
            },
            {
              "cityId": 154793,
              "parentId": 154201,
              "name": "嘉荫县"
            },
            {
              "cityId": 154899,
              "parentId": 154201,
              "name": "铁力市"
            }
          ]
        },
        {
          "cityId": 155076,
          "parentId": 145665,
          "name": "佳木斯市",
          "child": [{
              "cityId": 155077,
              "parentId": 155076,
              "name": "市辖区"
            },
            {
              "cityId": 155078,
              "parentId": 155076,
              "name": "向阳区"
            },
            {
              "cityId": 155166,
              "parentId": 155076,
              "name": "前进区"
            },
            {
              "cityId": 155191,
              "parentId": 155076,
              "name": "东风区"
            },
            {
              "cityId": 155254,
              "parentId": 155076,
              "name": "郊区"
            },
            {
              "cityId": 155435,
              "parentId": 155076,
              "name": "桦南县"
            },
            {
              "cityId": 155702,
              "parentId": 155076,
              "name": "桦川县"
            },
            {
              "cityId": 155838,
              "parentId": 155076,
              "name": "汤原县"
            },
            {
              "cityId": 156034,
              "parentId": 155076,
              "name": "同江市"
            },
            {
              "cityId": 156209,
              "parentId": 155076,
              "name": "富锦市"
            },
            {
              "cityId": 156599,
              "parentId": 155076,
              "name": "抚远市"
            }
          ]
        },
        {
          "cityId": 156713,
          "parentId": 145665,
          "name": "七台河市",
          "child": [{
              "cityId": 156714,
              "parentId": 156713,
              "name": "市辖区"
            },
            {
              "cityId": 156715,
              "parentId": 156713,
              "name": "新兴区"
            },
            {
              "cityId": 156815,
              "parentId": 156713,
              "name": "桃山区"
            },
            {
              "cityId": 156865,
              "parentId": 156713,
              "name": "茄子河区"
            },
            {
              "cityId": 156956,
              "parentId": 156713,
              "name": "勃利县"
            }
          ]
        },
        {
          "cityId": 157130,
          "parentId": 145665,
          "name": "牡丹江市",
          "child": [{
              "cityId": 157131,
              "parentId": 157130,
              "name": "市辖区"
            },
            {
              "cityId": 157132,
              "parentId": 157130,
              "name": "东安区"
            },
            {
              "cityId": 157170,
              "parentId": 157130,
              "name": "阳明区"
            },
            {
              "cityId": 157273,
              "parentId": 157130,
              "name": "爱民区"
            },
            {
              "cityId": 157321,
              "parentId": 157130,
              "name": "西安区"
            },
            {
              "cityId": 157406,
              "parentId": 157130,
              "name": "林口县"
            },
            {
              "cityId": 157646,
              "parentId": 157130,
              "name": "绥芬河市"
            },
            {
              "cityId": 157678,
              "parentId": 157130,
              "name": "海林市"
            },
            {
              "cityId": 157911,
              "parentId": 157130,
              "name": "宁安市"
            },
            {
              "cityId": 158233,
              "parentId": 157130,
              "name": "穆棱市"
            },
            {
              "cityId": 158470,
              "parentId": 157130,
              "name": "东宁市"
            }
          ]
        },
        {
          "cityId": 158623,
          "parentId": 145665,
          "name": "黑河市",
          "child": [{
              "cityId": 158624,
              "parentId": 158623,
              "name": "市辖区"
            },
            {
              "cityId": 158625,
              "parentId": 158623,
              "name": "爱辉区"
            },
            {
              "cityId": 158797,
              "parentId": 158623,
              "name": "嫩江县"
            },
            {
              "cityId": 159105,
              "parentId": 158623,
              "name": "逊克县"
            },
            {
              "cityId": 159220,
              "parentId": 158623,
              "name": "孙吴县"
            },
            {
              "cityId": 159359,
              "parentId": 158623,
              "name": "北安市"
            },
            {
              "cityId": 159526,
              "parentId": 158623,
              "name": "五大连池市"
            }
          ]
        },
        {
          "cityId": 159769,
          "parentId": 145665,
          "name": "绥化市",
          "child": [{
              "cityId": 159770,
              "parentId": 159769,
              "name": "市辖区"
            },
            {
              "cityId": 159771,
              "parentId": 159769,
              "name": "北林区"
            },
            {
              "cityId": 159985,
              "parentId": 159769,
              "name": "望奎县"
            },
            {
              "cityId": 160128,
              "parentId": 159769,
              "name": "兰西县"
            },
            {
              "cityId": 160271,
              "parentId": 159769,
              "name": "青冈县"
            },
            {
              "cityId": 160478,
              "parentId": 159769,
              "name": "庆安县"
            },
            {
              "cityId": 160610,
              "parentId": 159769,
              "name": "明水县"
            },
            {
              "cityId": 160744,
              "parentId": 159769,
              "name": "绥棱县"
            },
            {
              "cityId": 160910,
              "parentId": 159769,
              "name": "安达市"
            },
            {
              "cityId": 161079,
              "parentId": 159769,
              "name": "肇东市"
            },
            {
              "cityId": 161331,
              "parentId": 159769,
              "name": "海伦市"
            }
          ]
        },
        {
          "cityId": 161651,
          "parentId": 145665,
          "name": "大兴安岭地区",
          "child": [{
              "cityId": 161652,
              "parentId": 161651,
              "name": "呼玛县"
            },
            {
              "cityId": 161720,
              "parentId": 161651,
              "name": "塔河县"
            },
            {
              "cityId": 161754,
              "parentId": 161651,
              "name": "漠河县"
            }
          ]
        }
      ]
    },
    {
      "cityId": 161792,
      "parentId": 0,
      "name": "上海市",
      "child": [{
        "cityId": 161793,
        "parentId": 161792,
        "name": "上海市",
        "child": [{
            "cityId": 161794,
            "parentId": 161793,
            "name": "黄浦区"
          },
          {
            "cityId": 161988,
            "parentId": 161793,
            "name": "徐汇区"
          },
          {
            "cityId": 162309,
            "parentId": 161793,
            "name": "长宁区"
          },
          {
            "cityId": 162505,
            "parentId": 161793,
            "name": "静安区"
          },
          {
            "cityId": 162794,
            "parentId": 161793,
            "name": "普陀区"
          },
          {
            "cityId": 163069,
            "parentId": 161793,
            "name": "虹口区"
          },
          {
            "cityId": 163290,
            "parentId": 161793,
            "name": "杨浦区"
          },
          {
            "cityId": 163621,
            "parentId": 161793,
            "name": "闵行区"
          },
          {
            "cityId": 164194,
            "parentId": 161793,
            "name": "宝山区"
          },
          {
            "cityId": 164682,
            "parentId": 161793,
            "name": "嘉定区"
          },
          {
            "cityId": 165026,
            "parentId": 161793,
            "name": "浦东新区"
          },
          {
            "cityId": 166350,
            "parentId": 161793,
            "name": "金山区"
          },
          {
            "cityId": 166585,
            "parentId": 161793,
            "name": "松江区"
          },
          {
            "cityId": 166927,
            "parentId": 161793,
            "name": "青浦区"
          },
          {
            "cityId": 167228,
            "parentId": 161793,
            "name": "奉贤区"
          },
          {
            "cityId": 167533,
            "parentId": 161793,
            "name": "崇明区"
          }
        ]
      }]
    },
    {
      "cityId": 167904,
      "parentId": 0,
      "name": "江苏省",
      "child": [{
          "cityId": 167905,
          "parentId": 167904,
          "name": "南京市",
          "child": [{
              "cityId": 167906,
              "parentId": 167905,
              "name": "市辖区"
            },
            {
              "cityId": 167907,
              "parentId": 167905,
              "name": "玄武区"
            },
            {
              "cityId": 167974,
              "parentId": 167905,
              "name": "秦淮区"
            },
            {
              "cityId": 168104,
              "parentId": 167905,
              "name": "建邺区"
            },
            {
              "cityId": 168174,
              "parentId": 167905,
              "name": "鼓楼区"
            },
            {
              "cityId": 168310,
              "parentId": 167905,
              "name": "浦口区"
            },
            {
              "cityId": 168451,
              "parentId": 167905,
              "name": "栖霞区"
            },
            {
              "cityId": 168604,
              "parentId": 167905,
              "name": "雨花台区"
            },
            {
              "cityId": 168704,
              "parentId": 167905,
              "name": "江宁区"
            },
            {
              "cityId": 168925,
              "parentId": 167905,
              "name": "六合区"
            },
            {
              "cityId": 169096,
              "parentId": 167905,
              "name": "溧水区"
            },
            {
              "cityId": 169214,
              "parentId": 167905,
              "name": "高淳区"
            }
          ]
        },
        {
          "cityId": 169375,
          "parentId": 167904,
          "name": "无锡市",
          "child": [{
              "cityId": 169376,
              "parentId": 169375,
              "name": "市辖区"
            },
            {
              "cityId": 169377,
              "parentId": 169375,
              "name": "锡山区"
            },
            {
              "cityId": 169507,
              "parentId": 169375,
              "name": "惠山区"
            },
            {
              "cityId": 169628,
              "parentId": 169375,
              "name": "滨湖区"
            },
            {
              "cityId": 169751,
              "parentId": 169375,
              "name": "梁溪区"
            },
            {
              "cityId": 169924,
              "parentId": 169375,
              "name": "新吴区"
            },
            {
              "cityId": 170064,
              "parentId": 169375,
              "name": "江阴市"
            },
            {
              "cityId": 170390,
              "parentId": 169375,
              "name": "宜兴市"
            }
          ]
        },
        {
          "cityId": 170724,
          "parentId": 167904,
          "name": "徐州市",
          "child": [{
              "cityId": 170725,
              "parentId": 170724,
              "name": "市辖区"
            },
            {
              "cityId": 170726,
              "parentId": 170724,
              "name": "鼓楼区"
            },
            {
              "cityId": 170800,
              "parentId": 170724,
              "name": "云龙区"
            },
            {
              "cityId": 170886,
              "parentId": 170724,
              "name": "贾汪区"
            },
            {
              "cityId": 171049,
              "parentId": 170724,
              "name": "泉山区"
            },
            {
              "cityId": 171182,
              "parentId": 170724,
              "name": "铜山区"
            },
            {
              "cityId": 171537,
              "parentId": 170724,
              "name": "丰县"
            },
            {
              "cityId": 171966,
              "parentId": 170724,
              "name": "沛县"
            },
            {
              "cityId": 172381,
              "parentId": 170724,
              "name": "睢宁县"
            },
            {
              "cityId": 172804,
              "parentId": 170724,
              "name": "新沂市"
            },
            {
              "cityId": 173105,
              "parentId": 170724,
              "name": "邳州市"
            }
          ]
        },
        {
          "cityId": 173635,
          "parentId": 167904,
          "name": "常州市",
          "child": [{
              "cityId": 173636,
              "parentId": 173635,
              "name": "市辖区"
            },
            {
              "cityId": 173637,
              "parentId": 173635,
              "name": "天宁区"
            },
            {
              "cityId": 173773,
              "parentId": 173635,
              "name": "钟楼区"
            },
            {
              "cityId": 173891,
              "parentId": 173635,
              "name": "新北区"
            },
            {
              "cityId": 174038,
              "parentId": 173635,
              "name": "武进区"
            },
            {
              "cityId": 174347,
              "parentId": 173635,
              "name": "金坛区"
            },
            {
              "cityId": 174508,
              "parentId": 173635,
              "name": "溧阳市"
            }
          ]
        },
        {
          "cityId": 174764,
          "parentId": 167904,
          "name": "苏州市",
          "child": [{
              "cityId": 174765,
              "parentId": 174764,
              "name": "工业园区"
            },
            {
              "cityId": 174766,
              "parentId": 174764,
              "name": "虎丘区"
            },
            {
              "cityId": 174872,
              "parentId": 174764,
              "name": "吴中区"
            },
            {
              "cityId": 175088,
              "parentId": 174764,
              "name": "相城区"
            },
            {
              "cityId": 175247,
              "parentId": 174764,
              "name": "姑苏区"
            },
            {
              "cityId": 175444,
              "parentId": 174764,
              "name": "吴江区"
            },
            {
              "cityId": 175765,
              "parentId": 174764,
              "name": "常熟市"
            },
            {
              "cityId": 176120,
              "parentId": 174764,
              "name": "张家港市"
            },
            {
              "cityId": 176424,
              "parentId": 174764,
              "name": "昆山市"
            },
            {
              "cityId": 176763,
              "parentId": 174764,
              "name": "太仓市"
            }
          ]
        },
        {
          "cityId": 176920,
          "parentId": 167904,
          "name": "南通市",
          "child": [{
              "cityId": 176921,
              "parentId": 176920,
              "name": "市辖区"
            },
            {
              "cityId": 176922,
              "parentId": 176920,
              "name": "崇川区"
            },
            {
              "cityId": 177040,
              "parentId": 176920,
              "name": "港闸区"
            },
            {
              "cityId": 177119,
              "parentId": 176920,
              "name": "通州区"
            },
            {
              "cityId": 177415,
              "parentId": 176920,
              "name": "海安县"
            },
            {
              "cityId": 177665,
              "parentId": 176920,
              "name": "如东县"
            },
            {
              "cityId": 177946,
              "parentId": 176920,
              "name": "启东市"
            },
            {
              "cityId": 178291,
              "parentId": 176920,
              "name": "如皋市"
            },
            {
              "cityId": 178669,
              "parentId": 176920,
              "name": "海门市"
            }
          ]
        },
        {
          "cityId": 178999,
          "parentId": 167904,
          "name": "连云港市",
          "child": [{
              "cityId": 179000,
              "parentId": 178999,
              "name": "市辖区"
            },
            {
              "cityId": 179001,
              "parentId": 178999,
              "name": "连云区"
            },
            {
              "cityId": 179081,
              "parentId": 178999,
              "name": "海州区"
            },
            {
              "cityId": 179296,
              "parentId": 178999,
              "name": "赣榆区"
            },
            {
              "cityId": 179786,
              "parentId": 178999,
              "name": "东海县"
            },
            {
              "cityId": 180136,
              "parentId": 178999,
              "name": "灌云县"
            },
            {
              "cityId": 180493,
              "parentId": 178999,
              "name": "灌南县"
            }
          ]
        },
        {
          "cityId": 180743,
          "parentId": 167904,
          "name": "淮安市",
          "child": [{
              "cityId": 180744,
              "parentId": 180743,
              "name": "市辖区"
            },
            {
              "cityId": 180745,
              "parentId": 180743,
              "name": "淮安区"
            },
            {
              "cityId": 181117,
              "parentId": 180743,
              "name": "淮阴区"
            },
            {
              "cityId": 181401,
              "parentId": 180743,
              "name": "清江浦区"
            },
            {
              "cityId": 181547,
              "parentId": 180743,
              "name": "洪泽区"
            },
            {
              "cityId": 181681,
              "parentId": 180743,
              "name": "涟水县"
            },
            {
              "cityId": 182092,
              "parentId": 180743,
              "name": "盱眙县"
            },
            {
              "cityId": 182372,
              "parentId": 180743,
              "name": "金湖县"
            }
          ]
        },
        {
          "cityId": 182538,
          "parentId": 167904,
          "name": "盐城市",
          "child": [{
              "cityId": 182539,
              "parentId": 182538,
              "name": "市辖区"
            },
            {
              "cityId": 182540,
              "parentId": 182538,
              "name": "亭湖区"
            },
            {
              "cityId": 182762,
              "parentId": 182538,
              "name": "盐都区"
            },
            {
              "cityId": 183048,
              "parentId": 182538,
              "name": "大丰区"
            },
            {
              "cityId": 183334,
              "parentId": 182538,
              "name": "响水县"
            },
            {
              "cityId": 183510,
              "parentId": 182538,
              "name": "滨海县"
            },
            {
              "cityId": 183829,
              "parentId": 182538,
              "name": "阜宁县"
            },
            {
              "cityId": 184188,
              "parentId": 182538,
              "name": "射阳县"
            },
            {
              "cityId": 184485,
              "parentId": 182538,
              "name": "建湖县"
            },
            {
              "cityId": 184748,
              "parentId": 182538,
              "name": "东台市"
            }
          ]
        },
        {
          "cityId": 185201,
          "parentId": 167904,
          "name": "扬州市",
          "child": [{
              "cityId": 185202,
              "parentId": 185201,
              "name": "市辖区"
            },
            {
              "cityId": 185203,
              "parentId": 185201,
              "name": "广陵区"
            },
            {
              "cityId": 185360,
              "parentId": 185201,
              "name": "邗江区"
            },
            {
              "cityId": 185555,
              "parentId": 185201,
              "name": "江都区"
            },
            {
              "cityId": 185905,
              "parentId": 185201,
              "name": "宝应县"
            },
            {
              "cityId": 186203,
              "parentId": 185201,
              "name": "仪征市"
            },
            {
              "cityId": 186414,
              "parentId": 185201,
              "name": "高邮市"
            }
          ]
        },
        {
          "cityId": 186657,
          "parentId": 167904,
          "name": "镇江市",
          "child": [{
              "cityId": 186658,
              "parentId": 186657,
              "name": "市辖区"
            },
            {
              "cityId": 186659,
              "parentId": 186657,
              "name": "京口区"
            },
            {
              "cityId": 186734,
              "parentId": 186657,
              "name": "润州区"
            },
            {
              "cityId": 186817,
              "parentId": 186657,
              "name": "丹徒区"
            },
            {
              "cityId": 186919,
              "parentId": 186657,
              "name": "丹阳市"
            },
            {
              "cityId": 187149,
              "parentId": 186657,
              "name": "扬中市"
            },
            {
              "cityId": 187235,
              "parentId": 186657,
              "name": "句容市"
            }
          ]
        },
        {
          "cityId": 187441,
          "parentId": 167904,
          "name": "泰州市",
          "child": [{
              "cityId": 187442,
              "parentId": 187441,
              "name": "市辖区"
            },
            {
              "cityId": 187443,
              "parentId": 187441,
              "name": "海陵区"
            },
            {
              "cityId": 187593,
              "parentId": 187441,
              "name": "高港区"
            },
            {
              "cityId": 187693,
              "parentId": 187441,
              "name": "姜堰区"
            },
            {
              "cityId": 188035,
              "parentId": 187441,
              "name": "兴化市"
            },
            {
              "cityId": 188752,
              "parentId": 187441,
              "name": "靖江市"
            },
            {
              "cityId": 189026,
              "parentId": 187441,
              "name": "泰兴市"
            }
          ]
        },
        {
          "cityId": 189402,
          "parentId": 167904,
          "name": "宿迁市",
          "child": [{
              "cityId": 189403,
              "parentId": 189402,
              "name": "市辖区"
            },
            {
              "cityId": 189404,
              "parentId": 189402,
              "name": "宿城区"
            },
            {
              "cityId": 189637,
              "parentId": 189402,
              "name": "宿豫区"
            },
            {
              "cityId": 189850,
              "parentId": 189402,
              "name": "沭阳县"
            },
            {
              "cityId": 190377,
              "parentId": 189402,
              "name": "泗阳县"
            },
            {
              "cityId": 190642,
              "parentId": 189402,
              "name": "泗洪县"
            }
          ]
        }
      ]
    },
    {
      "cityId": 191019,
      "parentId": 0,
      "name": "浙江省",
      "child": [{
          "cityId": 191020,
          "parentId": 191019,
          "name": "杭州市",
          "child": [{
              "cityId": 191021,
              "parentId": 191020,
              "name": "市辖区"
            },
            {
              "cityId": 191022,
              "parentId": 191020,
              "name": "上城区"
            },
            {
              "cityId": 191083,
              "parentId": 191020,
              "name": "下城区"
            },
            {
              "cityId": 191166,
              "parentId": 191020,
              "name": "江干区"
            },
            {
              "cityId": 191360,
              "parentId": 191020,
              "name": "拱墅区"
            },
            {
              "cityId": 191479,
              "parentId": 191020,
              "name": "西湖区"
            },
            {
              "cityId": 191693,
              "parentId": 191020,
              "name": "滨江区"
            },
            {
              "cityId": 191756,
              "parentId": 191020,
              "name": "萧山区"
            },
            {
              "cityId": 192388,
              "parentId": 191020,
              "name": "余杭区"
            },
            {
              "cityId": 192753,
              "parentId": 191020,
              "name": "富阳区"
            },
            {
              "cityId": 193085,
              "parentId": 191020,
              "name": "桐庐县"
            },
            {
              "cityId": 193304,
              "parentId": 191020,
              "name": "淳安县"
            },
            {
              "cityId": 193766,
              "parentId": 191020,
              "name": "建德市"
            },
            {
              "cityId": 194060,
              "parentId": 191020,
              "name": "临安市"
            }
          ]
        },
        {
          "cityId": 194395,
          "parentId": 191019,
          "name": "宁波市",
          "child": [{
              "cityId": 194396,
              "parentId": 194395,
              "name": "市辖区"
            },
            {
              "cityId": 194397,
              "parentId": 194395,
              "name": "海曙区"
            },
            {
              "cityId": 194484,
              "parentId": 194395,
              "name": "江东区"
            },
            {
              "cityId": 194568,
              "parentId": 194395,
              "name": "江北区"
            },
            {
              "cityId": 194747,
              "parentId": 194395,
              "name": "北仑区"
            },
            {
              "cityId": 195023,
              "parentId": 194395,
              "name": "镇海区"
            },
            {
              "cityId": 195132,
              "parentId": 194395,
              "name": "鄞州区"
            },
            {
              "cityId": 195703,
              "parentId": 194395,
              "name": "象山县"
            },
            {
              "cityId": 196257,
              "parentId": 194395,
              "name": "宁海县"
            },
            {
              "cityId": 196686,
              "parentId": 194395,
              "name": "余姚市"
            },
            {
              "cityId": 197031,
              "parentId": 194395,
              "name": "慈溪市"
            },
            {
              "cityId": 197440,
              "parentId": 194395,
              "name": "奉化市"
            }
          ]
        },
        {
          "cityId": 197850,
          "parentId": 191019,
          "name": "温州市",
          "child": [{
              "cityId": 197851,
              "parentId": 197850,
              "name": "市辖区"
            },
            {
              "cityId": 197852,
              "parentId": 197850,
              "name": "鹿城区"
            },
            {
              "cityId": 198091,
              "parentId": 197850,
              "name": "龙湾区"
            },
            {
              "cityId": 198210,
              "parentId": 197850,
              "name": "瓯海区"
            },
            {
              "cityId": 198508,
              "parentId": 197850,
              "name": "洞头区"
            },
            {
              "cityId": 198619,
              "parentId": 197850,
              "name": "永嘉县"
            },
            {
              "cityId": 199581,
              "parentId": 197850,
              "name": "平阳县"
            },
            {
              "cityId": 200239,
              "parentId": 197850,
              "name": "苍南县"
            },
            {
              "cityId": 201140,
              "parentId": 197850,
              "name": "文成县"
            },
            {
              "cityId": 201556,
              "parentId": 197850,
              "name": "泰顺县"
            },
            {
              "cityId": 201882,
              "parentId": 197850,
              "name": "瑞安市"
            },
            {
              "cityId": 202864,
              "parentId": 197850,
              "name": "乐清市"
            }
          ]
        },
        {
          "cityId": 203852,
          "parentId": 191019,
          "name": "嘉兴市",
          "child": [{
              "cityId": 203853,
              "parentId": 203852,
              "name": "市辖区"
            },
            {
              "cityId": 203854,
              "parentId": 203852,
              "name": "南湖区"
            },
            {
              "cityId": 204008,
              "parentId": 203852,
              "name": "秀洲区"
            },
            {
              "cityId": 204177,
              "parentId": 203852,
              "name": "嘉善县"
            },
            {
              "cityId": 204341,
              "parentId": 203852,
              "name": "海盐县"
            },
            {
              "cityId": 204486,
              "parentId": 203852,
              "name": "海宁市"
            },
            {
              "cityId": 204723,
              "parentId": 203852,
              "name": "平湖市"
            },
            {
              "cityId": 204877,
              "parentId": 203852,
              "name": "桐乡市"
            }
          ]
        },
        {
          "cityId": 205101,
          "parentId": 191019,
          "name": "湖州市",
          "child": [{
              "cityId": 205102,
              "parentId": 205101,
              "name": "市辖区"
            },
            {
              "cityId": 205103,
              "parentId": 205101,
              "name": "吴兴区"
            },
            {
              "cityId": 205449,
              "parentId": 205101,
              "name": "南浔区"
            },
            {
              "cityId": 205717,
              "parentId": 205101,
              "name": "德清县"
            },
            {
              "cityId": 205910,
              "parentId": 205101,
              "name": "长兴县"
            },
            {
              "cityId": 206213,
              "parentId": 205101,
              "name": "安吉县"
            }
          ]
        },
        {
          "cityId": 206438,
          "parentId": 191019,
          "name": "绍兴市",
          "child": [{
              "cityId": 206439,
              "parentId": 206438,
              "name": "市辖区"
            },
            {
              "cityId": 206440,
              "parentId": 206438,
              "name": "越城区"
            },
            {
              "cityId": 206875,
              "parentId": 206438,
              "name": "柯桥区"
            },
            {
              "cityId": 207247,
              "parentId": 206438,
              "name": "上虞区"
            },
            {
              "cityId": 207715,
              "parentId": 206438,
              "name": "新昌县"
            },
            {
              "cityId": 208171,
              "parentId": 206438,
              "name": "诸暨市"
            },
            {
              "cityId": 208733,
              "parentId": 206438,
              "name": "嵊州市"
            }
          ]
        },
        {
          "cityId": 209250,
          "parentId": 191019,
          "name": "金华市",
          "child": [{
              "cityId": 209251,
              "parentId": 209250,
              "name": "市辖区"
            },
            {
              "cityId": 209252,
              "parentId": 209250,
              "name": "婺城区"
            },
            {
              "cityId": 209949,
              "parentId": 209250,
              "name": "金东区"
            },
            {
              "cityId": 210474,
              "parentId": 209250,
              "name": "武义县"
            },
            {
              "cityId": 211047,
              "parentId": 209250,
              "name": "浦江县"
            },
            {
              "cityId": 211492,
              "parentId": 209250,
              "name": "磐安县"
            },
            {
              "cityId": 211883,
              "parentId": 209250,
              "name": "兰溪市"
            },
            {
              "cityId": 212256,
              "parentId": 209250,
              "name": "义乌市"
            },
            {
              "cityId": 213076,
              "parentId": 209250,
              "name": "东阳市"
            },
            {
              "cityId": 213485,
              "parentId": 209250,
              "name": "永康市"
            }
          ]
        },
        {
          "cityId": 214260,
          "parentId": 191019,
          "name": "衢州市",
          "child": [{
              "cityId": 214261,
              "parentId": 214260,
              "name": "市辖区"
            },
            {
              "cityId": 214262,
              "parentId": 214260,
              "name": "柯城区"
            },
            {
              "cityId": 214536,
              "parentId": 214260,
              "name": "衢江区"
            },
            {
              "cityId": 214843,
              "parentId": 214260,
              "name": "常山县"
            },
            {
              "cityId": 215051,
              "parentId": 214260,
              "name": "开化县"
            },
            {
              "cityId": 215332,
              "parentId": 214260,
              "name": "龙游县"
            },
            {
              "cityId": 215624,
              "parentId": 214260,
              "name": "江山市"
            }
          ]
        },
        {
          "cityId": 215955,
          "parentId": 191019,
          "name": "舟山市",
          "child": [{
              "cityId": 215956,
              "parentId": 215955,
              "name": "市辖区"
            },
            {
              "cityId": 215957,
              "parentId": 215955,
              "name": "定海区"
            },
            {
              "cityId": 216139,
              "parentId": 215955,
              "name": "普陀区"
            },
            {
              "cityId": 216290,
              "parentId": 215955,
              "name": "岱山县"
            },
            {
              "cityId": 216394,
              "parentId": 215955,
              "name": "嵊泗县"
            }
          ]
        },
        {
          "cityId": 216449,
          "parentId": 191019,
          "name": "台州市",
          "child": [{
              "cityId": 216450,
              "parentId": 216449,
              "name": "市辖区"
            },
            {
              "cityId": 216451,
              "parentId": 216449,
              "name": "椒江区"
            },
            {
              "cityId": 216788,
              "parentId": 216449,
              "name": "黄岩区"
            },
            {
              "cityId": 217324,
              "parentId": 216449,
              "name": "路桥区"
            },
            {
              "cityId": 217666,
              "parentId": 216449,
              "name": "玉环县"
            },
            {
              "cityId": 217998,
              "parentId": 216449,
              "name": "三门县"
            },
            {
              "cityId": 218534,
              "parentId": 216449,
              "name": "天台县"
            },
            {
              "cityId": 219162,
              "parentId": 216449,
              "name": "仙居县"
            },
            {
              "cityId": 219609,
              "parentId": 216449,
              "name": "温岭市"
            },
            {
              "cityId": 220555,
              "parentId": 216449,
              "name": "临海市"
            }
          ]
        },
        {
          "cityId": 221610,
          "parentId": 191019,
          "name": "丽水市",
          "child": [{
              "cityId": 221611,
              "parentId": 221610,
              "name": "市辖区"
            },
            {
              "cityId": 221612,
              "parentId": 221610,
              "name": "莲都区"
            },
            {
              "cityId": 221903,
              "parentId": 221610,
              "name": "青田县"
            },
            {
              "cityId": 222380,
              "parentId": 221610,
              "name": "缙云县"
            },
            {
              "cityId": 222658,
              "parentId": 221610,
              "name": "遂昌县"
            },
            {
              "cityId": 222890,
              "parentId": 221610,
              "name": "松阳县"
            },
            {
              "cityId": 223324,
              "parentId": 221610,
              "name": "云和县"
            },
            {
              "cityId": 223512,
              "parentId": 221610,
              "name": "庆元县"
            },
            {
              "cityId": 223885,
              "parentId": 221610,
              "name": "景宁畲族自治县"
            },
            {
              "cityId": 224172,
              "parentId": 221610,
              "name": "龙泉市"
            }
          ]
        }
      ]
    },
    {
      "cityId": 224649,
      "parentId": 0,
      "name": "安徽省",
      "child": [{
          "cityId": 224650,
          "parentId": 224649,
          "name": "合肥市",
          "child": [{
              "cityId": 224651,
              "parentId": 224650,
              "name": "市辖区"
            },
            {
              "cityId": 224652,
              "parentId": 224650,
              "name": "瑶海区"
            },
            {
              "cityId": 224755,
              "parentId": 224650,
              "name": "庐阳区"
            },
            {
              "cityId": 224838,
              "parentId": 224650,
              "name": "蜀山区"
            },
            {
              "cityId": 224946,
              "parentId": 224650,
              "name": "包河区"
            },
            {
              "cityId": 225062,
              "parentId": 224650,
              "name": "长丰县"
            },
            {
              "cityId": 225352,
              "parentId": 224650,
              "name": "肥东县"
            },
            {
              "cityId": 225705,
              "parentId": 224650,
              "name": "肥西县"
            },
            {
              "cityId": 225998,
              "parentId": 224650,
              "name": "庐江县"
            },
            {
              "cityId": 226249,
              "parentId": 224650,
              "name": "巢湖市"
            }
          ]
        },
        {
          "cityId": 226469,
          "parentId": 224649,
          "name": "芜湖市",
          "child": [{
              "cityId": 226470,
              "parentId": 226469,
              "name": "市辖区"
            },
            {
              "cityId": 226471,
              "parentId": 226469,
              "name": "镜湖区"
            },
            {
              "cityId": 226550,
              "parentId": 226469,
              "name": "弋江区"
            },
            {
              "cityId": 226612,
              "parentId": 226469,
              "name": "鸠江区"
            },
            {
              "cityId": 226735,
              "parentId": 226469,
              "name": "三山区"
            },
            {
              "cityId": 226786,
              "parentId": 226469,
              "name": "芜湖县"
            },
            {
              "cityId": 226891,
              "parentId": 226469,
              "name": "繁昌县"
            },
            {
              "cityId": 226995,
              "parentId": 226469,
              "name": "南陵县"
            },
            {
              "cityId": 227183,
              "parentId": 226469,
              "name": "无为县"
            }
          ]
        },
        {
          "cityId": 227482,
          "parentId": 224649,
          "name": "蚌埠市",
          "child": [{
              "cityId": 227483,
              "parentId": 227482,
              "name": "市辖区"
            },
            {
              "cityId": 227484,
              "parentId": 227482,
              "name": "龙子湖区"
            },
            {
              "cityId": 227551,
              "parentId": 227482,
              "name": "蚌山区"
            },
            {
              "cityId": 227598,
              "parentId": 227482,
              "name": "禹会区"
            },
            {
              "cityId": 227677,
              "parentId": 227482,
              "name": "淮上区"
            },
            {
              "cityId": 227769,
              "parentId": 227482,
              "name": "怀远县"
            },
            {
              "cityId": 228155,
              "parentId": 227482,
              "name": "五河县"
            },
            {
              "cityId": 228388,
              "parentId": 227482,
              "name": "固镇县"
            }
          ]
        },
        {
          "cityId": 228630,
          "parentId": 224649,
          "name": "淮南市",
          "child": [{
              "cityId": 228631,
              "parentId": 228630,
              "name": "市辖区"
            },
            {
              "cityId": 228632,
              "parentId": 228630,
              "name": "大通区"
            },
            {
              "cityId": 228710,
              "parentId": 228630,
              "name": "田家庵区"
            },
            {
              "cityId": 228853,
              "parentId": 228630,
              "name": "谢家集区"
            },
            {
              "cityId": 228960,
              "parentId": 228630,
              "name": "八公山区"
            },
            {
              "cityId": 229029,
              "parentId": 228630,
              "name": "潘集区"
            },
            {
              "cityId": 229221,
              "parentId": 228630,
              "name": "凤台县"
            },
            {
              "cityId": 229527,
              "parentId": 228630,
              "name": "寿县"
            }
          ]
        },
        {
          "cityId": 229844,
          "parentId": 224649,
          "name": "马鞍山市",
          "child": [{
              "cityId": 229845,
              "parentId": 229844,
              "name": "市辖区"
            },
            {
              "cityId": 229846,
              "parentId": 229844,
              "name": "花山区"
            },
            {
              "cityId": 229922,
              "parentId": 229844,
              "name": "雨山区"
            },
            {
              "cityId": 229992,
              "parentId": 229844,
              "name": "博望区"
            },
            {
              "cityId": 230041,
              "parentId": 229844,
              "name": "当涂县"
            },
            {
              "cityId": 230213,
              "parentId": 229844,
              "name": "含山县"
            },
            {
              "cityId": 230345,
              "parentId": 229844,
              "name": "和县"
            }
          ]
        },
        {
          "cityId": 230476,
          "parentId": 224649,
          "name": "淮北市",
          "child": [{
              "cityId": 230477,
              "parentId": 230476,
              "name": "市辖区"
            },
            {
              "cityId": 230478,
              "parentId": 230476,
              "name": "杜集区"
            },
            {
              "cityId": 230539,
              "parentId": 230476,
              "name": "相山区"
            },
            {
              "cityId": 230648,
              "parentId": 230476,
              "name": "烈山区"
            },
            {
              "cityId": 230720,
              "parentId": 230476,
              "name": "濉溪县"
            }
          ]
        },
        {
          "cityId": 230981,
          "parentId": 224649,
          "name": "铜陵市",
          "child": [{
              "cityId": 230982,
              "parentId": 230981,
              "name": "市辖区"
            },
            {
              "cityId": 230983,
              "parentId": 230981,
              "name": "铜官区"
            },
            {
              "cityId": 231043,
              "parentId": 230981,
              "name": "义安区"
            },
            {
              "cityId": 231171,
              "parentId": 230981,
              "name": "郊区"
            },
            {
              "cityId": 231216,
              "parentId": 230981,
              "name": "枞阳县"
            }
          ]
        },
        {
          "cityId": 231499,
          "parentId": 224649,
          "name": "安庆市",
          "child": [{
              "cityId": 231500,
              "parentId": 231499,
              "name": "市辖区"
            },
            {
              "cityId": 231501,
              "parentId": 231499,
              "name": "迎江区"
            },
            {
              "cityId": 231558,
              "parentId": 231499,
              "name": "大观区"
            },
            {
              "cityId": 231646,
              "parentId": 231499,
              "name": "宜秀区"
            },
            {
              "cityId": 231710,
              "parentId": 231499,
              "name": "怀宁县"
            },
            {
              "cityId": 231974,
              "parentId": 231499,
              "name": "潜山县"
            },
            {
              "cityId": 232180,
              "parentId": 231499,
              "name": "太湖县"
            },
            {
              "cityId": 232386,
              "parentId": 231499,
              "name": "宿松县"
            },
            {
              "cityId": 232631,
              "parentId": 231499,
              "name": "望江县"
            },
            {
              "cityId": 232781,
              "parentId": 231499,
              "name": "岳西县"
            },
            {
              "cityId": 232996,
              "parentId": 231499,
              "name": "桐城市"
            }
          ]
        },
        {
          "cityId": 233236,
          "parentId": 224649,
          "name": "黄山市",
          "child": [{
              "cityId": 233237,
              "parentId": 233236,
              "name": "市辖区"
            },
            {
              "cityId": 233238,
              "parentId": 233236,
              "name": "屯溪区"
            },
            {
              "cityId": 233321,
              "parentId": 233236,
              "name": "黄山区"
            },
            {
              "cityId": 233423,
              "parentId": 233236,
              "name": "徽州区"
            },
            {
              "cityId": 233487,
              "parentId": 233236,
              "name": "歙县"
            },
            {
              "cityId": 233714,
              "parentId": 233236,
              "name": "休宁县"
            },
            {
              "cityId": 233894,
              "parentId": 233236,
              "name": "黟县"
            },
            {
              "cityId": 233973,
              "parentId": 233236,
              "name": "祁门县"
            }
          ]
        },
        {
          "cityId": 234109,
          "parentId": 224649,
          "name": "滁州市",
          "child": [{
              "cityId": 234110,
              "parentId": 234109,
              "name": "市辖区"
            },
            {
              "cityId": 234111,
              "parentId": 234109,
              "name": "琅琊区"
            },
            {
              "cityId": 234174,
              "parentId": 234109,
              "name": "南谯区"
            },
            {
              "cityId": 234274,
              "parentId": 234109,
              "name": "来安县"
            },
            {
              "cityId": 234434,
              "parentId": 234109,
              "name": "全椒县"
            },
            {
              "cityId": 234560,
              "parentId": 234109,
              "name": "定远县"
            },
            {
              "cityId": 234847,
              "parentId": 234109,
              "name": "凤阳县"
            },
            {
              "cityId": 235112,
              "parentId": 234109,
              "name": "天长市"
            },
            {
              "cityId": 235305,
              "parentId": 234109,
              "name": "明光市"
            }
          ]
        },
        {
          "cityId": 235483,
          "parentId": 224649,
          "name": "阜阳市",
          "child": [{
              "cityId": 235484,
              "parentId": 235483,
              "name": "市辖区"
            },
            {
              "cityId": 235485,
              "parentId": 235483,
              "name": "颍州区"
            },
            {
              "cityId": 235646,
              "parentId": 235483,
              "name": "颍东区"
            },
            {
              "cityId": 235778,
              "parentId": 235483,
              "name": "颍泉区"
            },
            {
              "cityId": 235909,
              "parentId": 235483,
              "name": "临泉县"
            },
            {
              "cityId": 236338,
              "parentId": 235483,
              "name": "太和县"
            },
            {
              "cityId": 236685,
              "parentId": 235483,
              "name": "阜南县"
            },
            {
              "cityId": 237046,
              "parentId": 235483,
              "name": "颍上县"
            },
            {
              "cityId": 237428,
              "parentId": 235483,
              "name": "界首市"
            }
          ]
        },
        {
          "cityId": 237607,
          "parentId": 224649,
          "name": "宿州市",
          "child": [{
              "cityId": 237608,
              "parentId": 237607,
              "name": "市辖区"
            },
            {
              "cityId": 237609,
              "parentId": 237607,
              "name": "埇桥区"
            },
            {
              "cityId": 238022,
              "parentId": 237607,
              "name": "砀山县"
            },
            {
              "cityId": 238207,
              "parentId": 237607,
              "name": "萧县"
            },
            {
              "cityId": 238533,
              "parentId": 237607,
              "name": "灵璧县"
            },
            {
              "cityId": 238872,
              "parentId": 237607,
              "name": "泗县"
            }
          ]
        },
        {
          "cityId": 239084,
          "parentId": 224649,
          "name": "六安市",
          "child": [{
              "cityId": 239085,
              "parentId": 239084,
              "name": "市辖区"
            },
            {
              "cityId": 239086,
              "parentId": 239084,
              "name": "金安区"
            },
            {
              "cityId": 239450,
              "parentId": 239084,
              "name": "裕安区"
            },
            {
              "cityId": 239780,
              "parentId": 239084,
              "name": "叶集区"
            },
            {
              "cityId": 239834,
              "parentId": 239084,
              "name": "霍邱县"
            },
            {
              "cityId": 240310,
              "parentId": 239084,
              "name": "舒城县"
            },
            {
              "cityId": 240759,
              "parentId": 239084,
              "name": "金寨县"
            },
            {
              "cityId": 241016,
              "parentId": 239084,
              "name": "霍山县"
            }
          ]
        },
        {
          "cityId": 241180,
          "parentId": 224649,
          "name": "亳州市",
          "child": [{
              "cityId": 241181,
              "parentId": 241180,
              "name": "市辖区"
            },
            {
              "cityId": 241182,
              "parentId": 241180,
              "name": "谯城区"
            },
            {
              "cityId": 241517,
              "parentId": 241180,
              "name": "涡阳县"
            },
            {
              "cityId": 241927,
              "parentId": 241180,
              "name": "蒙城县"
            },
            {
              "cityId": 242245,
              "parentId": 241180,
              "name": "利辛县"
            }
          ]
        },
        {
          "cityId": 242634,
          "parentId": 224649,
          "name": "池州市",
          "child": [{
              "cityId": 242635,
              "parentId": 242634,
              "name": "市辖区"
            },
            {
              "cityId": 242636,
              "parentId": 242634,
              "name": "贵池区"
            },
            {
              "cityId": 242888,
              "parentId": 242634,
              "name": "东至县"
            },
            {
              "cityId": 243159,
              "parentId": 242634,
              "name": "石台县"
            },
            {
              "cityId": 243254,
              "parentId": 242634,
              "name": "青阳县"
            }
          ]
        },
        {
          "cityId": 243399,
          "parentId": 224649,
          "name": "宣城市",
          "child": [{
              "cityId": 243400,
              "parentId": 243399,
              "name": "市辖区"
            },
            {
              "cityId": 243401,
              "parentId": 243399,
              "name": "宣州区"
            },
            {
              "cityId": 243636,
              "parentId": 243399,
              "name": "郎溪县"
            },
            {
              "cityId": 243753,
              "parentId": 243399,
              "name": "广德县"
            },
            {
              "cityId": 243902,
              "parentId": 243399,
              "name": "泾县"
            },
            {
              "cityId": 244058,
              "parentId": 243399,
              "name": "绩溪县"
            },
            {
              "cityId": 244153,
              "parentId": 243399,
              "name": "旌德县"
            },
            {
              "cityId": 244232,
              "parentId": 243399,
              "name": "宁国市"
            }
          ]
        }
      ]
    },
    {
      "cityId": 244377,
      "parentId": 0,
      "name": "福建省",
      "child": [{
          "cityId": 244378,
          "parentId": 244377,
          "name": "福州市",
          "child": [{
              "cityId": 244379,
              "parentId": 244378,
              "name": "市辖区"
            },
            {
              "cityId": 244380,
              "parentId": 244378,
              "name": "鼓楼区"
            },
            {
              "cityId": 244460,
              "parentId": 244378,
              "name": "台江区"
            },
            {
              "cityId": 244523,
              "parentId": 244378,
              "name": "仓山区"
            },
            {
              "cityId": 244713,
              "parentId": 244378,
              "name": "马尾区"
            },
            {
              "cityId": 244793,
              "parentId": 244378,
              "name": "晋安区"
            },
            {
              "cityId": 244987,
              "parentId": 244378,
              "name": "闽侯县"
            },
            {
              "cityId": 245329,
              "parentId": 244378,
              "name": "连江县"
            },
            {
              "cityId": 245633,
              "parentId": 244378,
              "name": "罗源县"
            },
            {
              "cityId": 245845,
              "parentId": 244378,
              "name": "闽清县"
            },
            {
              "cityId": 246154,
              "parentId": 244378,
              "name": "永泰县"
            },
            {
              "cityId": 246443,
              "parentId": 244378,
              "name": "平潭县"
            },
            {
              "cityId": 246667,
              "parentId": 244378,
              "name": "福清市"
            },
            {
              "cityId": 247200,
              "parentId": 244378,
              "name": "长乐市"
            }
          ]
        },
        {
          "cityId": 247478,
          "parentId": 244377,
          "name": "厦门市",
          "child": [{
              "cityId": 247479,
              "parentId": 247478,
              "name": "市辖区"
            },
            {
              "cityId": 247480,
              "parentId": 247478,
              "name": "思明区"
            },
            {
              "cityId": 247590,
              "parentId": 247478,
              "name": "海沧区"
            },
            {
              "cityId": 247641,
              "parentId": 247478,
              "name": "湖里区"
            },
            {
              "cityId": 247704,
              "parentId": 247478,
              "name": "集美区"
            },
            {
              "cityId": 247782,
              "parentId": 247478,
              "name": "同安区"
            },
            {
              "cityId": 247933,
              "parentId": 247478,
              "name": "翔安区"
            }
          ]
        },
        {
          "cityId": 248059,
          "parentId": 244377,
          "name": "莆田市",
          "child": [{
              "cityId": 248060,
              "parentId": 248059,
              "name": "市辖区"
            },
            {
              "cityId": 248061,
              "parentId": 248059,
              "name": "城厢区"
            },
            {
              "cityId": 248189,
              "parentId": 248059,
              "name": "涵江区"
            },
            {
              "cityId": 248403,
              "parentId": 248059,
              "name": "荔城区"
            },
            {
              "cityId": 248540,
              "parentId": 248059,
              "name": "秀屿区"
            },
            {
              "cityId": 248753,
              "parentId": 248059,
              "name": "仙游县"
            }
          ]
        },
        {
          "cityId": 249098,
          "parentId": 244377,
          "name": "三明市",
          "child": [{
              "cityId": 249099,
              "parentId": 249098,
              "name": "市辖区"
            },
            {
              "cityId": 249100,
              "parentId": 249098,
              "name": "梅列区"
            },
            {
              "cityId": 249157,
              "parentId": 249098,
              "name": "三元区"
            },
            {
              "cityId": 249245,
              "parentId": 249098,
              "name": "明溪县"
            },
            {
              "cityId": 249351,
              "parentId": 249098,
              "name": "清流县"
            },
            {
              "cityId": 249484,
              "parentId": 249098,
              "name": "宁化县"
            },
            {
              "cityId": 249728,
              "parentId": 249098,
              "name": "大田县"
            },
            {
              "cityId": 250026,
              "parentId": 249098,
              "name": "尤溪县"
            },
            {
              "cityId": 250307,
              "parentId": 249098,
              "name": "沙县"
            },
            {
              "cityId": 250512,
              "parentId": 249098,
              "name": "将乐县"
            },
            {
              "cityId": 250680,
              "parentId": 249098,
              "name": "泰宁县"
            },
            {
              "cityId": 250809,
              "parentId": 249098,
              "name": "建宁县"
            },
            {
              "cityId": 250920,
              "parentId": 249098,
              "name": "永安市"
            }
          ]
        },
        {
          "cityId": 251197,
          "parentId": 244377,
          "name": "泉州市",
          "child": [{
              "cityId": 251198,
              "parentId": 251197,
              "name": "市辖区"
            },
            {
              "cityId": 251199,
              "parentId": 251197,
              "name": "鲤城区"
            },
            {
              "cityId": 251289,
              "parentId": 251197,
              "name": "丰泽区"
            },
            {
              "cityId": 251376,
              "parentId": 251197,
              "name": "洛江区"
            },
            {
              "cityId": 251470,
              "parentId": 251197,
              "name": "泉港区"
            },
            {
              "cityId": 251580,
              "parentId": 251197,
              "name": "惠安县"
            },
            {
              "cityId": 251897,
              "parentId": 251197,
              "name": "安溪县"
            },
            {
              "cityId": 252406,
              "parentId": 251197,
              "name": "永春县"
            },
            {
              "cityId": 252665,
              "parentId": 251197,
              "name": "德化县"
            },
            {
              "cityId": 252894,
              "parentId": 251197,
              "name": "金门县"
            },
            {
              "cityId": 252895,
              "parentId": 251197,
              "name": "石狮市"
            },
            {
              "cityId": 253032,
              "parentId": 251197,
              "name": "晋江市"
            },
            {
              "cityId": 253454,
              "parentId": 251197,
              "name": "南安市"
            }
          ]
        },
        {
          "cityId": 253905,
          "parentId": 244377,
          "name": "漳州市",
          "child": [{
              "cityId": 253906,
              "parentId": 253905,
              "name": "市辖区"
            },
            {
              "cityId": 253907,
              "parentId": 253905,
              "name": "芗城区"
            },
            {
              "cityId": 254093,
              "parentId": 253905,
              "name": "龙文区"
            },
            {
              "cityId": 254170,
              "parentId": 253905,
              "name": "云霄县"
            },
            {
              "cityId": 254394,
              "parentId": 253905,
              "name": "漳浦县"
            },
            {
              "cityId": 254747,
              "parentId": 253905,
              "name": "诏安县"
            },
            {
              "cityId": 255011,
              "parentId": 253905,
              "name": "长泰县"
            },
            {
              "cityId": 255105,
              "parentId": 253905,
              "name": "东山县"
            },
            {
              "cityId": 255190,
              "parentId": 253905,
              "name": "南靖县"
            },
            {
              "cityId": 255405,
              "parentId": 253905,
              "name": "平和县"
            },
            {
              "cityId": 255680,
              "parentId": 253905,
              "name": "华安县"
            },
            {
              "cityId": 255789,
              "parentId": 253905,
              "name": "龙海市"
            }
          ]
        },
        {
          "cityId": 256112,
          "parentId": 244377,
          "name": "南平市",
          "child": [{
              "cityId": 256113,
              "parentId": 256112,
              "name": "市辖区"
            },
            {
              "cityId": 256114,
              "parentId": 256112,
              "name": "延平区"
            },
            {
              "cityId": 256452,
              "parentId": 256112,
              "name": "建阳区"
            },
            {
              "cityId": 256687,
              "parentId": 256112,
              "name": "顺昌县"
            },
            {
              "cityId": 256849,
              "parentId": 256112,
              "name": "浦城县"
            },
            {
              "cityId": 257172,
              "parentId": 256112,
              "name": "光泽县"
            },
            {
              "cityId": 257276,
              "parentId": 256112,
              "name": "松溪县"
            },
            {
              "cityId": 257395,
              "parentId": 256112,
              "name": "政和县"
            },
            {
              "cityId": 257539,
              "parentId": 256112,
              "name": "邵武市"
            },
            {
              "cityId": 257733,
              "parentId": 256112,
              "name": "武夷山市"
            },
            {
              "cityId": 257886,
              "parentId": 256112,
              "name": "建瓯市"
            }
          ]
        },
        {
          "cityId": 258160,
          "parentId": 244377,
          "name": "龙岩市",
          "child": [{
              "cityId": 258161,
              "parentId": 258160,
              "name": "市辖区"
            },
            {
              "cityId": 258162,
              "parentId": 258160,
              "name": "新罗区"
            },
            {
              "cityId": 258520,
              "parentId": 258160,
              "name": "永定区"
            },
            {
              "cityId": 258825,
              "parentId": 258160,
              "name": "长汀县"
            },
            {
              "cityId": 259144,
              "parentId": 258160,
              "name": "上杭县"
            },
            {
              "cityId": 259512,
              "parentId": 258160,
              "name": "武平县"
            },
            {
              "cityId": 259749,
              "parentId": 258160,
              "name": "连城县"
            },
            {
              "cityId": 260014,
              "parentId": 258160,
              "name": "漳平市"
            }
          ]
        },
        {
          "cityId": 260230,
          "parentId": 244377,
          "name": "宁德市",
          "child": [{
              "cityId": 260231,
              "parentId": 260230,
              "name": "市辖区"
            },
            {
              "cityId": 260232,
              "parentId": 260230,
              "name": "蕉城区"
            },
            {
              "cityId": 260575,
              "parentId": 260230,
              "name": "霞浦县"
            },
            {
              "cityId": 260905,
              "parentId": 260230,
              "name": "古田县"
            },
            {
              "cityId": 261211,
              "parentId": 260230,
              "name": "屏南县"
            },
            {
              "cityId": 261382,
              "parentId": 260230,
              "name": "寿宁县"
            },
            {
              "cityId": 261602,
              "parentId": 260230,
              "name": "周宁县"
            },
            {
              "cityId": 261759,
              "parentId": 260230,
              "name": "柘荣县"
            },
            {
              "cityId": 261885,
              "parentId": 260230,
              "name": "福安市"
            },
            {
              "cityId": 262395,
              "parentId": 260230,
              "name": "福鼎市"
            }
          ]
        }
      ]
    },
    {
      "cityId": 262698,
      "parentId": 0,
      "name": "江西省",
      "child": [{
          "cityId": 262699,
          "parentId": 262698,
          "name": "南昌市",
          "child": [{
              "cityId": 262700,
              "parentId": 262699,
              "name": "市辖区"
            },
            {
              "cityId": 262701,
              "parentId": 262699,
              "name": "东湖区"
            },
            {
              "cityId": 262889,
              "parentId": 262699,
              "name": "西湖区"
            },
            {
              "cityId": 263058,
              "parentId": 262699,
              "name": "青云谱区"
            },
            {
              "cityId": 263152,
              "parentId": 262699,
              "name": "湾里区"
            },
            {
              "cityId": 263217,
              "parentId": 262699,
              "name": "青山湖区"
            },
            {
              "cityId": 263496,
              "parentId": 262699,
              "name": "新建区"
            },
            {
              "cityId": 263948,
              "parentId": 262699,
              "name": "南昌县"
            },
            {
              "cityId": 264375,
              "parentId": 262699,
              "name": "安义县"
            },
            {
              "cityId": 264517,
              "parentId": 262699,
              "name": "进贤县"
            }
          ]
        },
        {
          "cityId": 264862,
          "parentId": 262698,
          "name": "景德镇市",
          "child": [{
              "cityId": 264863,
              "parentId": 264862,
              "name": "市辖区"
            },
            {
              "cityId": 264864,
              "parentId": 264862,
              "name": "昌江区"
            },
            {
              "cityId": 264962,
              "parentId": 264862,
              "name": "珠山区"
            },
            {
              "cityId": 265083,
              "parentId": 264862,
              "name": "浮梁县"
            },
            {
              "cityId": 265297,
              "parentId": 264862,
              "name": "乐平市"
            }
          ]
        },
        {
          "cityId": 265677,
          "parentId": 262698,
          "name": "萍乡市",
          "child": [{
              "cityId": 265678,
              "parentId": 265677,
              "name": "市辖区"
            },
            {
              "cityId": 265679,
              "parentId": 265677,
              "name": "安源区"
            },
            {
              "cityId": 265826,
              "parentId": 265677,
              "name": "湘东区"
            },
            {
              "cityId": 265994,
              "parentId": 265677,
              "name": "莲花县"
            },
            {
              "cityId": 266171,
              "parentId": 265677,
              "name": "上栗县"
            },
            {
              "cityId": 266356,
              "parentId": 265677,
              "name": "芦溪县"
            }
          ]
        },
        {
          "cityId": 266521,
          "parentId": 262698,
          "name": "九江市",
          "child": [{
              "cityId": 266522,
              "parentId": 266521,
              "name": "市辖区"
            },
            {
              "cityId": 266523,
              "parentId": 266521,
              "name": "濂溪区"
            },
            {
              "cityId": 266665,
              "parentId": 266521,
              "name": "浔阳区"
            },
            {
              "cityId": 266781,
              "parentId": 266521,
              "name": "九江县"
            },
            {
              "cityId": 266946,
              "parentId": 266521,
              "name": "武宁县"
            },
            {
              "cityId": 267168,
              "parentId": 266521,
              "name": "修水县"
            },
            {
              "cityId": 267613,
              "parentId": 266521,
              "name": "永修县"
            },
            {
              "cityId": 267868,
              "parentId": 266521,
              "name": "德安县"
            },
            {
              "cityId": 267982,
              "parentId": 266521,
              "name": "都昌县"
            },
            {
              "cityId": 268318,
              "parentId": 266521,
              "name": "湖口县"
            },
            {
              "cityId": 268480,
              "parentId": 266521,
              "name": "彭泽县"
            },
            {
              "cityId": 268689,
              "parentId": 266521,
              "name": "瑞昌市"
            },
            {
              "cityId": 268926,
              "parentId": 266521,
              "name": "共青城市"
            },
            {
              "cityId": 268995,
              "parentId": 266521,
              "name": "庐山市"
            }
          ]
        },
        {
          "cityId": 269107,
          "parentId": 262698,
          "name": "新余市",
          "child": [{
              "cityId": 269108,
              "parentId": 269107,
              "name": "市辖区"
            },
            {
              "cityId": 269109,
              "parentId": 269107,
              "name": "渝水区"
            },
            {
              "cityId": 269465,
              "parentId": 269107,
              "name": "分宜县"
            }
          ]
        },
        {
          "cityId": 269654,
          "parentId": 262698,
          "name": "鹰潭市",
          "child": [{
              "cityId": 269655,
              "parentId": 269654,
              "name": "市辖区"
            },
            {
              "cityId": 269656,
              "parentId": 269654,
              "name": "月湖区"
            },
            {
              "cityId": 269746,
              "parentId": 269654,
              "name": "余江县"
            },
            {
              "cityId": 269942,
              "parentId": 269654,
              "name": "贵溪市"
            }
          ]
        },
        {
          "cityId": 270222,
          "parentId": 262698,
          "name": "赣州市",
          "child": [{
              "cityId": 270223,
              "parentId": 270222,
              "name": "市辖区"
            },
            {
              "cityId": 270224,
              "parentId": 270222,
              "name": "章贡区"
            },
            {
              "cityId": 270442,
              "parentId": 270222,
              "name": "南康区"
            },
            {
              "cityId": 270780,
              "parentId": 270222,
              "name": "赣县"
            },
            {
              "cityId": 271095,
              "parentId": 270222,
              "name": "信丰县"
            },
            {
              "cityId": 271410,
              "parentId": 270222,
              "name": "大余县"
            },
            {
              "cityId": 271541,
              "parentId": 270222,
              "name": "上犹县"
            },
            {
              "cityId": 271699,
              "parentId": 270222,
              "name": "崇义县"
            },
            {
              "cityId": 271848,
              "parentId": 270222,
              "name": "安远县"
            },
            {
              "cityId": 272033,
              "parentId": 270222,
              "name": "龙南县"
            },
            {
              "cityId": 272156,
              "parentId": 270222,
              "name": "定南县"
            },
            {
              "cityId": 272302,
              "parentId": 270222,
              "name": "全南县"
            },
            {
              "cityId": 272408,
              "parentId": 270222,
              "name": "宁都县"
            },
            {
              "cityId": 272767,
              "parentId": 270222,
              "name": "于都县"
            },
            {
              "cityId": 273183,
              "parentId": 270222,
              "name": "兴国县"
            },
            {
              "cityId": 273525,
              "parentId": 270222,
              "name": "会昌县"
            },
            {
              "cityId": 273820,
              "parentId": 270222,
              "name": "寻乌县"
            },
            {
              "cityId": 274020,
              "parentId": 270222,
              "name": "石城县"
            },
            {
              "cityId": 274184,
              "parentId": 270222,
              "name": "瑞金市"
            }
          ]
        },
        {
          "cityId": 274444,
          "parentId": 262698,
          "name": "吉安市",
          "child": [{
              "cityId": 274445,
              "parentId": 274444,
              "name": "市辖区"
            },
            {
              "cityId": 274446,
              "parentId": 274444,
              "name": "吉州区"
            },
            {
              "cityId": 274592,
              "parentId": 274444,
              "name": "青原区"
            },
            {
              "cityId": 274736,
              "parentId": 274444,
              "name": "吉安县"
            },
            {
              "cityId": 275105,
              "parentId": 274444,
              "name": "吉水县"
            },
            {
              "cityId": 275406,
              "parentId": 274444,
              "name": "峡江县"
            },
            {
              "cityId": 275511,
              "parentId": 274444,
              "name": "新干县"
            },
            {
              "cityId": 275694,
              "parentId": 274444,
              "name": "永丰县"
            },
            {
              "cityId": 275956,
              "parentId": 274444,
              "name": "泰和县"
            },
            {
              "cityId": 276331,
              "parentId": 274444,
              "name": "遂川县"
            },
            {
              "cityId": 276700,
              "parentId": 274444,
              "name": "万安县"
            },
            {
              "cityId": 276874,
              "parentId": 274444,
              "name": "安福县"
            },
            {
              "cityId": 277170,
              "parentId": 274444,
              "name": "永新县"
            },
            {
              "cityId": 277459,
              "parentId": 274444,
              "name": "井冈山市"
            }
          ]
        },
        {
          "cityId": 277615,
          "parentId": 262698,
          "name": "宜春市",
          "child": [{
              "cityId": 277616,
              "parentId": 277615,
              "name": "市辖区"
            },
            {
              "cityId": 277617,
              "parentId": 277615,
              "name": "袁州区"
            },
            {
              "cityId": 278033,
              "parentId": 277615,
              "name": "奉新县"
            },
            {
              "cityId": 278249,
              "parentId": 277615,
              "name": "万载县"
            },
            {
              "cityId": 278471,
              "parentId": 277615,
              "name": "上高县"
            },
            {
              "cityId": 278710,
              "parentId": 277615,
              "name": "宜丰县"
            },
            {
              "cityId": 278975,
              "parentId": 277615,
              "name": "靖安县"
            },
            {
              "cityId": 279095,
              "parentId": 277615,
              "name": "铜鼓县"
            },
            {
              "cityId": 279220,
              "parentId": 277615,
              "name": "丰城市"
            },
            {
              "cityId": 279845,
              "parentId": 277615,
              "name": "樟树市"
            },
            {
              "cityId": 280166,
              "parentId": 277615,
              "name": "高安市"
            }
          ]
        },
        {
          "cityId": 280562,
          "parentId": 262698,
          "name": "抚州市",
          "child": [{
              "cityId": 280563,
              "parentId": 280562,
              "name": "市辖区"
            },
            {
              "cityId": 280564,
              "parentId": 280562,
              "name": "临川区"
            },
            {
              "cityId": 281098,
              "parentId": 280562,
              "name": "南城县"
            },
            {
              "cityId": 281285,
              "parentId": 280562,
              "name": "黎川县"
            },
            {
              "cityId": 281443,
              "parentId": 280562,
              "name": "南丰县"
            },
            {
              "cityId": 281641,
              "parentId": 280562,
              "name": "崇仁县"
            },
            {
              "cityId": 281827,
              "parentId": 280562,
              "name": "乐安县"
            },
            {
              "cityId": 282036,
              "parentId": 280562,
              "name": "宜黄县"
            },
            {
              "cityId": 282206,
              "parentId": 280562,
              "name": "金溪县"
            },
            {
              "cityId": 282391,
              "parentId": 280562,
              "name": "资溪县"
            },
            {
              "cityId": 282482,
              "parentId": 280562,
              "name": "东乡县"
            },
            {
              "cityId": 282684,
              "parentId": 280562,
              "name": "广昌县"
            }
          ]
        },
        {
          "cityId": 282841,
          "parentId": 262698,
          "name": "上饶市",
          "child": [{
              "cityId": 282842,
              "parentId": 282841,
              "name": "市辖区"
            },
            {
              "cityId": 282843,
              "parentId": 282841,
              "name": "信州区"
            },
            {
              "cityId": 282978,
              "parentId": 282841,
              "name": "广丰区"
            },
            {
              "cityId": 283240,
              "parentId": 282841,
              "name": "上饶县"
            },
            {
              "cityId": 283511,
              "parentId": 282841,
              "name": "玉山县"
            },
            {
              "cityId": 283769,
              "parentId": 282841,
              "name": "铅山县"
            },
            {
              "cityId": 283970,
              "parentId": 282841,
              "name": "横峰县"
            },
            {
              "cityId": 284066,
              "parentId": 282841,
              "name": "弋阳县"
            },
            {
              "cityId": 284239,
              "parentId": 282841,
              "name": "余干县"
            },
            {
              "cityId": 284706,
              "parentId": 282841,
              "name": "鄱阳县"
            },
            {
              "cityId": 285319,
              "parentId": 282841,
              "name": "万年县"
            },
            {
              "cityId": 285488,
              "parentId": 282841,
              "name": "婺源县"
            },
            {
              "cityId": 285711,
              "parentId": 282841,
              "name": "德兴市"
            }
          ]
        }
      ]
    },
    {
      "cityId": 285860,
      "parentId": 0,
      "name": "山东省",
      "child": [{
          "cityId": 285861,
          "parentId": 285860,
          "name": "济南市",
          "child": [{
              "cityId": 285862,
              "parentId": 285861,
              "name": "市辖区"
            },
            {
              "cityId": 285863,
              "parentId": 285861,
              "name": "历下区"
            },
            {
              "cityId": 285991,
              "parentId": 285861,
              "name": "市中区"
            },
            {
              "cityId": 286215,
              "parentId": 285861,
              "name": "槐荫区"
            },
            {
              "cityId": 286404,
              "parentId": 285861,
              "name": "天桥区"
            },
            {
              "cityId": 286683,
              "parentId": 285861,
              "name": "历城区"
            },
            {
              "cityId": 287424,
              "parentId": 285861,
              "name": "长清区"
            },
            {
              "cityId": 288084,
              "parentId": 285861,
              "name": "平阴县"
            },
            {
              "cityId": 288448,
              "parentId": 285861,
              "name": "济阳县"
            },
            {
              "cityId": 289313,
              "parentId": 285861,
              "name": "商河县"
            },
            {
              "cityId": 290291,
              "parentId": 285861,
              "name": "章丘市"
            }
          ]
        },
        {
          "cityId": 291235,
          "parentId": 285860,
          "name": "青岛市",
          "child": [{
              "cityId": 291236,
              "parentId": 291235,
              "name": "市辖区"
            },
            {
              "cityId": 291237,
              "parentId": 291235,
              "name": "市南区"
            },
            {
              "cityId": 291313,
              "parentId": 291235,
              "name": "市北区"
            },
            {
              "cityId": 291468,
              "parentId": 291235,
              "name": "黄岛区"
            },
            {
              "cityId": 292713,
              "parentId": 291235,
              "name": "崂山区"
            },
            {
              "cityId": 292879,
              "parentId": 291235,
              "name": "李沧区"
            },
            {
              "cityId": 293008,
              "parentId": 291235,
              "name": "城阳区"
            },
            {
              "cityId": 293241,
              "parentId": 291235,
              "name": "胶州市"
            },
            {
              "cityId": 294138,
              "parentId": 291235,
              "name": "即墨市"
            },
            {
              "cityId": 295251,
              "parentId": 291235,
              "name": "平度市"
            },
            {
              "cityId": 297121,
              "parentId": 291235,
              "name": "莱西市"
            }
          ]
        },
        {
          "cityId": 298025,
          "parentId": 285860,
          "name": "淄博市",
          "child": [{
              "cityId": 298026,
              "parentId": 298025,
              "name": "市辖区"
            },
            {
              "cityId": 298027,
              "parentId": 298025,
              "name": "淄川区"
            },
            {
              "cityId": 298507,
              "parentId": 298025,
              "name": "张店区"
            },
            {
              "cityId": 298821,
              "parentId": 298025,
              "name": "博山区"
            },
            {
              "cityId": 299159,
              "parentId": 298025,
              "name": "临淄区"
            },
            {
              "cityId": 299652,
              "parentId": 298025,
              "name": "周村区"
            },
            {
              "cityId": 299919,
              "parentId": 298025,
              "name": "桓台县"
            },
            {
              "cityId": 300276,
              "parentId": 298025,
              "name": "高青县"
            },
            {
              "cityId": 301056,
              "parentId": 298025,
              "name": "沂源县"
            }
          ]
        },
        {
          "cityId": 301711,
          "parentId": 285860,
          "name": "枣庄市",
          "child": [{
              "cityId": 301712,
              "parentId": 301711,
              "name": "市辖区"
            },
            {
              "cityId": 301713,
              "parentId": 301711,
              "name": "市中区"
            },
            {
              "cityId": 301888,
              "parentId": 301711,
              "name": "薛城区"
            },
            {
              "cityId": 302179,
              "parentId": 301711,
              "name": "峄城区"
            },
            {
              "cityId": 302532,
              "parentId": 301711,
              "name": "台儿庄区"
            },
            {
              "cityId": 302750,
              "parentId": 301711,
              "name": "山亭区"
            },
            {
              "cityId": 303037,
              "parentId": 301711,
              "name": "滕州市"
            }
          ]
        },
        {
          "cityId": 304309,
          "parentId": 285860,
          "name": "东营市",
          "child": [{
              "cityId": 304310,
              "parentId": 304309,
              "name": "市辖区"
            },
            {
              "cityId": 304311,
              "parentId": 304309,
              "name": "东营区"
            },
            {
              "cityId": 304647,
              "parentId": 304309,
              "name": "河口区"
            },
            {
              "cityId": 304845,
              "parentId": 304309,
              "name": "垦利区"
            },
            {
              "cityId": 305208,
              "parentId": 304309,
              "name": "利津县"
            },
            {
              "cityId": 305747,
              "parentId": 304309,
              "name": "广饶县"
            }
          ]
        },
        {
          "cityId": 306339,
          "parentId": 285860,
          "name": "烟台市",
          "child": [{
              "cityId": 306340,
              "parentId": 306339,
              "name": "市辖区"
            },
            {
              "cityId": 306341,
              "parentId": 306339,
              "name": "芝罘区"
            },
            {
              "cityId": 306496,
              "parentId": 306339,
              "name": "福山区"
            },
            {
              "cityId": 306743,
              "parentId": 306339,
              "name": "牟平区"
            },
            {
              "cityId": 307348,
              "parentId": 306339,
              "name": "莱山区"
            },
            {
              "cityId": 307481,
              "parentId": 306339,
              "name": "长岛县"
            },
            {
              "cityId": 307530,
              "parentId": 306339,
              "name": "龙口市"
            },
            {
              "cityId": 308140,
              "parentId": 306339,
              "name": "莱阳市"
            },
            {
              "cityId": 308953,
              "parentId": 306339,
              "name": "莱州市"
            },
            {
              "cityId": 309985,
              "parentId": 306339,
              "name": "蓬莱市"
            },
            {
              "cityId": 310581,
              "parentId": 306339,
              "name": "招远市"
            },
            {
              "cityId": 311342,
              "parentId": 306339,
              "name": "栖霞市"
            },
            {
              "cityId": 312312,
              "parentId": 306339,
              "name": "海阳市"
            }
          ]
        },
        {
          "cityId": 313063,
          "parentId": 285860,
          "name": "潍坊市",
          "child": [{
              "cityId": 313064,
              "parentId": 313063,
              "name": "市辖区"
            },
            {
              "cityId": 313065,
              "parentId": 313063,
              "name": "潍城区"
            },
            {
              "cityId": 313327,
              "parentId": 313063,
              "name": "寒亭区"
            },
            {
              "cityId": 313701,
              "parentId": 313063,
              "name": "坊子区"
            },
            {
              "cityId": 314288,
              "parentId": 313063,
              "name": "奎文区"
            },
            {
              "cityId": 314533,
              "parentId": 313063,
              "name": "临朐县"
            },
            {
              "cityId": 314894,
              "parentId": 313063,
              "name": "昌乐县"
            },
            {
              "cityId": 315273,
              "parentId": 313063,
              "name": "青州市"
            },
            {
              "cityId": 316361,
              "parentId": 313063,
              "name": "诸城市"
            },
            {
              "cityId": 316642,
              "parentId": 313063,
              "name": "寿光市"
            },
            {
              "cityId": 317633,
              "parentId": 313063,
              "name": "安丘市"
            },
            {
              "cityId": 318512,
              "parentId": 313063,
              "name": "高密市"
            },
            {
              "cityId": 319478,
              "parentId": 313063,
              "name": "昌邑市"
            }
          ]
        },
        {
          "cityId": 320179,
          "parentId": 285860,
          "name": "济宁市",
          "child": [{
              "cityId": 320180,
              "parentId": 320179,
              "name": "市辖区"
            },
            {
              "cityId": 320181,
              "parentId": 320179,
              "name": "任城区"
            },
            {
              "cityId": 320795,
              "parentId": 320179,
              "name": "兖州区"
            },
            {
              "cityId": 321246,
              "parentId": 320179,
              "name": "微山县"
            },
            {
              "cityId": 321804,
              "parentId": 320179,
              "name": "鱼台县"
            },
            {
              "cityId": 322209,
              "parentId": 320179,
              "name": "金乡县"
            },
            {
              "cityId": 322882,
              "parentId": 320179,
              "name": "嘉祥县"
            },
            {
              "cityId": 323607,
              "parentId": 320179,
              "name": "汶上县"
            },
            {
              "cityId": 324118,
              "parentId": 320179,
              "name": "泗水县"
            },
            {
              "cityId": 324729,
              "parentId": 320179,
              "name": "梁山县"
            },
            {
              "cityId": 325372,
              "parentId": 320179,
              "name": "曲阜市"
            },
            {
              "cityId": 325791,
              "parentId": 320179,
              "name": "邹城市"
            }
          ]
        },
        {
          "cityId": 326706,
          "parentId": 285860,
          "name": "泰安市",
          "child": [{
              "cityId": 326707,
              "parentId": 326706,
              "name": "市辖区"
            },
            {
              "cityId": 326708,
              "parentId": 326706,
              "name": "泰山区"
            },
            {
              "cityId": 326926,
              "parentId": 326706,
              "name": "岱岳区"
            },
            {
              "cityId": 327655,
              "parentId": 326706,
              "name": "宁阳县"
            },
            {
              "cityId": 328231,
              "parentId": 326706,
              "name": "东平县"
            },
            {
              "cityId": 328962,
              "parentId": 326706,
              "name": "新泰市"
            },
            {
              "cityId": 329897,
              "parentId": 326706,
              "name": "肥城市"
            }
          ]
        },
        {
          "cityId": 330547,
          "parentId": 285860,
          "name": "威海市",
          "child": [{
              "cityId": 330548,
              "parentId": 330547,
              "name": "市辖区"
            },
            {
              "cityId": 330549,
              "parentId": 330547,
              "name": "环翠区"
            },
            {
              "cityId": 330748,
              "parentId": 330547,
              "name": "文登区"
            },
            {
              "cityId": 331529,
              "parentId": 330547,
              "name": "荣成市"
            },
            {
              "cityId": 332496,
              "parentId": 330547,
              "name": "乳山市"
            }
          ]
        },
        {
          "cityId": 333140,
          "parentId": 285860,
          "name": "日照市",
          "child": [{
              "cityId": 333141,
              "parentId": 333140,
              "name": "市辖区"
            },
            {
              "cityId": 333142,
              "parentId": 333140,
              "name": "东港区"
            },
            {
              "cityId": 333755,
              "parentId": 333140,
              "name": "岚山区"
            },
            {
              "cityId": 334188,
              "parentId": 333140,
              "name": "五莲县"
            },
            {
              "cityId": 334833,
              "parentId": 333140,
              "name": "莒县"
            }
          ]
        },
        {
          "cityId": 336050,
          "parentId": 285860,
          "name": "莱芜市",
          "child": [{
              "cityId": 336051,
              "parentId": 336050,
              "name": "市辖区"
            },
            {
              "cityId": 336052,
              "parentId": 336050,
              "name": "莱城区"
            },
            {
              "cityId": 336908,
              "parentId": 336050,
              "name": "钢城区"
            }
          ]
        },
        {
          "cityId": 337144,
          "parentId": 285860,
          "name": "临沂市",
          "child": [{
              "cityId": 337145,
              "parentId": 337144,
              "name": "市辖区"
            },
            {
              "cityId": 337146,
              "parentId": 337144,
              "name": "兰山区"
            },
            {
              "cityId": 337621,
              "parentId": 337144,
              "name": "罗庄区"
            },
            {
              "cityId": 337844,
              "parentId": 337144,
              "name": "河东区"
            },
            {
              "cityId": 338188,
              "parentId": 337144,
              "name": "沂南县"
            },
            {
              "cityId": 338778,
              "parentId": 337144,
              "name": "郯城县"
            },
            {
              "cityId": 339408,
              "parentId": 337144,
              "name": "沂水县"
            },
            {
              "cityId": 340490,
              "parentId": 337144,
              "name": "兰陵县"
            },
            {
              "cityId": 341532,
              "parentId": 337144,
              "name": "费县"
            },
            {
              "cityId": 342020,
              "parentId": 337144,
              "name": "平邑县"
            },
            {
              "cityId": 342766,
              "parentId": 337144,
              "name": "莒南县"
            },
            {
              "cityId": 343361,
              "parentId": 337144,
              "name": "蒙阴县"
            },
            {
              "cityId": 343836,
              "parentId": 337144,
              "name": "临沭县"
            }
          ]
        },
        {
          "cityId": 344133,
          "parentId": 285860,
          "name": "德州市",
          "child": [{
              "cityId": 344134,
              "parentId": 344133,
              "name": "市辖区"
            },
            {
              "cityId": 344135,
              "parentId": 344133,
              "name": "德城区"
            },
            {
              "cityId": 344244,
              "parentId": 344133,
              "name": "陵城区"
            },
            {
              "cityId": 345248,
              "parentId": 344133,
              "name": "宁津县"
            },
            {
              "cityId": 346117,
              "parentId": 344133,
              "name": "庆云县"
            },
            {
              "cityId": 346518,
              "parentId": 344133,
              "name": "临邑县"
            },
            {
              "cityId": 346715,
              "parentId": 344133,
              "name": "齐河县"
            },
            {
              "cityId": 347749,
              "parentId": 344133,
              "name": "平原县"
            },
            {
              "cityId": 347943,
              "parentId": 344133,
              "name": "夏津县"
            },
            {
              "cityId": 348268,
              "parentId": 344133,
              "name": "武城县"
            },
            {
              "cityId": 348472,
              "parentId": 344133,
              "name": "乐陵市"
            },
            {
              "cityId": 348980,
              "parentId": 344133,
              "name": "禹城市"
            }
          ]
        },
        {
          "cityId": 349382,
          "parentId": 285860,
          "name": "聊城市",
          "child": [{
              "cityId": 349383,
              "parentId": 349382,
              "name": "市辖区"
            },
            {
              "cityId": 349384,
              "parentId": 349382,
              "name": "东昌府区"
            },
            {
              "cityId": 350543,
              "parentId": 349382,
              "name": "阳谷县"
            },
            {
              "cityId": 351436,
              "parentId": 349382,
              "name": "莘县"
            },
            {
              "cityId": 352587,
              "parentId": 349382,
              "name": "茌平县"
            },
            {
              "cityId": 353334,
              "parentId": 349382,
              "name": "东阿县"
            },
            {
              "cityId": 353863,
              "parentId": 349382,
              "name": "冠县"
            },
            {
              "cityId": 354643,
              "parentId": 349382,
              "name": "高唐县"
            },
            {
              "cityId": 355301,
              "parentId": 349382,
              "name": "临清市"
            }
          ]
        },
        {
          "cityId": 355972,
          "parentId": 285860,
          "name": "滨州市",
          "child": [{
              "cityId": 355973,
              "parentId": 355972,
              "name": "市辖区"
            },
            {
              "cityId": 355974,
              "parentId": 355972,
              "name": "滨城区"
            },
            {
              "cityId": 356960,
              "parentId": 355972,
              "name": "沾化区"
            },
            {
              "cityId": 357420,
              "parentId": 355972,
              "name": "惠民县"
            },
            {
              "cityId": 358556,
              "parentId": 355972,
              "name": "阳信县"
            },
            {
              "cityId": 359424,
              "parentId": 355972,
              "name": "无棣县"
            },
            {
              "cityId": 360038,
              "parentId": 355972,
              "name": "博兴县"
            },
            {
              "cityId": 360507,
              "parentId": 355972,
              "name": "邹平县"
            }
          ]
        },
        {
          "cityId": 361375,
          "parentId": 285860,
          "name": "菏泽市",
          "child": [{
              "cityId": 361376,
              "parentId": 361375,
              "name": "市辖区"
            },
            {
              "cityId": 361377,
              "parentId": 361375,
              "name": "牡丹区"
            },
            {
              "cityId": 361988,
              "parentId": 361375,
              "name": "定陶区"
            },
            {
              "cityId": 362368,
              "parentId": 361375,
              "name": "曹县"
            },
            {
              "cityId": 363576,
              "parentId": 361375,
              "name": "单县"
            },
            {
              "cityId": 364107,
              "parentId": 361375,
              "name": "成武县"
            },
            {
              "cityId": 364606,
              "parentId": 361375,
              "name": "巨野县"
            },
            {
              "cityId": 365498,
              "parentId": 361375,
              "name": "郓城县"
            },
            {
              "cityId": 366562,
              "parentId": 361375,
              "name": "鄄城县"
            },
            {
              "cityId": 366971,
              "parentId": 361375,
              "name": "东明县"
            }
          ]
        }
      ]
    },
    {
      "cityId": 367395,
      "parentId": 0,
      "name": "河南省",
      "child": [{
          "cityId": 367396,
          "parentId": 367395,
          "name": "郑州市",
          "child": [{
              "cityId": 367397,
              "parentId": 367396,
              "name": "市辖区"
            },
            {
              "cityId": 367398,
              "parentId": 367396,
              "name": "中原区"
            },
            {
              "cityId": 367563,
              "parentId": 367396,
              "name": "二七区"
            },
            {
              "cityId": 367741,
              "parentId": 367396,
              "name": "管城回族区"
            },
            {
              "cityId": 367877,
              "parentId": 367396,
              "name": "金水区"
            },
            {
              "cityId": 368151,
              "parentId": 367396,
              "name": "上街区"
            },
            {
              "cityId": 368217,
              "parentId": 367396,
              "name": "惠济区"
            },
            {
              "cityId": 368291,
              "parentId": 367396,
              "name": "中牟县"
            },
            {
              "cityId": 368635,
              "parentId": 367396,
              "name": "巩义市"
            },
            {
              "cityId": 368975,
              "parentId": 367396,
              "name": "荥阳市"
            },
            {
              "cityId": 369293,
              "parentId": 367396,
              "name": "新密市"
            },
            {
              "cityId": 369663,
              "parentId": 367396,
              "name": "新郑市"
            },
            {
              "cityId": 369963,
              "parentId": 367396,
              "name": "登封市"
            }
          ]
        },
        {
          "cityId": 370304,
          "parentId": 367395,
          "name": "开封市",
          "child": [{
              "cityId": 370305,
              "parentId": 370304,
              "name": "市辖区"
            },
            {
              "cityId": 370306,
              "parentId": 370304,
              "name": "龙亭区"
            },
            {
              "cityId": 370373,
              "parentId": 370304,
              "name": "顺河回族区"
            },
            {
              "cityId": 370450,
              "parentId": 370304,
              "name": "鼓楼区"
            },
            {
              "cityId": 370501,
              "parentId": 370304,
              "name": "禹王台区"
            },
            {
              "cityId": 370557,
              "parentId": 370304,
              "name": "金明区"
            },
            {
              "cityId": 370634,
              "parentId": 370304,
              "name": "祥符区"
            },
            {
              "cityId": 370995,
              "parentId": 370304,
              "name": "杞县"
            },
            {
              "cityId": 371618,
              "parentId": 370304,
              "name": "通许县"
            },
            {
              "cityId": 371942,
              "parentId": 370304,
              "name": "尉氏县"
            },
            {
              "cityId": 372481,
              "parentId": 370304,
              "name": "兰考县"
            }
          ]
        },
        {
          "cityId": 372971,
          "parentId": 367395,
          "name": "洛阳市",
          "child": [{
              "cityId": 372972,
              "parentId": 372971,
              "name": "市辖区"
            },
            {
              "cityId": 372973,
              "parentId": 372971,
              "name": "老城区"
            },
            {
              "cityId": 373030,
              "parentId": 372971,
              "name": "西工区"
            },
            {
              "cityId": 373105,
              "parentId": 372971,
              "name": "瀍河回族区"
            },
            {
              "cityId": 373143,
              "parentId": 372971,
              "name": "涧西区"
            },
            {
              "cityId": 373227,
              "parentId": 372971,
              "name": "吉利区"
            },
            {
              "cityId": 373268,
              "parentId": 372971,
              "name": "洛龙区"
            },
            {
              "cityId": 373554,
              "parentId": 372971,
              "name": "孟津县"
            },
            {
              "cityId": 373799,
              "parentId": 372971,
              "name": "新安县"
            },
            {
              "cityId": 374121,
              "parentId": 372971,
              "name": "栾川县"
            },
            {
              "cityId": 374349,
              "parentId": 372971,
              "name": "嵩县"
            },
            {
              "cityId": 374694,
              "parentId": 372971,
              "name": "汝阳县"
            },
            {
              "cityId": 374929,
              "parentId": 372971,
              "name": "宜阳县"
            },
            {
              "cityId": 375308,
              "parentId": 372971,
              "name": "洛宁县"
            },
            {
              "cityId": 375717,
              "parentId": 372971,
              "name": "伊川县"
            },
            {
              "cityId": 376105,
              "parentId": 372971,
              "name": "偃师市"
            }
          ]
        },
        {
          "cityId": 376365,
          "parentId": 367395,
          "name": "平顶山市",
          "child": [{
              "cityId": 376366,
              "parentId": 376365,
              "name": "市辖区"
            },
            {
              "cityId": 376367,
              "parentId": 376365,
              "name": "新华区"
            },
            {
              "cityId": 376442,
              "parentId": 376365,
              "name": "卫东区"
            },
            {
              "cityId": 376513,
              "parentId": 376365,
              "name": "石龙区"
            },
            {
              "cityId": 376532,
              "parentId": 376365,
              "name": "湛河区"
            },
            {
              "cityId": 376643,
              "parentId": 376365,
              "name": "宝丰县"
            },
            {
              "cityId": 376984,
              "parentId": 376365,
              "name": "叶县"
            },
            {
              "cityId": 377555,
              "parentId": 376365,
              "name": "鲁山县"
            },
            {
              "cityId": 378139,
              "parentId": 376365,
              "name": "郏县"
            },
            {
              "cityId": 378532,
              "parentId": 376365,
              "name": "舞钢市"
            },
            {
              "cityId": 378764,
              "parentId": 376365,
              "name": "汝州市"
            }
          ]
        },
        {
          "cityId": 379245,
          "parentId": 367395,
          "name": "安阳市",
          "child": [{
              "cityId": 379246,
              "parentId": 379245,
              "name": "市辖区"
            },
            {
              "cityId": 379247,
              "parentId": 379245,
              "name": "文峰区"
            },
            {
              "cityId": 379400,
              "parentId": 379245,
              "name": "北关区"
            },
            {
              "cityId": 379488,
              "parentId": 379245,
              "name": "殷都区"
            },
            {
              "cityId": 379584,
              "parentId": 379245,
              "name": "龙安区"
            },
            {
              "cityId": 379742,
              "parentId": 379245,
              "name": "安阳县"
            },
            {
              "cityId": 380371,
              "parentId": 379245,
              "name": "汤阴县"
            },
            {
              "cityId": 380688,
              "parentId": 379245,
              "name": "滑县"
            },
            {
              "cityId": 381731,
              "parentId": 379245,
              "name": "内黄县"
            },
            {
              "cityId": 382281,
              "parentId": 379245,
              "name": "林州市"
            }
          ]
        },
        {
          "cityId": 382878,
          "parentId": 367395,
          "name": "鹤壁市",
          "child": [{
              "cityId": 382879,
              "parentId": 382878,
              "name": "市辖区"
            },
            {
              "cityId": 382880,
              "parentId": 382878,
              "name": "鹤山区"
            },
            {
              "cityId": 382969,
              "parentId": 382878,
              "name": "山城区"
            },
            {
              "cityId": 383079,
              "parentId": 382878,
              "name": "淇滨区"
            },
            {
              "cityId": 383228,
              "parentId": 382878,
              "name": "浚县"
            },
            {
              "cityId": 383704,
              "parentId": 382878,
              "name": "淇县"
            }
          ]
        },
        {
          "cityId": 383890,
          "parentId": 367395,
          "name": "新乡市",
          "child": [{
              "cityId": 383891,
              "parentId": 383890,
              "name": "市辖区"
            },
            {
              "cityId": 383892,
              "parentId": 383890,
              "name": "红旗区"
            },
            {
              "cityId": 383978,
              "parentId": 383890,
              "name": "卫滨区"
            },
            {
              "cityId": 384041,
              "parentId": 383890,
              "name": "凤泉区"
            },
            {
              "cityId": 384093,
              "parentId": 383890,
              "name": "牧野区"
            },
            {
              "cityId": 384193,
              "parentId": 383890,
              "name": "新乡县"
            },
            {
              "cityId": 384380,
              "parentId": 383890,
              "name": "获嘉县"
            },
            {
              "cityId": 384622,
              "parentId": 383890,
              "name": "原阳县"
            },
            {
              "cityId": 385122,
              "parentId": 383890,
              "name": "延津县"
            },
            {
              "cityId": 385485,
              "parentId": 383890,
              "name": "封丘县"
            },
            {
              "cityId": 386113,
              "parentId": 383890,
              "name": "长垣县"
            },
            {
              "cityId": 386744,
              "parentId": 383890,
              "name": "卫辉市"
            },
            {
              "cityId": 387125,
              "parentId": 383890,
              "name": "辉县市"
            }
          ]
        },
        {
          "cityId": 387707,
          "parentId": 367395,
          "name": "焦作市",
          "child": [{
              "cityId": 387708,
              "parentId": 387707,
              "name": "市辖区"
            },
            {
              "cityId": 387709,
              "parentId": 387707,
              "name": "解放区"
            },
            {
              "cityId": 387776,
              "parentId": 387707,
              "name": "中站区"
            },
            {
              "cityId": 387836,
              "parentId": 387707,
              "name": "马村区"
            },
            {
              "cityId": 387922,
              "parentId": 387707,
              "name": "山阳区"
            },
            {
              "cityId": 387985,
              "parentId": 387707,
              "name": "修武县"
            },
            {
              "cityId": 388187,
              "parentId": 387707,
              "name": "博爱县"
            },
            {
              "cityId": 388415,
              "parentId": 387707,
              "name": "武陟县"
            },
            {
              "cityId": 388783,
              "parentId": 387707,
              "name": "温县"
            },
            {
              "cityId": 389064,
              "parentId": 387707,
              "name": "沁阳市"
            },
            {
              "cityId": 389418,
              "parentId": 387707,
              "name": "孟州市"
            }
          ]
        },
        {
          "cityId": 389719,
          "parentId": 367395,
          "name": "濮阳市",
          "child": [{
              "cityId": 389720,
              "parentId": 389719,
              "name": "市辖区"
            },
            {
              "cityId": 389721,
              "parentId": 389719,
              "name": "华龙区"
            },
            {
              "cityId": 389884,
              "parentId": 389719,
              "name": "清丰县"
            },
            {
              "cityId": 390411,
              "parentId": 389719,
              "name": "南乐县"
            },
            {
              "cityId": 390751,
              "parentId": 389719,
              "name": "范县"
            },
            {
              "cityId": 391345,
              "parentId": 389719,
              "name": "台前县"
            },
            {
              "cityId": 391730,
              "parentId": 389719,
              "name": "濮阳县"
            }
          ]
        },
        {
          "cityId": 392737,
          "parentId": 367395,
          "name": "许昌市",
          "child": [{
              "cityId": 392738,
              "parentId": 392737,
              "name": "市辖区"
            },
            {
              "cityId": 392739,
              "parentId": 392737,
              "name": "魏都区"
            },
            {
              "cityId": 392850,
              "parentId": 392737,
              "name": "许昌县"
            },
            {
              "cityId": 393306,
              "parentId": 392737,
              "name": "鄢陵县"
            },
            {
              "cityId": 393705,
              "parentId": 392737,
              "name": "襄城县"
            },
            {
              "cityId": 394170,
              "parentId": 392737,
              "name": "禹州市"
            },
            {
              "cityId": 394863,
              "parentId": 392737,
              "name": "长葛市"
            }
          ]
        },
        {
          "cityId": 395254,
          "parentId": 367395,
          "name": "漯河市",
          "child": [{
              "cityId": 395255,
              "parentId": 395254,
              "name": "市辖区"
            },
            {
              "cityId": 395256,
              "parentId": 395254,
              "name": "源汇区"
            },
            {
              "cityId": 395392,
              "parentId": 395254,
              "name": "郾城区"
            },
            {
              "cityId": 395601,
              "parentId": 395254,
              "name": "召陵区"
            },
            {
              "cityId": 395816,
              "parentId": 395254,
              "name": "舞阳县"
            },
            {
              "cityId": 396228,
              "parentId": 395254,
              "name": "临颍县"
            }
          ]
        },
        {
          "cityId": 396611,
          "parentId": 367395,
          "name": "三门峡市",
          "child": [{
              "cityId": 396612,
              "parentId": 396611,
              "name": "市辖区"
            },
            {
              "cityId": 396613,
              "parentId": 396611,
              "name": "湖滨区"
            },
            {
              "cityId": 396710,
              "parentId": 396611,
              "name": "陕州区"
            },
            {
              "cityId": 396996,
              "parentId": 396611,
              "name": "渑池县"
            },
            {
              "cityId": 397257,
              "parentId": 396611,
              "name": "卢氏县"
            },
            {
              "cityId": 397644,
              "parentId": 396611,
              "name": "义马市"
            },
            {
              "cityId": 397687,
              "parentId": 396611,
              "name": "灵宝市"
            }
          ]
        },
        {
          "cityId": 398169,
          "parentId": 367395,
          "name": "南阳市",
          "child": [{
              "cityId": 398170,
              "parentId": 398169,
              "name": "市辖区"
            },
            {
              "cityId": 398171,
              "parentId": 398169,
              "name": "宛城区"
            },
            {
              "cityId": 398437,
              "parentId": 398169,
              "name": "卧龙区"
            },
            {
              "cityId": 398747,
              "parentId": 398169,
              "name": "南召县"
            },
            {
              "cityId": 399117,
              "parentId": 398169,
              "name": "方城县"
            },
            {
              "cityId": 399702,
              "parentId": 398169,
              "name": "西峡县"
            },
            {
              "cityId": 400021,
              "parentId": 398169,
              "name": "镇平县"
            },
            {
              "cityId": 400474,
              "parentId": 398169,
              "name": "内乡县"
            },
            {
              "cityId": 400787,
              "parentId": 398169,
              "name": "淅川县"
            },
            {
              "cityId": 401305,
              "parentId": 398169,
              "name": "社旗县"
            },
            {
              "cityId": 401579,
              "parentId": 398169,
              "name": "唐河县"
            },
            {
              "cityId": 402141,
              "parentId": 398169,
              "name": "新野县"
            },
            {
              "cityId": 402429,
              "parentId": 398169,
              "name": "桐柏县"
            },
            {
              "cityId": 402668,
              "parentId": 398169,
              "name": "邓州市"
            }
          ]
        },
        {
          "cityId": 403311,
          "parentId": 367395,
          "name": "商丘市",
          "child": [{
              "cityId": 403312,
              "parentId": 403311,
              "name": "市辖区"
            },
            {
              "cityId": 403313,
              "parentId": 403311,
              "name": "梁园区"
            },
            {
              "cityId": 403577,
              "parentId": 403311,
              "name": "睢阳区"
            },
            {
              "cityId": 403992,
              "parentId": 403311,
              "name": "民权县"
            },
            {
              "cityId": 404572,
              "parentId": 403311,
              "name": "睢县"
            },
            {
              "cityId": 405150,
              "parentId": 403311,
              "name": "宁陵县"
            },
            {
              "cityId": 405530,
              "parentId": 403311,
              "name": "柘城县"
            },
            {
              "cityId": 406070,
              "parentId": 403311,
              "name": "虞城县"
            },
            {
              "cityId": 406717,
              "parentId": 403311,
              "name": "夏邑县"
            },
            {
              "cityId": 407487,
              "parentId": 403311,
              "name": "永城市"
            }
          ]
        },
        {
          "cityId": 408287,
          "parentId": 367395,
          "name": "信阳市",
          "child": [{
              "cityId": 408288,
              "parentId": 408287,
              "name": "市辖区"
            },
            {
              "cityId": 408289,
              "parentId": 408287,
              "name": "浉河区"
            },
            {
              "cityId": 408566,
              "parentId": 408287,
              "name": "平桥区"
            },
            {
              "cityId": 408914,
              "parentId": 408287,
              "name": "罗山县"
            },
            {
              "cityId": 409241,
              "parentId": 408287,
              "name": "光山县"
            },
            {
              "cityId": 409621,
              "parentId": 408287,
              "name": "新县"
            },
            {
              "cityId": 409844,
              "parentId": 408287,
              "name": "商城县"
            },
            {
              "cityId": 410235,
              "parentId": 408287,
              "name": "固始县"
            },
            {
              "cityId": 410916,
              "parentId": 408287,
              "name": "潢川县"
            },
            {
              "cityId": 411226,
              "parentId": 408287,
              "name": "淮滨县"
            },
            {
              "cityId": 411541,
              "parentId": 408287,
              "name": "息县"
            }
          ]
        },
        {
          "cityId": 411925,
          "parentId": 367395,
          "name": "周口市",
          "child": [{
              "cityId": 411926,
              "parentId": 411925,
              "name": "市辖区"
            },
            {
              "cityId": 411927,
              "parentId": 411925,
              "name": "川汇区"
            },
            {
              "cityId": 412097,
              "parentId": 411925,
              "name": "扶沟县"
            },
            {
              "cityId": 412526,
              "parentId": 411925,
              "name": "西华县"
            },
            {
              "cityId": 413030,
              "parentId": 411925,
              "name": "商水县"
            },
            {
              "cityId": 413644,
              "parentId": 411925,
              "name": "沈丘县"
            },
            {
              "cityId": 414239,
              "parentId": 411925,
              "name": "郸城县"
            },
            {
              "cityId": 414779,
              "parentId": 411925,
              "name": "淮阳县"
            },
            {
              "cityId": 415271,
              "parentId": 411925,
              "name": "太康县"
            },
            {
              "cityId": 416070,
              "parentId": 411925,
              "name": "鹿邑县"
            },
            {
              "cityId": 416651,
              "parentId": 411925,
              "name": "项城市"
            }
          ]
        },
        {
          "cityId": 417174,
          "parentId": 367395,
          "name": "驻马店市",
          "child": [{
              "cityId": 417175,
              "parentId": 417174,
              "name": "市辖区"
            },
            {
              "cityId": 417176,
              "parentId": 417174,
              "name": "驿城区"
            },
            {
              "cityId": 417405,
              "parentId": 417174,
              "name": "西平县"
            },
            {
              "cityId": 417712,
              "parentId": 417174,
              "name": "上蔡县"
            },
            {
              "cityId": 418199,
              "parentId": 417174,
              "name": "平舆县"
            },
            {
              "cityId": 418443,
              "parentId": 417174,
              "name": "正阳县"
            },
            {
              "cityId": 418759,
              "parentId": 417174,
              "name": "确山县"
            },
            {
              "cityId": 418973,
              "parentId": 417174,
              "name": "泌阳县"
            },
            {
              "cityId": 419354,
              "parentId": 417174,
              "name": "汝南县"
            },
            {
              "cityId": 419654,
              "parentId": 417174,
              "name": "遂平县"
            },
            {
              "cityId": 419874,
              "parentId": 417174,
              "name": "新蔡县"
            }
          ]
        },
        {
          "cityId": 420258,
          "parentId": 367395,
          "name": "直辖县",
          "child": [{
            "cityId": 420259,
            "parentId": 420258,
            "name": "济源市"
          }]
        }
      ]
    },
    {
      "cityId": 420824,
      "parentId": 0,
      "name": "湖北省",
      "child": [{
          "cityId": 420825,
          "parentId": 420824,
          "name": "武汉市",
          "child": [{
              "cityId": 420826,
              "parentId": 420825,
              "name": "市辖区"
            },
            {
              "cityId": 420827,
              "parentId": 420825,
              "name": "江岸区"
            },
            {
              "cityId": 420996,
              "parentId": 420825,
              "name": "江汉区"
            },
            {
              "cityId": 421122,
              "parentId": 420825,
              "name": "硚口区"
            },
            {
              "cityId": 421270,
              "parentId": 420825,
              "name": "汉阳区"
            },
            {
              "cityId": 421400,
              "parentId": 420825,
              "name": "武昌区"
            },
            {
              "cityId": 421559,
              "parentId": 420825,
              "name": "青山区"
            },
            {
              "cityId": 421676,
              "parentId": 420825,
              "name": "洪山区"
            },
            {
              "cityId": 421983,
              "parentId": 420825,
              "name": "东西湖区"
            },
            {
              "cityId": 422149,
              "parentId": 420825,
              "name": "汉南区"
            },
            {
              "cityId": 422220,
              "parentId": 420825,
              "name": "蔡甸区"
            },
            {
              "cityId": 422631,
              "parentId": 420825,
              "name": "江夏区"
            },
            {
              "cityId": 423075,
              "parentId": 420825,
              "name": "黄陂区"
            },
            {
              "cityId": 423775,
              "parentId": 420825,
              "name": "新洲区"
            }
          ]
        },
        {
          "cityId": 424449,
          "parentId": 420824,
          "name": "黄石市",
          "child": [{
              "cityId": 424450,
              "parentId": 424449,
              "name": "市辖区"
            },
            {
              "cityId": 424451,
              "parentId": 424449,
              "name": "黄石港区"
            },
            {
              "cityId": 424489,
              "parentId": 424449,
              "name": "西塞山区"
            },
            {
              "cityId": 424531,
              "parentId": 424449,
              "name": "下陆区"
            },
            {
              "cityId": 424574,
              "parentId": 424449,
              "name": "铁山区"
            },
            {
              "cityId": 424590,
              "parentId": 424449,
              "name": "阳新县"
            },
            {
              "cityId": 425070,
              "parentId": 424449,
              "name": "大冶市"
            }
          ]
        },
        {
          "cityId": 425508,
          "parentId": 420824,
          "name": "十堰市",
          "child": [{
              "cityId": 425509,
              "parentId": 425508,
              "name": "市辖区"
            },
            {
              "cityId": 425510,
              "parentId": 425508,
              "name": "茅箭区"
            },
            {
              "cityId": 425592,
              "parentId": 425508,
              "name": "张湾区"
            },
            {
              "cityId": 425709,
              "parentId": 425508,
              "name": "郧阳区"
            },
            {
              "cityId": 426078,
              "parentId": 425508,
              "name": "郧西县"
            },
            {
              "cityId": 426440,
              "parentId": 425508,
              "name": "竹山县"
            },
            {
              "cityId": 426708,
              "parentId": 425508,
              "name": "竹溪县"
            },
            {
              "cityId": 427085,
              "parentId": 425508,
              "name": "房县"
            },
            {
              "cityId": 427420,
              "parentId": 425508,
              "name": "丹江口市"
            }
          ]
        },
        {
          "cityId": 427710,
          "parentId": 420824,
          "name": "宜昌市",
          "child": [{
              "cityId": 427711,
              "parentId": 427710,
              "name": "市辖区"
            },
            {
              "cityId": 427712,
              "parentId": 427710,
              "name": "西陵区"
            },
            {
              "cityId": 427828,
              "parentId": 427710,
              "name": "伍家岗区"
            },
            {
              "cityId": 427894,
              "parentId": 427710,
              "name": "点军区"
            },
            {
              "cityId": 427953,
              "parentId": 427710,
              "name": "猇亭区"
            },
            {
              "cityId": 427983,
              "parentId": 427710,
              "name": "夷陵区"
            },
            {
              "cityId": 428194,
              "parentId": 427710,
              "name": "远安县"
            },
            {
              "cityId": 428319,
              "parentId": 427710,
              "name": "兴山县"
            },
            {
              "cityId": 428424,
              "parentId": 427710,
              "name": "秭归县"
            },
            {
              "cityId": 428630,
              "parentId": 427710,
              "name": "长阳土家族自治县"
            },
            {
              "cityId": 428807,
              "parentId": 427710,
              "name": "五峰土家族自治县"
            },
            {
              "cityId": 428924,
              "parentId": 427710,
              "name": "宜都市"
            },
            {
              "cityId": 429089,
              "parentId": 427710,
              "name": "当阳市"
            },
            {
              "cityId": 429273,
              "parentId": 427710,
              "name": "枝江市"
            }
          ]
        },
        {
          "cityId": 429501,
          "parentId": 420824,
          "name": "襄阳市",
          "child": [{
              "cityId": 429502,
              "parentId": 429501,
              "name": "市辖区"
            },
            {
              "cityId": 429503,
              "parentId": 429501,
              "name": "襄城区"
            },
            {
              "cityId": 429687,
              "parentId": 429501,
              "name": "樊城区"
            },
            {
              "cityId": 429907,
              "parentId": 429501,
              "name": "襄州区"
            },
            {
              "cityId": 430402,
              "parentId": 429501,
              "name": "南漳县"
            },
            {
              "cityId": 430723,
              "parentId": 429501,
              "name": "谷城县"
            },
            {
              "cityId": 431025,
              "parentId": 429501,
              "name": "保康县"
            },
            {
              "cityId": 431301,
              "parentId": 429501,
              "name": "老河口市"
            },
            {
              "cityId": 431575,
              "parentId": 429501,
              "name": "枣阳市"
            },
            {
              "cityId": 432166,
              "parentId": 429501,
              "name": "宜城市"
            }
          ]
        },
        {
          "cityId": 432415,
          "parentId": 420824,
          "name": "鄂州市",
          "child": [{
              "cityId": 432416,
              "parentId": 432415,
              "name": "市辖区"
            },
            {
              "cityId": 432417,
              "parentId": 432415,
              "name": "梁子湖区"
            },
            {
              "cityId": 432510,
              "parentId": 432415,
              "name": "华容区"
            },
            {
              "cityId": 432633,
              "parentId": 432415,
              "name": "鄂城区"
            }
          ]
        },
        {
          "cityId": 432829,
          "parentId": 420824,
          "name": "荆门市",
          "child": [{
              "cityId": 432830,
              "parentId": 432829,
              "name": "市辖区"
            },
            {
              "cityId": 432831,
              "parentId": 432829,
              "name": "东宝区"
            },
            {
              "cityId": 433052,
              "parentId": 432829,
              "name": "掇刀区"
            },
            {
              "cityId": 433182,
              "parentId": 432829,
              "name": "京山县"
            },
            {
              "cityId": 433634,
              "parentId": 432829,
              "name": "沙洋县"
            },
            {
              "cityId": 433944,
              "parentId": 432829,
              "name": "钟祥市"
            }
          ]
        },
        {
          "cityId": 434548,
          "parentId": 420824,
          "name": "孝感市",
          "child": [{
              "cityId": 434549,
              "parentId": 434548,
              "name": "市辖区"
            },
            {
              "cityId": 434550,
              "parentId": 434548,
              "name": "孝南区"
            },
            {
              "cityId": 435091,
              "parentId": 434548,
              "name": "孝昌县"
            },
            {
              "cityId": 435563,
              "parentId": 434548,
              "name": "大悟县"
            },
            {
              "cityId": 435956,
              "parentId": 434548,
              "name": "云梦县"
            },
            {
              "cityId": 436267,
              "parentId": 434548,
              "name": "应城市"
            },
            {
              "cityId": 436706,
              "parentId": 434548,
              "name": "安陆市"
            },
            {
              "cityId": 437119,
              "parentId": 434548,
              "name": "汉川市"
            }
          ]
        },
        {
          "cityId": 437779,
          "parentId": 420824,
          "name": "荆州市",
          "child": [{
              "cityId": 437780,
              "parentId": 437779,
              "name": "市辖区"
            },
            {
              "cityId": 437781,
              "parentId": 437779,
              "name": "沙市区"
            },
            {
              "cityId": 437909,
              "parentId": 437779,
              "name": "荆州区"
            },
            {
              "cityId": 438103,
              "parentId": 437779,
              "name": "公安县"
            },
            {
              "cityId": 438512,
              "parentId": 437779,
              "name": "监利县"
            },
            {
              "cityId": 439263,
              "parentId": 437779,
              "name": "江陵县"
            },
            {
              "cityId": 439491,
              "parentId": 437779,
              "name": "石首市"
            },
            {
              "cityId": 439836,
              "parentId": 437779,
              "name": "洪湖市"
            },
            {
              "cityId": 440344,
              "parentId": 437779,
              "name": "松滋市"
            }
          ]
        },
        {
          "cityId": 440639,
          "parentId": 420824,
          "name": "黄冈市",
          "child": [{
              "cityId": 440640,
              "parentId": 440639,
              "name": "市辖区"
            },
            {
              "cityId": 440641,
              "parentId": 440639,
              "name": "黄州区"
            },
            {
              "cityId": 440797,
              "parentId": 440639,
              "name": "团风县"
            },
            {
              "cityId": 441110,
              "parentId": 440639,
              "name": "红安县"
            },
            {
              "cityId": 441550,
              "parentId": 440639,
              "name": "罗田县"
            },
            {
              "cityId": 442017,
              "parentId": 440639,
              "name": "英山县"
            },
            {
              "cityId": 442351,
              "parentId": 440639,
              "name": "浠水县"
            },
            {
              "cityId": 443040,
              "parentId": 440639,
              "name": "蕲春县"
            },
            {
              "cityId": 443641,
              "parentId": 440639,
              "name": "黄梅县"
            },
            {
              "cityId": 444186,
              "parentId": 440639,
              "name": "麻城市"
            },
            {
              "cityId": 444684,
              "parentId": 440639,
              "name": "武穴市"
            }
          ]
        },
        {
          "cityId": 445040,
          "parentId": 420824,
          "name": "咸宁市",
          "child": [{
              "cityId": 445041,
              "parentId": 445040,
              "name": "市辖区"
            },
            {
              "cityId": 445042,
              "parentId": 445040,
              "name": "咸安区"
            },
            {
              "cityId": 445249,
              "parentId": 445040,
              "name": "嘉鱼县"
            },
            {
              "cityId": 445363,
              "parentId": 445040,
              "name": "通城县"
            },
            {
              "cityId": 445569,
              "parentId": 445040,
              "name": "崇阳县"
            },
            {
              "cityId": 445786,
              "parentId": 445040,
              "name": "通山县"
            },
            {
              "cityId": 446007,
              "parentId": 445040,
              "name": "赤壁市"
            }
          ]
        },
        {
          "cityId": 446212,
          "parentId": 420824,
          "name": "随州市",
          "child": [{
              "cityId": 446213,
              "parentId": 446212,
              "name": "市辖区"
            },
            {
              "cityId": 446214,
              "parentId": 446212,
              "name": "曾都区"
            },
            {
              "cityId": 446433,
              "parentId": 446212,
              "name": "随县"
            },
            {
              "cityId": 446847,
              "parentId": 446212,
              "name": "广水市"
            }
          ]
        },
        {
          "cityId": 447268,
          "parentId": 420824,
          "name": "恩施土家族苗族自治州",
          "child": [{
              "cityId": 447269,
              "parentId": 447268,
              "name": "恩施市"
            },
            {
              "cityId": 447495,
              "parentId": 447268,
              "name": "利川市"
            },
            {
              "cityId": 448099,
              "parentId": 447268,
              "name": "建始县"
            },
            {
              "cityId": 448521,
              "parentId": 447268,
              "name": "巴东县"
            },
            {
              "cityId": 448856,
              "parentId": 447268,
              "name": "宣恩县"
            },
            {
              "cityId": 449150,
              "parentId": 447268,
              "name": "咸丰县"
            },
            {
              "cityId": 449431,
              "parentId": 447268,
              "name": "来凤县"
            },
            {
              "cityId": 449636,
              "parentId": 447268,
              "name": "鹤峰县"
            }
          ]
        },
        {
          "cityId": 449865,
          "parentId": 420824,
          "name": "直辖县",
          "child": [{
              "cityId": 449866,
              "parentId": 449865,
              "name": "仙桃市"
            },
            {
              "cityId": 450653,
              "parentId": 449865,
              "name": "潜江市"
            },
            {
              "cityId": 451150,
              "parentId": 449865,
              "name": "天门市"
            },
            {
              "cityId": 452034,
              "parentId": 449865,
              "name": "神农架林区"
            }
          ]
        }
      ]
    },
    {
      "cityId": 452123,
      "parentId": 0,
      "name": "湖南省",
      "child": [{
          "cityId": 452124,
          "parentId": 452123,
          "name": "长沙市",
          "child": [{
              "cityId": 452125,
              "parentId": 452124,
              "name": "市辖区"
            },
            {
              "cityId": 452126,
              "parentId": 452124,
              "name": "芙蓉区"
            },
            {
              "cityId": 452213,
              "parentId": 452124,
              "name": "天心区"
            },
            {
              "cityId": 452314,
              "parentId": 452124,
              "name": "岳麓区"
            },
            {
              "cityId": 452486,
              "parentId": 452124,
              "name": "开福区"
            },
            {
              "cityId": 452610,
              "parentId": 452124,
              "name": "雨花区"
            },
            {
              "cityId": 452771,
              "parentId": 452124,
              "name": "望城区"
            },
            {
              "cityId": 452924,
              "parentId": 452124,
              "name": "长沙县"
            },
            {
              "cityId": 453140,
              "parentId": 452124,
              "name": "宁乡县"
            },
            {
              "cityId": 453444,
              "parentId": 452124,
              "name": "浏阳市"
            }
          ]
        },
        {
          "cityId": 453799,
          "parentId": 452123,
          "name": "株洲市",
          "child": [{
              "cityId": 453800,
              "parentId": 453799,
              "name": "市辖区"
            },
            {
              "cityId": 453801,
              "parentId": 453799,
              "name": "荷塘区"
            },
            {
              "cityId": 453867,
              "parentId": 453799,
              "name": "芦淞区"
            },
            {
              "cityId": 453957,
              "parentId": 453799,
              "name": "石峰区"
            },
            {
              "cityId": 454008,
              "parentId": 453799,
              "name": "天元区"
            },
            {
              "cityId": 454113,
              "parentId": 453799,
              "name": "株洲县"
            },
            {
              "cityId": 454261,
              "parentId": 453799,
              "name": "攸县"
            },
            {
              "cityId": 454580,
              "parentId": 453799,
              "name": "茶陵县"
            },
            {
              "cityId": 454845,
              "parentId": 453799,
              "name": "炎陵县"
            },
            {
              "cityId": 454989,
              "parentId": 453799,
              "name": "醴陵市"
            }
          ]
        },
        {
          "cityId": 455283,
          "parentId": 452123,
          "name": "湘潭市",
          "child": [{
              "cityId": 455284,
              "parentId": 455283,
              "name": "市辖区"
            },
            {
              "cityId": 455285,
              "parentId": 455283,
              "name": "雨湖区"
            },
            {
              "cityId": 455426,
              "parentId": 455283,
              "name": "岳塘区"
            },
            {
              "cityId": 455515,
              "parentId": 455283,
              "name": "湘潭县"
            },
            {
              "cityId": 455889,
              "parentId": 455283,
              "name": "湘乡市"
            },
            {
              "cityId": 456260,
              "parentId": 455283,
              "name": "韶山市"
            }
          ]
        },
        {
          "cityId": 456303,
          "parentId": 452123,
          "name": "衡阳市",
          "child": [{
              "cityId": 456304,
              "parentId": 456303,
              "name": "市辖区"
            },
            {
              "cityId": 456305,
              "parentId": 456303,
              "name": "珠晖区"
            },
            {
              "cityId": 456391,
              "parentId": 456303,
              "name": "雁峰区"
            },
            {
              "cityId": 456449,
              "parentId": 456303,
              "name": "石鼓区"
            },
            {
              "cityId": 456502,
              "parentId": 456303,
              "name": "蒸湘区"
            },
            {
              "cityId": 456558,
              "parentId": 456303,
              "name": "南岳区"
            },
            {
              "cityId": 456587,
              "parentId": 456303,
              "name": "衡阳县"
            },
            {
              "cityId": 457104,
              "parentId": 456303,
              "name": "衡南县"
            },
            {
              "cityId": 457576,
              "parentId": 456303,
              "name": "衡山县"
            },
            {
              "cityId": 457742,
              "parentId": 456303,
              "name": "衡东县"
            },
            {
              "cityId": 458018,
              "parentId": 456303,
              "name": "祁东县"
            },
            {
              "cityId": 458411,
              "parentId": 456303,
              "name": "耒阳市"
            },
            {
              "cityId": 458820,
              "parentId": 456303,
              "name": "常宁市"
            }
          ]
        },
        {
          "cityId": 459251,
          "parentId": 452123,
          "name": "邵阳市",
          "child": [{
              "cityId": 459252,
              "parentId": 459251,
              "name": "市辖区"
            },
            {
              "cityId": 459253,
              "parentId": 459251,
              "name": "双清区"
            },
            {
              "cityId": 459357,
              "parentId": 459251,
              "name": "大祥区"
            },
            {
              "cityId": 459470,
              "parentId": 459251,
              "name": "北塔区"
            },
            {
              "cityId": 459517,
              "parentId": 459251,
              "name": "邵东县"
            },
            {
              "cityId": 460126,
              "parentId": 459251,
              "name": "新邵县"
            },
            {
              "cityId": 460558,
              "parentId": 459251,
              "name": "邵阳县"
            },
            {
              "cityId": 461014,
              "parentId": 459251,
              "name": "隆回县"
            },
            {
              "cityId": 461613,
              "parentId": 459251,
              "name": "洞口县"
            },
            {
              "cityId": 462000,
              "parentId": 459251,
              "name": "绥宁县"
            },
            {
              "cityId": 462257,
              "parentId": 459251,
              "name": "新宁县"
            },
            {
              "cityId": 462600,
              "parentId": 459251,
              "name": "城步苗族自治县"
            },
            {
              "cityId": 462807,
              "parentId": 459251,
              "name": "武冈市"
            }
          ]
        },
        {
          "cityId": 463141,
          "parentId": 452123,
          "name": "岳阳市",
          "child": [{
              "cityId": 463142,
              "parentId": 463141,
              "name": "市辖区"
            },
            {
              "cityId": 463143,
              "parentId": 463141,
              "name": "岳阳楼区"
            },
            {
              "cityId": 463333,
              "parentId": 463141,
              "name": "云溪区"
            },
            {
              "cityId": 463397,
              "parentId": 463141,
              "name": "君山区"
            },
            {
              "cityId": 463489,
              "parentId": 463141,
              "name": "岳阳县"
            },
            {
              "cityId": 463722,
              "parentId": 463141,
              "name": "华容县"
            },
            {
              "cityId": 463961,
              "parentId": 463141,
              "name": "湘阴县"
            },
            {
              "cityId": 464180,
              "parentId": 463141,
              "name": "平江县"
            },
            {
              "cityId": 464747,
              "parentId": 463141,
              "name": "汨罗市"
            },
            {
              "cityId": 464944,
              "parentId": 463141,
              "name": "临湘市"
            }
          ]
        },
        {
          "cityId": 465105,
          "parentId": 452123,
          "name": "常德市",
          "child": [{
              "cityId": 465106,
              "parentId": 465105,
              "name": "市辖区"
            },
            {
              "cityId": 465107,
              "parentId": 465105,
              "name": "武陵区"
            },
            {
              "cityId": 465295,
              "parentId": 465105,
              "name": "鼎城区"
            },
            {
              "cityId": 465661,
              "parentId": 465105,
              "name": "安乡县"
            },
            {
              "cityId": 465844,
              "parentId": 465105,
              "name": "汉寿县"
            },
            {
              "cityId": 466173,
              "parentId": 465105,
              "name": "澧县"
            },
            {
              "cityId": 466483,
              "parentId": 465105,
              "name": "临澧县"
            },
            {
              "cityId": 466654,
              "parentId": 465105,
              "name": "桃源县"
            },
            {
              "cityId": 467112,
              "parentId": 465105,
              "name": "石门县"
            },
            {
              "cityId": 467471,
              "parentId": 465105,
              "name": "津市市"
            }
          ]
        },
        {
          "cityId": 467562,
          "parentId": 452123,
          "name": "张家界市",
          "child": [{
              "cityId": 467563,
              "parentId": 467562,
              "name": "市辖区"
            },
            {
              "cityId": 467564,
              "parentId": 467562,
              "name": "永定区"
            },
            {
              "cityId": 467841,
              "parentId": 467562,
              "name": "武陵源区"
            },
            {
              "cityId": 467881,
              "parentId": 467562,
              "name": "慈利县"
            },
            {
              "cityId": 468338,
              "parentId": 467562,
              "name": "桑植县"
            }
          ]
        },
        {
          "cityId": 468660,
          "parentId": 452123,
          "name": "益阳市",
          "child": [{
              "cityId": 468661,
              "parentId": 468660,
              "name": "市辖区"
            },
            {
              "cityId": 468662,
              "parentId": 468660,
              "name": "资阳区"
            },
            {
              "cityId": 468790,
              "parentId": 468660,
              "name": "赫山区"
            },
            {
              "cityId": 469015,
              "parentId": 468660,
              "name": "南县"
            },
            {
              "cityId": 469192,
              "parentId": 468660,
              "name": "桃江县"
            },
            {
              "cityId": 469452,
              "parentId": 468660,
              "name": "安化县"
            },
            {
              "cityId": 469909,
              "parentId": 468660,
              "name": "沅江市"
            }
          ]
        },
        {
          "cityId": 470123,
          "parentId": 452123,
          "name": "郴州市",
          "child": [{
              "cityId": 470124,
              "parentId": 470123,
              "name": "市辖区"
            },
            {
              "cityId": 470125,
              "parentId": 470123,
              "name": "北湖区"
            },
            {
              "cityId": 470300,
              "parentId": 470123,
              "name": "苏仙区"
            },
            {
              "cityId": 470500,
              "parentId": 470123,
              "name": "桂阳县"
            },
            {
              "cityId": 470894,
              "parentId": 470123,
              "name": "宜章县"
            },
            {
              "cityId": 471178,
              "parentId": 470123,
              "name": "永兴县"
            },
            {
              "cityId": 471473,
              "parentId": 470123,
              "name": "嘉禾县"
            },
            {
              "cityId": 471685,
              "parentId": 470123,
              "name": "临武县"
            },
            {
              "cityId": 471908,
              "parentId": 470123,
              "name": "汝城县"
            },
            {
              "cityId": 472174,
              "parentId": 470123,
              "name": "桂东县"
            },
            {
              "cityId": 472301,
              "parentId": 470123,
              "name": "安仁县"
            },
            {
              "cityId": 472477,
              "parentId": 470123,
              "name": "资兴市"
            }
          ]
        },
        {
          "cityId": 472709,
          "parentId": 452123,
          "name": "永州市",
          "child": [{
              "cityId": 472710,
              "parentId": 472709,
              "name": "市辖区"
            },
            {
              "cityId": 472711,
              "parentId": 472709,
              "name": "零陵区"
            },
            {
              "cityId": 473062,
              "parentId": 472709,
              "name": "冷水滩区"
            },
            {
              "cityId": 473284,
              "parentId": 472709,
              "name": "祁阳县"
            },
            {
              "cityId": 473871,
              "parentId": 472709,
              "name": "东安县"
            },
            {
              "cityId": 474233,
              "parentId": 472709,
              "name": "双牌县"
            },
            {
              "cityId": 474367,
              "parentId": 472709,
              "name": "道县"
            },
            {
              "cityId": 474761,
              "parentId": 472709,
              "name": "江永县"
            },
            {
              "cityId": 474883,
              "parentId": 472709,
              "name": "宁远县"
            },
            {
              "cityId": 475326,
              "parentId": 472709,
              "name": "蓝山县"
            },
            {
              "cityId": 475574,
              "parentId": 472709,
              "name": "新田县"
            },
            {
              "cityId": 475819,
              "parentId": 472709,
              "name": "江华瑶族自治县"
            }
          ]
        },
        {
          "cityId": 476148,
          "parentId": 452123,
          "name": "怀化市",
          "child": [{
              "cityId": 476149,
              "parentId": 476148,
              "name": "市辖区"
            },
            {
              "cityId": 476150,
              "parentId": 476148,
              "name": "鹤城区"
            },
            {
              "cityId": 476288,
              "parentId": 476148,
              "name": "中方县"
            },
            {
              "cityId": 476449,
              "parentId": 476148,
              "name": "沅陵县"
            },
            {
              "cityId": 476874,
              "parentId": 476148,
              "name": "辰溪县"
            },
            {
              "cityId": 477191,
              "parentId": 476148,
              "name": "溆浦县"
            },
            {
              "cityId": 477633,
              "parentId": 476148,
              "name": "会同县"
            },
            {
              "cityId": 477895,
              "parentId": 476148,
              "name": "麻阳苗族自治县"
            },
            {
              "cityId": 478135,
              "parentId": 476148,
              "name": "新晃侗族自治县"
            },
            {
              "cityId": 478299,
              "parentId": 476148,
              "name": "芷江侗族自治县"
            },
            {
              "cityId": 478536,
              "parentId": 476148,
              "name": "靖州苗族侗族自治县"
            },
            {
              "cityId": 478685,
              "parentId": 476148,
              "name": "通道侗族自治县"
            },
            {
              "cityId": 478864,
              "parentId": 476148,
              "name": "洪江市"
            }
          ]
        },
        {
          "cityId": 479103,
          "parentId": 452123,
          "name": "娄底市",
          "child": [{
              "cityId": 479104,
              "parentId": 479103,
              "name": "市辖区"
            },
            {
              "cityId": 479105,
              "parentId": 479103,
              "name": "娄星区"
            },
            {
              "cityId": 479285,
              "parentId": 479103,
              "name": "双峰县"
            },
            {
              "cityId": 479889,
              "parentId": 479103,
              "name": "新化县"
            },
            {
              "cityId": 480606,
              "parentId": 479103,
              "name": "冷水江市"
            },
            {
              "cityId": 480767,
              "parentId": 479103,
              "name": "涟源市"
            }
          ]
        },
        {
          "cityId": 481319,
          "parentId": 452123,
          "name": "湘西土家族苗族自治州",
          "child": [{
              "cityId": 481320,
              "parentId": 481319,
              "name": "吉首市"
            },
            {
              "cityId": 481465,
              "parentId": 481319,
              "name": "泸溪县"
            },
            {
              "cityId": 481626,
              "parentId": 481319,
              "name": "凤凰县"
            },
            {
              "cityId": 481925,
              "parentId": 481319,
              "name": "花垣县"
            },
            {
              "cityId": 482179,
              "parentId": 481319,
              "name": "保靖县"
            },
            {
              "cityId": 482377,
              "parentId": 481319,
              "name": "古丈县"
            },
            {
              "cityId": 482506,
              "parentId": 481319,
              "name": "永顺县"
            },
            {
              "cityId": 482831,
              "parentId": 481319,
              "name": "龙山县"
            }
          ]
        }
      ]
    },
    {
      "cityId": 483250,
      "parentId": 0,
      "name": "广东省",
      "child": [{
          "cityId": 483251,
          "parentId": 483250,
          "name": "广州市",
          "child": [{
              "cityId": 483252,
              "parentId": 483251,
              "name": "市辖区"
            },
            {
              "cityId": 483253,
              "parentId": 483251,
              "name": "荔湾区"
            },
            {
              "cityId": 483462,
              "parentId": 483251,
              "name": "越秀区"
            },
            {
              "cityId": 483703,
              "parentId": 483251,
              "name": "海珠区"
            },
            {
              "cityId": 483979,
              "parentId": 483251,
              "name": "天河区"
            },
            {
              "cityId": 484218,
              "parentId": 483251,
              "name": "白云区"
            },
            {
              "cityId": 484627,
              "parentId": 483251,
              "name": "黄埔区"
            },
            {
              "cityId": 484770,
              "parentId": 483251,
              "name": "番禺区"
            },
            {
              "cityId": 485059,
              "parentId": 483251,
              "name": "花都区"
            },
            {
              "cityId": 485312,
              "parentId": 483251,
              "name": "南沙区"
            },
            {
              "cityId": 485489,
              "parentId": 483251,
              "name": "从化区"
            },
            {
              "cityId": 485776,
              "parentId": 483251,
              "name": "增城区"
            }
          ]
        },
        {
          "cityId": 486129,
          "parentId": 483250,
          "name": "韶关市",
          "child": [{
              "cityId": 486130,
              "parentId": 486129,
              "name": "市辖区"
            },
            {
              "cityId": 486131,
              "parentId": 486129,
              "name": "武江区"
            },
            {
              "cityId": 486223,
              "parentId": 486129,
              "name": "浈江区"
            },
            {
              "cityId": 486345,
              "parentId": 486129,
              "name": "曲江区"
            },
            {
              "cityId": 486469,
              "parentId": 486129,
              "name": "始兴县"
            },
            {
              "cityId": 486609,
              "parentId": 486129,
              "name": "仁化县"
            },
            {
              "cityId": 486746,
              "parentId": 486129,
              "name": "翁源县"
            },
            {
              "cityId": 486929,
              "parentId": 486129,
              "name": "乳源瑶族自治县"
            },
            {
              "cityId": 487058,
              "parentId": 486129,
              "name": "新丰县"
            },
            {
              "cityId": 487223,
              "parentId": 486129,
              "name": "乐昌市"
            },
            {
              "cityId": 487468,
              "parentId": 486129,
              "name": "南雄市"
            }
          ]
        },
        {
          "cityId": 487721,
          "parentId": 483250,
          "name": "深圳市",
          "child": [{
              "cityId": 487722,
              "parentId": 487721,
              "name": "市辖区"
            },
            {
              "cityId": 487723,
              "parentId": 487721,
              "name": "罗湖区"
            },
            {
              "cityId": 487849,
              "parentId": 487721,
              "name": "福田区"
            },
            {
              "cityId": 487977,
              "parentId": 487721,
              "name": "南山区"
            },
            {
              "cityId": 488090,
              "parentId": 487721,
              "name": "宝安区"
            },
            {
              "cityId": 488375,
              "parentId": 487721,
              "name": "龙岗区"
            },
            {
              "cityId": 488558,
              "parentId": 487721,
              "name": "盐田区"
            }
          ]
        },
        {
          "cityId": 488589,
          "parentId": 483250,
          "name": "珠海市",
          "child": [{
              "cityId": 488590,
              "parentId": 488589,
              "name": "市辖区"
            },
            {
              "cityId": 488591,
              "parentId": 488589,
              "name": "香洲区"
            },
            {
              "cityId": 488768,
              "parentId": 488589,
              "name": "斗门区"
            },
            {
              "cityId": 488905,
              "parentId": 488589,
              "name": "金湾区"
            }
          ]
        },
        {
          "cityId": 488954,
          "parentId": 483250,
          "name": "汕头市",
          "child": [{
              "cityId": 488955,
              "parentId": 488954,
              "name": "市辖区"
            },
            {
              "cityId": 488956,
              "parentId": 488954,
              "name": "龙湖区"
            },
            {
              "cityId": 489082,
              "parentId": 488954,
              "name": "金平区"
            },
            {
              "cityId": 489270,
              "parentId": 488954,
              "name": "濠江区"
            },
            {
              "cityId": 489338,
              "parentId": 488954,
              "name": "潮阳区"
            },
            {
              "cityId": 489624,
              "parentId": 488954,
              "name": "潮南区"
            },
            {
              "cityId": 489868,
              "parentId": 488954,
              "name": "澄海区"
            },
            {
              "cityId": 490064,
              "parentId": 488954,
              "name": "南澳县"
            }
          ]
        },
        {
          "cityId": 490106,
          "parentId": 483250,
          "name": "佛山市",
          "child": [{
              "cityId": 490107,
              "parentId": 490106,
              "name": "市辖区"
            },
            {
              "cityId": 490108,
              "parentId": 490106,
              "name": "禅城区"
            },
            {
              "cityId": 490257,
              "parentId": 490106,
              "name": "南海区"
            },
            {
              "cityId": 490531,
              "parentId": 490106,
              "name": "顺德区"
            },
            {
              "cityId": 490746,
              "parentId": 490106,
              "name": "三水区"
            },
            {
              "cityId": 490834,
              "parentId": 490106,
              "name": "高明区"
            }
          ]
        },
        {
          "cityId": 490919,
          "parentId": 483250,
          "name": "江门市",
          "child": [{
              "cityId": 490920,
              "parentId": 490919,
              "name": "市辖区"
            },
            {
              "cityId": 490921,
              "parentId": 490919,
              "name": "蓬江区"
            },
            {
              "cityId": 491070,
              "parentId": 490919,
              "name": "江海区"
            },
            {
              "cityId": 491136,
              "parentId": 490919,
              "name": "新会区"
            },
            {
              "cityId": 491380,
              "parentId": 490919,
              "name": "台山市"
            },
            {
              "cityId": 491711,
              "parentId": 490919,
              "name": "开平市"
            },
            {
              "cityId": 492000,
              "parentId": 490919,
              "name": "鹤山市"
            },
            {
              "cityId": 492153,
              "parentId": 490919,
              "name": "恩平市"
            }
          ]
        },
        {
          "cityId": 492341,
          "parentId": 483250,
          "name": "湛江市",
          "child": [{
              "cityId": 492342,
              "parentId": 492341,
              "name": "市辖区"
            },
            {
              "cityId": 492343,
              "parentId": 492341,
              "name": "赤坎区"
            },
            {
              "cityId": 492393,
              "parentId": 492341,
              "name": "霞山区"
            },
            {
              "cityId": 492484,
              "parentId": 492341,
              "name": "坡头区"
            },
            {
              "cityId": 492563,
              "parentId": 492341,
              "name": "麻章区"
            },
            {
              "cityId": 492716,
              "parentId": 492341,
              "name": "遂溪县"
            },
            {
              "cityId": 493015,
              "parentId": 492341,
              "name": "徐闻县"
            },
            {
              "cityId": 493241,
              "parentId": 492341,
              "name": "廉江市"
            },
            {
              "cityId": 493663,
              "parentId": 492341,
              "name": "雷州市"
            },
            {
              "cityId": 494182,
              "parentId": 492341,
              "name": "吴川市"
            }
          ]
        },
        {
          "cityId": 494394,
          "parentId": 483250,
          "name": "茂名市",
          "child": [{
              "cityId": 494395,
              "parentId": 494394,
              "name": "市辖区"
            },
            {
              "cityId": 494396,
              "parentId": 494394,
              "name": "茂南区"
            },
            {
              "cityId": 494629,
              "parentId": 494394,
              "name": "电白区"
            },
            {
              "cityId": 495088,
              "parentId": 494394,
              "name": "高州市"
            },
            {
              "cityId": 495611,
              "parentId": 494394,
              "name": "化州市"
            },
            {
              "cityId": 496016,
              "parentId": 494394,
              "name": "信宜市"
            }
          ]
        },
        {
          "cityId": 496438,
          "parentId": 483250,
          "name": "肇庆市",
          "child": [{
              "cityId": 496439,
              "parentId": 496438,
              "name": "市辖区"
            },
            {
              "cityId": 496440,
              "parentId": 496438,
              "name": "端州区"
            },
            {
              "cityId": 496505,
              "parentId": 496438,
              "name": "鼎湖区"
            },
            {
              "cityId": 496594,
              "parentId": 496438,
              "name": "高要区"
            },
            {
              "cityId": 496964,
              "parentId": 496438,
              "name": "广宁县"
            },
            {
              "cityId": 497158,
              "parentId": 496438,
              "name": "怀集县"
            },
            {
              "cityId": 497503,
              "parentId": 496438,
              "name": "封开县"
            },
            {
              "cityId": 497719,
              "parentId": 496438,
              "name": "德庆县"
            },
            {
              "cityId": 497926,
              "parentId": 496438,
              "name": "四会市"
            }
          ]
        },
        {
          "cityId": 498104,
          "parentId": 483250,
          "name": "惠州市",
          "child": [{
              "cityId": 498105,
              "parentId": 498104,
              "name": "市辖区"
            },
            {
              "cityId": 498106,
              "parentId": 498104,
              "name": "惠城区"
            },
            {
              "cityId": 498397,
              "parentId": 498104,
              "name": "惠阳区"
            },
            {
              "cityId": 498578,
              "parentId": 498104,
              "name": "博罗县"
            },
            {
              "cityId": 498978,
              "parentId": 498104,
              "name": "惠东县"
            },
            {
              "cityId": 499292,
              "parentId": 498104,
              "name": "龙门县"
            }
          ]
        },
        {
          "cityId": 499489,
          "parentId": 483250,
          "name": "梅州市",
          "child": [{
              "cityId": 499490,
              "parentId": 499489,
              "name": "市辖区"
            },
            {
              "cityId": 499491,
              "parentId": 499489,
              "name": "梅江区"
            },
            {
              "cityId": 499625,
              "parentId": 499489,
              "name": "梅县区"
            },
            {
              "cityId": 500036,
              "parentId": 499489,
              "name": "大埔县"
            },
            {
              "cityId": 500312,
              "parentId": 499489,
              "name": "丰顺县"
            },
            {
              "cityId": 500611,
              "parentId": 499489,
              "name": "五华县"
            },
            {
              "cityId": 501074,
              "parentId": 499489,
              "name": "平远县"
            },
            {
              "cityId": 501230,
              "parentId": 499489,
              "name": "蕉岭县"
            },
            {
              "cityId": 501351,
              "parentId": 499489,
              "name": "兴宁市"
            }
          ]
        },
        {
          "cityId": 501867,
          "parentId": 483250,
          "name": "汕尾市",
          "child": [{
              "cityId": 501868,
              "parentId": 501867,
              "name": "市辖区"
            },
            {
              "cityId": 501869,
              "parentId": 501867,
              "name": "城区"
            },
            {
              "cityId": 502002,
              "parentId": 501867,
              "name": "海丰县"
            },
            {
              "cityId": 502303,
              "parentId": 501867,
              "name": "陆河县"
            },
            {
              "cityId": 502439,
              "parentId": 501867,
              "name": "陆丰市"
            }
          ]
        },
        {
          "cityId": 502828,
          "parentId": 483250,
          "name": "河源市",
          "child": [{
              "cityId": 502829,
              "parentId": 502828,
              "name": "市辖区"
            },
            {
              "cityId": 502830,
              "parentId": 502828,
              "name": "源城区"
            },
            {
              "cityId": 502907,
              "parentId": 502828,
              "name": "紫金县"
            },
            {
              "cityId": 503226,
              "parentId": 502828,
              "name": "龙川县"
            },
            {
              "cityId": 503608,
              "parentId": 502828,
              "name": "连平县"
            },
            {
              "cityId": 503797,
              "parentId": 502828,
              "name": "和平县"
            },
            {
              "cityId": 504060,
              "parentId": 502828,
              "name": "东源县"
            }
          ]
        },
        {
          "cityId": 504366,
          "parentId": 483250,
          "name": "阳江市",
          "child": [{
              "cityId": 504367,
              "parentId": 504366,
              "name": "市辖区"
            },
            {
              "cityId": 504368,
              "parentId": 504366,
              "name": "江城区"
            },
            {
              "cityId": 504549,
              "parentId": 504366,
              "name": "阳东区"
            },
            {
              "cityId": 504749,
              "parentId": 504366,
              "name": "阳西县"
            },
            {
              "cityId": 504911,
              "parentId": 504366,
              "name": "阳春市"
            }
          ]
        },
        {
          "cityId": 505298,
          "parentId": 483250,
          "name": "清远市",
          "child": [{
              "cityId": 505299,
              "parentId": 505298,
              "name": "市辖区"
            },
            {
              "cityId": 505300,
              "parentId": 505298,
              "name": "清城区"
            },
            {
              "cityId": 505466,
              "parentId": 505298,
              "name": "清新区"
            },
            {
              "cityId": 505685,
              "parentId": 505298,
              "name": "佛冈县"
            },
            {
              "cityId": 505782,
              "parentId": 505298,
              "name": "阳山县"
            },
            {
              "cityId": 505963,
              "parentId": 505298,
              "name": "连山壮族瑶族自治县"
            },
            {
              "cityId": 506027,
              "parentId": 505298,
              "name": "连南瑶族自治县"
            },
            {
              "cityId": 506106,
              "parentId": 505298,
              "name": "英德市"
            },
            {
              "cityId": 506430,
              "parentId": 505298,
              "name": "连州市"
            }
          ]
        },
        {
          "cityId": 506616,
          "parentId": 483250,
          "name": "东莞市",
          "child": [{
              "cityId": 506617,
              "parentId": 506616,
              "name": "东城街道办事处"
            },
            {
              "cityId": 506643,
              "parentId": 506616,
              "name": "南城街道办事处"
            },
            {
              "cityId": 506662,
              "parentId": 506616,
              "name": "万江街道办事处"
            },
            {
              "cityId": 506691,
              "parentId": 506616,
              "name": "莞城街道办事处"
            },
            {
              "cityId": 506700,
              "parentId": 506616,
              "name": "石碣镇"
            },
            {
              "cityId": 506716,
              "parentId": 506616,
              "name": "石龙镇"
            },
            {
              "cityId": 506727,
              "parentId": 506616,
              "name": "茶山镇"
            },
            {
              "cityId": 506746,
              "parentId": 506616,
              "name": "石排镇"
            },
            {
              "cityId": 506766,
              "parentId": 506616,
              "name": "企石镇"
            },
            {
              "cityId": 506787,
              "parentId": 506616,
              "name": "横沥镇"
            },
            {
              "cityId": 506805,
              "parentId": 506616,
              "name": "桥头镇"
            },
            {
              "cityId": 506823,
              "parentId": 506616,
              "name": "谢岗镇"
            },
            {
              "cityId": 506836,
              "parentId": 506616,
              "name": "东坑镇"
            },
            {
              "cityId": 506853,
              "parentId": 506616,
              "name": "常平镇"
            },
            {
              "cityId": 506887,
              "parentId": 506616,
              "name": "寮步镇"
            },
            {
              "cityId": 506918,
              "parentId": 506616,
              "name": "樟木头镇"
            },
            {
              "cityId": 506930,
              "parentId": 506616,
              "name": "大朗镇"
            },
            {
              "cityId": 506959,
              "parentId": 506616,
              "name": "黄江镇"
            },
            {
              "cityId": 506967,
              "parentId": 506616,
              "name": "清溪镇"
            },
            {
              "cityId": 506990,
              "parentId": 506616,
              "name": "塘厦镇"
            },
            {
              "cityId": 507015,
              "parentId": 506616,
              "name": "凤岗镇"
            },
            {
              "cityId": 507028,
              "parentId": 506616,
              "name": "大岭山镇"
            },
            {
              "cityId": 507052,
              "parentId": 506616,
              "name": "长安镇"
            },
            {
              "cityId": 507066,
              "parentId": 506616,
              "name": "虎门镇"
            },
            {
              "cityId": 507099,
              "parentId": 506616,
              "name": "厚街镇"
            },
            {
              "cityId": 507124,
              "parentId": 506616,
              "name": "沙田镇"
            },
            {
              "cityId": 507143,
              "parentId": 506616,
              "name": "道滘镇"
            },
            {
              "cityId": 507158,
              "parentId": 506616,
              "name": "洪梅镇"
            },
            {
              "cityId": 507169,
              "parentId": 506616,
              "name": "麻涌镇"
            },
            {
              "cityId": 507185,
              "parentId": 506616,
              "name": "望牛墩镇"
            },
            {
              "cityId": 507208,
              "parentId": 506616,
              "name": "中堂镇"
            },
            {
              "cityId": 507229,
              "parentId": 506616,
              "name": "高埗镇"
            },
            {
              "cityId": 507249,
              "parentId": 506616,
              "name": "松山湖管委会"
            },
            {
              "cityId": 507251,
              "parentId": 506616,
              "name": "虎门港管委会"
            },
            {
              "cityId": 507253,
              "parentId": 506616,
              "name": "东莞生态园"
            }
          ]
        },
        {
          "cityId": 507255,
          "parentId": 483250,
          "name": "中山市",
          "child": [{
              "cityId": 507256,
              "parentId": 507255,
              "name": "石岐区街道办事处"
            },
            {
              "cityId": 507276,
              "parentId": 507255,
              "name": "东区街道办事处"
            },
            {
              "cityId": 507287,
              "parentId": 507255,
              "name": "火炬开发区街道办事处"
            },
            {
              "cityId": 507295,
              "parentId": 507255,
              "name": "西区街道办事处"
            },
            {
              "cityId": 507305,
              "parentId": 507255,
              "name": "南区街道办事处"
            },
            {
              "cityId": 507310,
              "parentId": 507255,
              "name": "五桂山街道办事处"
            },
            {
              "cityId": 507316,
              "parentId": 507255,
              "name": "小榄镇"
            },
            {
              "cityId": 507332,
              "parentId": 507255,
              "name": "黄圃镇"
            },
            {
              "cityId": 507349,
              "parentId": 507255,
              "name": "民众镇"
            },
            {
              "cityId": 507369,
              "parentId": 507255,
              "name": "东凤镇"
            },
            {
              "cityId": 507384,
              "parentId": 507255,
              "name": "东升镇"
            },
            {
              "cityId": 507399,
              "parentId": 507255,
              "name": "古镇镇"
            },
            {
              "cityId": 507413,
              "parentId": 507255,
              "name": "沙溪镇"
            },
            {
              "cityId": 507430,
              "parentId": 507255,
              "name": "坦洲镇"
            },
            {
              "cityId": 507445,
              "parentId": 507255,
              "name": "港口镇"
            },
            {
              "cityId": 507455,
              "parentId": 507255,
              "name": "三角镇"
            },
            {
              "cityId": 507464,
              "parentId": 507255,
              "name": "横栏镇"
            },
            {
              "cityId": 507476,
              "parentId": 507255,
              "name": "南头镇"
            },
            {
              "cityId": 507483,
              "parentId": 507255,
              "name": "阜沙镇"
            },
            {
              "cityId": 507493,
              "parentId": 507255,
              "name": "南朗镇"
            },
            {
              "cityId": 507509,
              "parentId": 507255,
              "name": "三乡镇"
            },
            {
              "cityId": 507526,
              "parentId": 507255,
              "name": "板芙镇"
            },
            {
              "cityId": 507538,
              "parentId": 507255,
              "name": "大涌镇"
            },
            {
              "cityId": 507550,
              "parentId": 507255,
              "name": "神湾镇"
            }
          ]
        },
        {
          "cityId": 507557,
          "parentId": 483250,
          "name": "潮州市",
          "child": [{
              "cityId": 507558,
              "parentId": 507557,
              "name": "市辖区"
            },
            {
              "cityId": 507559,
              "parentId": 507557,
              "name": "湘桥区"
            },
            {
              "cityId": 507748,
              "parentId": 507557,
              "name": "潮安区"
            },
            {
              "cityId": 508223,
              "parentId": 507557,
              "name": "饶平县"
            }
          ]
        },
        {
          "cityId": 508648,
          "parentId": 483250,
          "name": "揭阳市",
          "child": [{
              "cityId": 508649,
              "parentId": 508648,
              "name": "市辖区"
            },
            {
              "cityId": 508650,
              "parentId": 508648,
              "name": "榕城区"
            },
            {
              "cityId": 508863,
              "parentId": 508648,
              "name": "揭东区"
            },
            {
              "cityId": 509101,
              "parentId": 508648,
              "name": "揭西县"
            },
            {
              "cityId": 509449,
              "parentId": 508648,
              "name": "惠来县"
            },
            {
              "cityId": 509796,
              "parentId": 508648,
              "name": "普宁市"
            }
          ]
        },
        {
          "cityId": 510408,
          "parentId": 483250,
          "name": "云浮市",
          "child": [{
              "cityId": 510409,
              "parentId": 510408,
              "name": "市辖区"
            },
            {
              "cityId": 510410,
              "parentId": 510408,
              "name": "云城区"
            },
            {
              "cityId": 510536,
              "parentId": 510408,
              "name": "云安区"
            },
            {
              "cityId": 510660,
              "parentId": 510408,
              "name": "新兴县"
            },
            {
              "cityId": 510878,
              "parentId": 510408,
              "name": "郁南县"
            },
            {
              "cityId": 511102,
              "parentId": 510408,
              "name": "罗定市"
            }
          ]
        }
      ]
    },
    {
      "cityId": 511462,
      "parentId": 0,
      "name": "广西壮族自治区",
      "child": [{
          "cityId": 511463,
          "parentId": 511462,
          "name": "南宁市",
          "child": [{
              "cityId": 511464,
              "parentId": 511463,
              "name": "市辖区"
            },
            {
              "cityId": 511465,
              "parentId": 511463,
              "name": "兴宁区"
            },
            {
              "cityId": 511545,
              "parentId": 511463,
              "name": "青秀区"
            },
            {
              "cityId": 511662,
              "parentId": 511463,
              "name": "江南区"
            },
            {
              "cityId": 511790,
              "parentId": 511463,
              "name": "西乡塘区"
            },
            {
              "cityId": 511960,
              "parentId": 511463,
              "name": "良庆区"
            },
            {
              "cityId": 512044,
              "parentId": 511463,
              "name": "邕宁区"
            },
            {
              "cityId": 512124,
              "parentId": 511463,
              "name": "武鸣区"
            },
            {
              "cityId": 512381,
              "parentId": 511463,
              "name": "隆安县"
            },
            {
              "cityId": 512530,
              "parentId": 511463,
              "name": "马山县"
            },
            {
              "cityId": 512695,
              "parentId": 511463,
              "name": "上林县"
            },
            {
              "cityId": 512838,
              "parentId": 511463,
              "name": "宾阳县"
            },
            {
              "cityId": 513091,
              "parentId": 511463,
              "name": "横县"
            }
          ]
        },
        {
          "cityId": 513434,
          "parentId": 511462,
          "name": "柳州市",
          "child": [{
              "cityId": 513435,
              "parentId": 513434,
              "name": "市辖区"
            },
            {
              "cityId": 513436,
              "parentId": 513434,
              "name": "城中区"
            },
            {
              "cityId": 513477,
              "parentId": 513434,
              "name": "鱼峰区"
            },
            {
              "cityId": 513569,
              "parentId": 513434,
              "name": "柳南区"
            },
            {
              "cityId": 513661,
              "parentId": 513434,
              "name": "柳北区"
            },
            {
              "cityId": 513772,
              "parentId": 513434,
              "name": "柳江区"
            },
            {
              "cityId": 513938,
              "parentId": 513434,
              "name": "柳城县"
            },
            {
              "cityId": 514091,
              "parentId": 513434,
              "name": "鹿寨县"
            },
            {
              "cityId": 514219,
              "parentId": 513434,
              "name": "融安县"
            },
            {
              "cityId": 514380,
              "parentId": 513434,
              "name": "融水苗族自治县"
            },
            {
              "cityId": 514607,
              "parentId": 513434,
              "name": "三江侗族自治县"
            }
          ]
        },
        {
          "cityId": 514790,
          "parentId": 511462,
          "name": "桂林市",
          "child": [{
              "cityId": 514791,
              "parentId": 514790,
              "name": "市辖区"
            },
            {
              "cityId": 514792,
              "parentId": 514790,
              "name": "秀峰区"
            },
            {
              "cityId": 514824,
              "parentId": 514790,
              "name": "叠彩区"
            },
            {
              "cityId": 514864,
              "parentId": 514790,
              "name": "象山区"
            },
            {
              "cityId": 514911,
              "parentId": 514790,
              "name": "七星区"
            },
            {
              "cityId": 514964,
              "parentId": 514790,
              "name": "雁山区"
            },
            {
              "cityId": 515012,
              "parentId": 514790,
              "name": "临桂区"
            },
            {
              "cityId": 515195,
              "parentId": 514790,
              "name": "阳朔县"
            },
            {
              "cityId": 515319,
              "parentId": 514790,
              "name": "灵川县"
            },
            {
              "cityId": 515481,
              "parentId": 514790,
              "name": "全州县"
            },
            {
              "cityId": 515784,
              "parentId": 514790,
              "name": "兴安县"
            },
            {
              "cityId": 515920,
              "parentId": 514790,
              "name": "永福县"
            },
            {
              "cityId": 516029,
              "parentId": 514790,
              "name": "灌阳县"
            },
            {
              "cityId": 516180,
              "parentId": 514790,
              "name": "龙胜各族自治县"
            },
            {
              "cityId": 516317,
              "parentId": 514790,
              "name": "资源县"
            },
            {
              "cityId": 516399,
              "parentId": 514790,
              "name": "平乐县"
            },
            {
              "cityId": 516563,
              "parentId": 514790,
              "name": "荔浦县"
            },
            {
              "cityId": 516721,
              "parentId": 514790,
              "name": "恭城瑶族自治县"
            }
          ]
        },
        {
          "cityId": 516858,
          "parentId": 511462,
          "name": "梧州市",
          "child": [{
              "cityId": 516859,
              "parentId": 516858,
              "name": "市辖区"
            },
            {
              "cityId": 516860,
              "parentId": 516858,
              "name": "万秀区"
            },
            {
              "cityId": 516945,
              "parentId": 516858,
              "name": "长洲区"
            },
            {
              "cityId": 516991,
              "parentId": 516858,
              "name": "龙圩区"
            },
            {
              "cityId": 517075,
              "parentId": 516858,
              "name": "苍梧县"
            },
            {
              "cityId": 517230,
              "parentId": 516858,
              "name": "藤县"
            },
            {
              "cityId": 517542,
              "parentId": 516858,
              "name": "蒙山县"
            },
            {
              "cityId": 517636,
              "parentId": 516858,
              "name": "岑溪市"
            }
          ]
        },
        {
          "cityId": 517933,
          "parentId": 511462,
          "name": "北海市",
          "child": [{
              "cityId": 517934,
              "parentId": 517933,
              "name": "市辖区"
            },
            {
              "cityId": 517935,
              "parentId": 517933,
              "name": "海城区"
            },
            {
              "cityId": 518010,
              "parentId": 517933,
              "name": "银海区"
            },
            {
              "cityId": 518067,
              "parentId": 517933,
              "name": "铁山港区"
            },
            {
              "cityId": 518115,
              "parentId": 517933,
              "name": "合浦县"
            }
          ]
        },
        {
          "cityId": 518407,
          "parentId": 511462,
          "name": "防城港市",
          "child": [{
              "cityId": 518408,
              "parentId": 518407,
              "name": "市辖区"
            },
            {
              "cityId": 518409,
              "parentId": 518407,
              "name": "港口区"
            },
            {
              "cityId": 518455,
              "parentId": 518407,
              "name": "防城区"
            },
            {
              "cityId": 518641,
              "parentId": 518407,
              "name": "上思县"
            },
            {
              "cityId": 518762,
              "parentId": 518407,
              "name": "东兴市"
            }
          ]
        },
        {
          "cityId": 518808,
          "parentId": 511462,
          "name": "钦州市",
          "child": [{
              "cityId": 518809,
              "parentId": 518808,
              "name": "市辖区"
            },
            {
              "cityId": 518810,
              "parentId": 518808,
              "name": "钦南区"
            },
            {
              "cityId": 519003,
              "parentId": 518808,
              "name": "钦北区"
            },
            {
              "cityId": 519201,
              "parentId": 518808,
              "name": "灵山县"
            },
            {
              "cityId": 519637,
              "parentId": 518808,
              "name": "浦北县"
            }
          ]
        },
        {
          "cityId": 519929,
          "parentId": 511462,
          "name": "贵港市",
          "child": [{
              "cityId": 519930,
              "parentId": 519929,
              "name": "市辖区"
            },
            {
              "cityId": 519931,
              "parentId": 519929,
              "name": "港北区"
            },
            {
              "cityId": 520063,
              "parentId": 519929,
              "name": "港南区"
            },
            {
              "cityId": 520240,
              "parentId": 519929,
              "name": "覃塘区"
            },
            {
              "cityId": 520397,
              "parentId": 519929,
              "name": "平南县"
            },
            {
              "cityId": 520707,
              "parentId": 519929,
              "name": "桂平市"
            }
          ]
        },
        {
          "cityId": 521166,
          "parentId": 511462,
          "name": "玉林市",
          "child": [{
              "cityId": 521167,
              "parentId": 521166,
              "name": "市辖区"
            },
            {
              "cityId": 521168,
              "parentId": 521166,
              "name": "玉州区"
            },
            {
              "cityId": 521286,
              "parentId": 521166,
              "name": "福绵区"
            },
            {
              "cityId": 521409,
              "parentId": 521166,
              "name": "容县"
            },
            {
              "cityId": 521652,
              "parentId": 521166,
              "name": "陆川县"
            },
            {
              "cityId": 521832,
              "parentId": 521166,
              "name": "博白县"
            },
            {
              "cityId": 522220,
              "parentId": 521166,
              "name": "兴业县"
            },
            {
              "cityId": 522448,
              "parentId": 521166,
              "name": "北流市"
            }
          ]
        },
        {
          "cityId": 522786,
          "parentId": 511462,
          "name": "百色市",
          "child": [{
              "cityId": 522787,
              "parentId": 522786,
              "name": "市辖区"
            },
            {
              "cityId": 522788,
              "parentId": 522786,
              "name": "右江区"
            },
            {
              "cityId": 522918,
              "parentId": 522786,
              "name": "田阳县"
            },
            {
              "cityId": 523086,
              "parentId": 522786,
              "name": "田东县"
            },
            {
              "cityId": 523265,
              "parentId": 522786,
              "name": "平果县"
            },
            {
              "cityId": 523461,
              "parentId": 522786,
              "name": "德保县"
            },
            {
              "cityId": 523661,
              "parentId": 522786,
              "name": "那坡县"
            },
            {
              "cityId": 523801,
              "parentId": 522786,
              "name": "凌云县"
            },
            {
              "cityId": 523920,
              "parentId": 522786,
              "name": "乐业县"
            },
            {
              "cityId": 524017,
              "parentId": 522786,
              "name": "田林县"
            },
            {
              "cityId": 524200,
              "parentId": 522786,
              "name": "西林县"
            },
            {
              "cityId": 524306,
              "parentId": 522786,
              "name": "隆林各族自治县"
            },
            {
              "cityId": 524502,
              "parentId": 522786,
              "name": "靖西市"
            }
          ]
        },
        {
          "cityId": 524814,
          "parentId": 511462,
          "name": "贺州市",
          "child": [{
              "cityId": 524815,
              "parentId": 524814,
              "name": "市辖区"
            },
            {
              "cityId": 524816,
              "parentId": 524814,
              "name": "八步区"
            },
            {
              "cityId": 525035,
              "parentId": 524814,
              "name": "平桂区"
            },
            {
              "cityId": 525169,
              "parentId": 524814,
              "name": "昭平县"
            },
            {
              "cityId": 525341,
              "parentId": 524814,
              "name": "钟山县"
            },
            {
              "cityId": 525470,
              "parentId": 524814,
              "name": "富川瑶族自治县"
            }
          ]
        },
        {
          "cityId": 525638,
          "parentId": 511462,
          "name": "河池市",
          "child": [{
              "cityId": 525639,
              "parentId": 525638,
              "name": "市辖区"
            },
            {
              "cityId": 525640,
              "parentId": 525638,
              "name": "金城江区"
            },
            {
              "cityId": 525798,
              "parentId": 525638,
              "name": "南丹县"
            },
            {
              "cityId": 525961,
              "parentId": 525638,
              "name": "天峨县"
            },
            {
              "cityId": 526066,
              "parentId": 525638,
              "name": "凤山县"
            },
            {
              "cityId": 526174,
              "parentId": 525638,
              "name": "东兰县"
            },
            {
              "cityId": 526338,
              "parentId": 525638,
              "name": "罗城仫佬族自治县"
            },
            {
              "cityId": 526493,
              "parentId": 525638,
              "name": "环江毛南族自治县"
            },
            {
              "cityId": 526654,
              "parentId": 525638,
              "name": "巴马瑶族自治县"
            },
            {
              "cityId": 526772,
              "parentId": 525638,
              "name": "都安瑶族自治县"
            },
            {
              "cityId": 527041,
              "parentId": 525638,
              "name": "大化瑶族自治县"
            },
            {
              "cityId": 527216,
              "parentId": 525638,
              "name": "宜州市"
            }
          ]
        },
        {
          "cityId": 527443,
          "parentId": 511462,
          "name": "来宾市",
          "child": [{
              "cityId": 527444,
              "parentId": 527443,
              "name": "市辖区"
            },
            {
              "cityId": 527445,
              "parentId": 527443,
              "name": "兴宾区"
            },
            {
              "cityId": 527762,
              "parentId": 527443,
              "name": "忻城县"
            },
            {
              "cityId": 527905,
              "parentId": 527443,
              "name": "象州县"
            },
            {
              "cityId": 528042,
              "parentId": 527443,
              "name": "武宣县"
            },
            {
              "cityId": 528205,
              "parentId": 527443,
              "name": "金秀瑶族自治县"
            },
            {
              "cityId": 528299,
              "parentId": 527443,
              "name": "合山市"
            }
          ]
        },
        {
          "cityId": 528339,
          "parentId": 511462,
          "name": "崇左市",
          "child": [{
              "cityId": 528340,
              "parentId": 528339,
              "name": "市辖区"
            },
            {
              "cityId": 528341,
              "parentId": 528339,
              "name": "江州区"
            },
            {
              "cityId": 528494,
              "parentId": 528339,
              "name": "扶绥县"
            },
            {
              "cityId": 528647,
              "parentId": 528339,
              "name": "宁明县"
            },
            {
              "cityId": 528845,
              "parentId": 528339,
              "name": "龙州县"
            },
            {
              "cityId": 528986,
              "parentId": 528339,
              "name": "大新县"
            },
            {
              "cityId": 529159,
              "parentId": 528339,
              "name": "天等县"
            },
            {
              "cityId": 529297,
              "parentId": 528339,
              "name": "凭祥市"
            }
          ]
        }
      ]
    },
    {
      "cityId": 529344,
      "parentId": 0,
      "name": "海南省",
      "child": [{
          "cityId": 529345,
          "parentId": 529344,
          "name": "海口市",
          "child": [{
              "cityId": 529346,
              "parentId": 529345,
              "name": "市辖区"
            },
            {
              "cityId": 529347,
              "parentId": 529345,
              "name": "秀英区"
            },
            {
              "cityId": 529451,
              "parentId": 529345,
              "name": "龙华区"
            },
            {
              "cityId": 529589,
              "parentId": 529345,
              "name": "琼山区"
            },
            {
              "cityId": 529720,
              "parentId": 529345,
              "name": "美兰区"
            }
          ]
        },
        {
          "cityId": 529878,
          "parentId": 529344,
          "name": "三亚市",
          "child": [{
              "cityId": 529879,
              "parentId": 529878,
              "name": "市辖区"
            },
            {
              "cityId": 529910,
              "parentId": 529878,
              "name": "海棠区"
            },
            {
              "cityId": 529934,
              "parentId": 529878,
              "name": "吉阳区"
            },
            {
              "cityId": 529973,
              "parentId": 529878,
              "name": "天涯区"
            },
            {
              "cityId": 530027,
              "parentId": 529878,
              "name": "崖州区"
            }
          ]
        },
        {
          "cityId": 530060,
          "parentId": 529344,
          "name": "三沙市",
          "child": [{
              "cityId": 530061,
              "parentId": 530060,
              "name": "西沙群岛"
            },
            {
              "cityId": 530064,
              "parentId": 530060,
              "name": "南沙群岛"
            },
            {
              "cityId": 530067,
              "parentId": 530060,
              "name": "中沙群岛的岛礁及其海域"
            }
          ]
        },
        {
          "cityId": 530070,
          "parentId": 529344,
          "name": "儋州市",
          "child": [{
              "cityId": 530071,
              "parentId": 530070,
              "name": "那大镇"
            },
            {
              "cityId": 530112,
              "parentId": 530070,
              "name": "和庆镇"
            },
            {
              "cityId": 530126,
              "parentId": 530070,
              "name": "南丰镇"
            },
            {
              "cityId": 530139,
              "parentId": 530070,
              "name": "大成镇"
            },
            {
              "cityId": 530162,
              "parentId": 530070,
              "name": "雅星镇"
            },
            {
              "cityId": 530188,
              "parentId": 530070,
              "name": "兰洋镇"
            },
            {
              "cityId": 530207,
              "parentId": 530070,
              "name": "光村镇"
            },
            {
              "cityId": 530220,
              "parentId": 530070,
              "name": "木棠镇"
            },
            {
              "cityId": 530246,
              "parentId": 530070,
              "name": "海头镇"
            },
            {
              "cityId": 530261,
              "parentId": 530070,
              "name": "峨蔓镇"
            },
            {
              "cityId": 530275,
              "parentId": 530070,
              "name": "三都镇"
            },
            {
              "cityId": 530285,
              "parentId": 530070,
              "name": "王五镇"
            },
            {
              "cityId": 530295,
              "parentId": 530070,
              "name": "白马井镇"
            },
            {
              "cityId": 530318,
              "parentId": 530070,
              "name": "中和镇"
            },
            {
              "cityId": 530331,
              "parentId": 530070,
              "name": "排浦镇"
            },
            {
              "cityId": 530340,
              "parentId": 530070,
              "name": "东成镇"
            },
            {
              "cityId": 530361,
              "parentId": 530070,
              "name": "新州镇"
            },
            {
              "cityId": 530394,
              "parentId": 530070,
              "name": "国营西培农场"
            },
            {
              "cityId": 530410,
              "parentId": 530070,
              "name": "国营西联农场"
            },
            {
              "cityId": 530431,
              "parentId": 530070,
              "name": "国营蓝洋农场"
            },
            {
              "cityId": 530437,
              "parentId": 530070,
              "name": "国营八一农场"
            },
            {
              "cityId": 530447,
              "parentId": 530070,
              "name": "洋浦经济开发区"
            },
            {
              "cityId": 530466,
              "parentId": 530070,
              "name": "华南热作学院"
            }
          ]
        },
        {
          "cityId": 530468,
          "parentId": 529344,
          "name": "省直辖县级行政区域",
          "child": [{
              "cityId": 530469,
              "parentId": 530468,
              "name": "五指山市"
            },
            {
              "cityId": 530552,
              "parentId": 530468,
              "name": "琼海市"
            },
            {
              "cityId": 530809,
              "parentId": 530468,
              "name": "文昌市"
            },
            {
              "cityId": 531148,
              "parentId": 530468,
              "name": "万宁市"
            },
            {
              "cityId": 531426,
              "parentId": 530468,
              "name": "东方市"
            },
            {
              "cityId": 531647,
              "parentId": 530468,
              "name": "定安县"
            },
            {
              "cityId": 531811,
              "parentId": 530468,
              "name": "屯昌县"
            },
            {
              "cityId": 531971,
              "parentId": 530468,
              "name": "澄迈县"
            },
            {
              "cityId": 532200,
              "parentId": 530468,
              "name": "临高县"
            },
            {
              "cityId": 532409,
              "parentId": 530468,
              "name": "白沙黎族自治县"
            },
            {
              "cityId": 532558,
              "parentId": 530468,
              "name": "昌江黎族自治县"
            },
            {
              "cityId": 532672,
              "parentId": 530468,
              "name": "乐东黎族自治县"
            },
            {
              "cityId": 532906,
              "parentId": 530468,
              "name": "陵水黎族自治县"
            },
            {
              "cityId": 533053,
              "parentId": 530468,
              "name": "保亭黎族苗族自治县"
            },
            {
              "cityId": 533161,
              "parentId": 530468,
              "name": "琼中黎族苗族自治县"
            }
          ]
        }
      ]
    },
    {
      "cityId": 533328,
      "parentId": 0,
      "name": "重庆市",
      "child": [{
          "cityId": 533329,
          "parentId": 533328,
          "name": "重庆市",
          "child": [{
              "cityId": 533330,
              "parentId": 533329,
              "name": "万州区"
            },
            {
              "cityId": 534019,
              "parentId": 533329,
              "name": "涪陵区"
            },
            {
              "cityId": 534468,
              "parentId": 533329,
              "name": "渝中区"
            },
            {
              "cityId": 534558,
              "parentId": 533329,
              "name": "大渡口区"
            },
            {
              "cityId": 534656,
              "parentId": 533329,
              "name": "江北区"
            },
            {
              "cityId": 534799,
              "parentId": 533329,
              "name": "沙坪坝区"
            },
            {
              "cityId": 535021,
              "parentId": 533329,
              "name": "九龙坡区"
            },
            {
              "cityId": 535262,
              "parentId": 533329,
              "name": "南岸区"
            },
            {
              "cityId": 535428,
              "parentId": 533329,
              "name": "北碚区"
            },
            {
              "cityId": 535629,
              "parentId": 533329,
              "name": "綦江区"
            },
            {
              "cityId": 536131,
              "parentId": 533329,
              "name": "大足区"
            },
            {
              "cityId": 536468,
              "parentId": 533329,
              "name": "渝北区"
            },
            {
              "cityId": 536890,
              "parentId": 533329,
              "name": "巴南区"
            },
            {
              "cityId": 537212,
              "parentId": 533329,
              "name": "黔江区"
            },
            {
              "cityId": 537461,
              "parentId": 533329,
              "name": "长寿区"
            },
            {
              "cityId": 537745,
              "parentId": 533329,
              "name": "江津区"
            },
            {
              "cityId": 538050,
              "parentId": 533329,
              "name": "合川区"
            },
            {
              "cityId": 538493,
              "parentId": 533329,
              "name": "永川区"
            },
            {
              "cityId": 538778,
              "parentId": 533329,
              "name": "南川区"
            },
            {
              "cityId": 539057,
              "parentId": 533329,
              "name": "璧山区"
            },
            {
              "cityId": 539260,
              "parentId": 533329,
              "name": "铜梁区"
            },
            {
              "cityId": 539615,
              "parentId": 533329,
              "name": "潼南区"
            },
            {
              "cityId": 539942,
              "parentId": 533329,
              "name": "荣昌区"
            },
            {
              "cityId": 540131,
              "parentId": 533329,
              "name": "开州区"
            }
          ]
        },
        {
          "cityId": 540700,
          "parentId": 533328,
          "name": "县",
          "child": [{
              "cityId": 540701,
              "parentId": 540700,
              "name": "梁平县"
            },
            {
              "cityId": 541080,
              "parentId": 540700,
              "name": "城口县"
            },
            {
              "cityId": 541310,
              "parentId": 540700,
              "name": "丰都县"
            },
            {
              "cityId": 541671,
              "parentId": 540700,
              "name": "垫江县"
            },
            {
              "cityId": 541999,
              "parentId": 540700,
              "name": "武隆县"
            },
            {
              "cityId": 542236,
              "parentId": 540700,
              "name": "忠县"
            },
            {
              "cityId": 542631,
              "parentId": 540700,
              "name": "云阳县"
            },
            {
              "cityId": 543152,
              "parentId": 540700,
              "name": "奉节县"
            },
            {
              "cityId": 543575,
              "parentId": 540700,
              "name": "巫山县"
            },
            {
              "cityId": 543943,
              "parentId": 540700,
              "name": "巫溪县"
            },
            {
              "cityId": 544307,
              "parentId": 540700,
              "name": "石柱土家族自治县"
            },
            {
              "cityId": 544583,
              "parentId": 540700,
              "name": "秀山土家族苗族自治县"
            },
            {
              "cityId": 544878,
              "parentId": 540700,
              "name": "酉阳土家族苗族自治县"
            },
            {
              "cityId": 545196,
              "parentId": 540700,
              "name": "彭水苗族土家族自治县"
            }
          ]
        }
      ]
    },
    {
      "cityId": 545532,
      "parentId": 0,
      "name": "四川省",
      "child": [{
          "cityId": 545533,
          "parentId": 545532,
          "name": "成都市",
          "child": [{
              "cityId": 545534,
              "parentId": 545533,
              "name": "市辖区"
            },
            {
              "cityId": 545535,
              "parentId": 545533,
              "name": "锦江区"
            },
            {
              "cityId": 545669,
              "parentId": 545533,
              "name": "青羊区"
            },
            {
              "cityId": 545763,
              "parentId": 545533,
              "name": "金牛区"
            },
            {
              "cityId": 545889,
              "parentId": 545533,
              "name": "武侯区"
            },
            {
              "cityId": 546035,
              "parentId": 545533,
              "name": "成华区"
            },
            {
              "cityId": 546151,
              "parentId": 545533,
              "name": "龙泉驿区"
            },
            {
              "cityId": 546306,
              "parentId": 545533,
              "name": "青白江区"
            },
            {
              "cityId": 546441,
              "parentId": 545533,
              "name": "新都区"
            },
            {
              "cityId": 546710,
              "parentId": 545533,
              "name": "温江区"
            },
            {
              "cityId": 546835,
              "parentId": 545533,
              "name": "双流区"
            },
            {
              "cityId": 547144,
              "parentId": 545533,
              "name": "金堂县"
            },
            {
              "cityId": 547398,
              "parentId": 545533,
              "name": "郫县"
            },
            {
              "cityId": 547634,
              "parentId": 545533,
              "name": "大邑县"
            },
            {
              "cityId": 547873,
              "parentId": 545533,
              "name": "蒲江县"
            },
            {
              "cityId": 548018,
              "parentId": 545533,
              "name": "新津县"
            },
            {
              "cityId": 548137,
              "parentId": 545533,
              "name": "都江堰市"
            },
            {
              "cityId": 548412,
              "parentId": 545533,
              "name": "彭州市"
            },
            {
              "cityId": 548788,
              "parentId": 545533,
              "name": "邛崃市"
            },
            {
              "cityId": 549084,
              "parentId": 545533,
              "name": "崇州市"
            },
            {
              "cityId": 549363,
              "parentId": 545533,
              "name": "简阳市"
            }
          ]
        },
        {
          "cityId": 550275,
          "parentId": 545532,
          "name": "自贡市",
          "child": [{
              "cityId": 550276,
              "parentId": 550275,
              "name": "市辖区"
            },
            {
              "cityId": 550277,
              "parentId": 550275,
              "name": "自流井区"
            },
            {
              "cityId": 550413,
              "parentId": 550275,
              "name": "贡井区"
            },
            {
              "cityId": 550609,
              "parentId": 550275,
              "name": "大安区"
            },
            {
              "cityId": 550816,
              "parentId": 550275,
              "name": "沿滩区"
            },
            {
              "cityId": 551023,
              "parentId": 550275,
              "name": "荣县"
            },
            {
              "cityId": 551398,
              "parentId": 550275,
              "name": "富顺县"
            }
          ]
        },
        {
          "cityId": 551809,
          "parentId": 545532,
          "name": "攀枝花市",
          "child": [{
              "cityId": 551810,
              "parentId": 551809,
              "name": "市辖区"
            },
            {
              "cityId": 551811,
              "parentId": 551809,
              "name": "东区"
            },
            {
              "cityId": 551891,
              "parentId": 551809,
              "name": "西区"
            },
            {
              "cityId": 551941,
              "parentId": 551809,
              "name": "仁和区"
            },
            {
              "cityId": 552057,
              "parentId": 551809,
              "name": "米易县"
            },
            {
              "cityId": 552169,
              "parentId": 551809,
              "name": "盐边县"
            }
          ]
        },
        {
          "cityId": 552358,
          "parentId": 545532,
          "name": "泸州市",
          "child": [{
              "cityId": 552359,
              "parentId": 552358,
              "name": "市辖区"
            },
            {
              "cityId": 552360,
              "parentId": 552358,
              "name": "江阳区"
            },
            {
              "cityId": 552536,
              "parentId": 552358,
              "name": "纳溪区"
            },
            {
              "cityId": 552755,
              "parentId": 552358,
              "name": "龙马潭区"
            },
            {
              "cityId": 552858,
              "parentId": 552358,
              "name": "泸县"
            },
            {
              "cityId": 553181,
              "parentId": 552358,
              "name": "合江县"
            },
            {
              "cityId": 553536,
              "parentId": 552358,
              "name": "叙永县"
            },
            {
              "cityId": 553823,
              "parentId": 552358,
              "name": "古蔺县"
            }
          ]
        },
        {
          "cityId": 554142,
          "parentId": 545532,
          "name": "德阳市",
          "child": [{
              "cityId": 554143,
              "parentId": 554142,
              "name": "市辖区"
            },
            {
              "cityId": 554144,
              "parentId": 554142,
              "name": "旌阳区"
            },
            {
              "cityId": 554397,
              "parentId": 554142,
              "name": "中江县"
            },
            {
              "cityId": 555280,
              "parentId": 554142,
              "name": "罗江县"
            },
            {
              "cityId": 555418,
              "parentId": 554142,
              "name": "广汉市"
            },
            {
              "cityId": 555660,
              "parentId": 554142,
              "name": "什邡市"
            },
            {
              "cityId": 555848,
              "parentId": 554142,
              "name": "绵竹市"
            }
          ]
        },
        {
          "cityId": 556077,
          "parentId": 545532,
          "name": "绵阳市",
          "child": [{
              "cityId": 556078,
              "parentId": 556077,
              "name": "市辖区"
            },
            {
              "cityId": 556079,
              "parentId": 556077,
              "name": "涪城区"
            },
            {
              "cityId": 556357,
              "parentId": 556077,
              "name": "游仙区"
            },
            {
              "cityId": 556705,
              "parentId": 556077,
              "name": "安州区"
            },
            {
              "cityId": 556985,
              "parentId": 556077,
              "name": "三台县"
            },
            {
              "cityId": 558091,
              "parentId": 556077,
              "name": "盐亭县"
            },
            {
              "cityId": 558648,
              "parentId": 556077,
              "name": "梓潼县"
            },
            {
              "cityId": 559030,
              "parentId": 556077,
              "name": "北川羌族自治县"
            },
            {
              "cityId": 559397,
              "parentId": 556077,
              "name": "平武县"
            },
            {
              "cityId": 559687,
              "parentId": 556077,
              "name": "江油市"
            }
          ]
        },
        {
          "cityId": 560191,
          "parentId": 545532,
          "name": "广元市",
          "child": [{
              "cityId": 560192,
              "parentId": 560191,
              "name": "市辖区"
            },
            {
              "cityId": 560193,
              "parentId": 560191,
              "name": "利州区"
            },
            {
              "cityId": 560469,
              "parentId": 560191,
              "name": "昭化区"
            },
            {
              "cityId": 560735,
              "parentId": 560191,
              "name": "朝天区"
            },
            {
              "cityId": 560982,
              "parentId": 560191,
              "name": "旺苍县"
            },
            {
              "cityId": 561413,
              "parentId": 560191,
              "name": "青川县"
            },
            {
              "cityId": 561756,
              "parentId": 560191,
              "name": "剑阁县"
            },
            {
              "cityId": 562393,
              "parentId": 560191,
              "name": "苍溪县"
            }
          ]
        },
        {
          "cityId": 563242,
          "parentId": 545532,
          "name": "遂宁市",
          "child": [{
              "cityId": 563243,
              "parentId": 563242,
              "name": "市辖区"
            },
            {
              "cityId": 563244,
              "parentId": 563242,
              "name": "船山区"
            },
            {
              "cityId": 563569,
              "parentId": 563242,
              "name": "安居区"
            },
            {
              "cityId": 564115,
              "parentId": 563242,
              "name": "蓬溪县"
            },
            {
              "cityId": 564699,
              "parentId": 563242,
              "name": "射洪县"
            },
            {
              "cityId": 565387,
              "parentId": 563242,
              "name": "大英县"
            }
          ]
        },
        {
          "cityId": 565735,
          "parentId": 545532,
          "name": "内江市",
          "child": [{
              "cityId": 565736,
              "parentId": 565735,
              "name": "市辖区"
            },
            {
              "cityId": 565737,
              "parentId": 565735,
              "name": "市中区"
            },
            {
              "cityId": 565956,
              "parentId": 565735,
              "name": "东兴区"
            },
            {
              "cityId": 566504,
              "parentId": 565735,
              "name": "威远县"
            },
            {
              "cityId": 566895,
              "parentId": 565735,
              "name": "资中县"
            },
            {
              "cityId": 567769,
              "parentId": 565735,
              "name": "隆昌县"
            }
          ]
        },
        {
          "cityId": 568202,
          "parentId": 545532,
          "name": "乐山市",
          "child": [{
              "cityId": 568203,
              "parentId": 568202,
              "name": "市辖区"
            },
            {
              "cityId": 568204,
              "parentId": 568202,
              "name": "市中区"
            },
            {
              "cityId": 568549,
              "parentId": 568202,
              "name": "沙湾区"
            },
            {
              "cityId": 568712,
              "parentId": 568202,
              "name": "五通桥区"
            },
            {
              "cityId": 568897,
              "parentId": 568202,
              "name": "金口河区"
            },
            {
              "cityId": 568949,
              "parentId": 568202,
              "name": "犍为县"
            },
            {
              "cityId": 569368,
              "parentId": 568202,
              "name": "井研县"
            },
            {
              "cityId": 569627,
              "parentId": 568202,
              "name": "夹江县"
            },
            {
              "cityId": 569905,
              "parentId": 568202,
              "name": "沐川县"
            },
            {
              "cityId": 570138,
              "parentId": 568202,
              "name": "峨边彝族自治县"
            },
            {
              "cityId": 570296,
              "parentId": 568202,
              "name": "马边彝族自治县"
            },
            {
              "cityId": 570440,
              "parentId": 568202,
              "name": "峨眉山市"
            }
          ]
        },
        {
          "cityId": 570733,
          "parentId": 545532,
          "name": "南充市",
          "child": [{
              "cityId": 570734,
              "parentId": 570733,
              "name": "市辖区"
            },
            {
              "cityId": 570735,
              "parentId": 570733,
              "name": "顺庆区"
            },
            {
              "cityId": 571078,
              "parentId": 570733,
              "name": "高坪区"
            },
            {
              "cityId": 571510,
              "parentId": 570733,
              "name": "嘉陵区"
            },
            {
              "cityId": 572146,
              "parentId": 570733,
              "name": "南部县"
            },
            {
              "cityId": 573339,
              "parentId": 570733,
              "name": "营山县"
            },
            {
              "cityId": 574080,
              "parentId": 570733,
              "name": "蓬安县"
            },
            {
              "cityId": 574767,
              "parentId": 570733,
              "name": "仪陇县"
            },
            {
              "cityId": 575761,
              "parentId": 570733,
              "name": "西充县"
            },
            {
              "cityId": 576427,
              "parentId": 570733,
              "name": "阆中市"
            }
          ]
        },
        {
          "cityId": 576992,
          "parentId": 545532,
          "name": "眉山市",
          "child": [{
              "cityId": 576993,
              "parentId": 576992,
              "name": "市辖区"
            },
            {
              "cityId": 576994,
              "parentId": 576992,
              "name": "东坡区"
            },
            {
              "cityId": 577323,
              "parentId": 576992,
              "name": "彭山区"
            },
            {
              "cityId": 577445,
              "parentId": 576992,
              "name": "仁寿县"
            },
            {
              "cityId": 578124,
              "parentId": 576992,
              "name": "洪雅县"
            },
            {
              "cityId": 578299,
              "parentId": 576992,
              "name": "丹棱县"
            },
            {
              "cityId": 578385,
              "parentId": 576992,
              "name": "青神县"
            }
          ]
        },
        {
          "cityId": 578485,
          "parentId": 545532,
          "name": "宜宾市",
          "child": [{
              "cityId": 578486,
              "parentId": 578485,
              "name": "市辖区"
            },
            {
              "cityId": 578487,
              "parentId": 578485,
              "name": "翠屏区"
            },
            {
              "cityId": 578857,
              "parentId": 578485,
              "name": "南溪区"
            },
            {
              "cityId": 579114,
              "parentId": 578485,
              "name": "宜宾县"
            },
            {
              "cityId": 579727,
              "parentId": 578485,
              "name": "江安县"
            },
            {
              "cityId": 580070,
              "parentId": 578485,
              "name": "长宁县"
            },
            {
              "cityId": 580388,
              "parentId": 578485,
              "name": "高县"
            },
            {
              "cityId": 580725,
              "parentId": 578485,
              "name": "珙县"
            },
            {
              "cityId": 581025,
              "parentId": 578485,
              "name": "筠连县"
            },
            {
              "cityId": 581304,
              "parentId": 578485,
              "name": "兴文县"
            },
            {
              "cityId": 581579,
              "parentId": 578485,
              "name": "屏山县"
            }
          ]
        },
        {
          "cityId": 581870,
          "parentId": 545532,
          "name": "广安市",
          "child": [{
              "cityId": 581871,
              "parentId": 581870,
              "name": "市辖区"
            },
            {
              "cityId": 581872,
              "parentId": 581870,
              "name": "广安区"
            },
            {
              "cityId": 582526,
              "parentId": 581870,
              "name": "前锋区"
            },
            {
              "cityId": 582827,
              "parentId": 581870,
              "name": "岳池县"
            },
            {
              "cityId": 583751,
              "parentId": 581870,
              "name": "武胜县"
            },
            {
              "cityId": 584332,
              "parentId": 581870,
              "name": "邻水县"
            },
            {
              "cityId": 584900,
              "parentId": 581870,
              "name": "华蓥市"
            }
          ]
        },
        {
          "cityId": 585049,
          "parentId": 545532,
          "name": "达州市",
          "child": [{
              "cityId": 585050,
              "parentId": 585049,
              "name": "市辖区"
            },
            {
              "cityId": 585051,
              "parentId": 585049,
              "name": "通川区"
            },
            {
              "cityId": 585347,
              "parentId": 585049,
              "name": "达川区"
            },
            {
              "cityId": 586085,
              "parentId": 585049,
              "name": "宣汉县"
            },
            {
              "cityId": 586709,
              "parentId": 585049,
              "name": "开江县"
            },
            {
              "cityId": 586957,
              "parentId": 585049,
              "name": "大竹县"
            },
            {
              "cityId": 587452,
              "parentId": 585049,
              "name": "渠县"
            },
            {
              "cityId": 588071,
              "parentId": 585049,
              "name": "万源市"
            }
          ]
        },
        {
          "cityId": 588538,
          "parentId": 545532,
          "name": "雅安市",
          "child": [{
              "cityId": 588539,
              "parentId": 588538,
              "name": "市辖区"
            },
            {
              "cityId": 588540,
              "parentId": 588538,
              "name": "雨城区"
            },
            {
              "cityId": 588771,
              "parentId": 588538,
              "name": "名山区"
            },
            {
              "cityId": 589001,
              "parentId": 588538,
              "name": "荥经县"
            },
            {
              "cityId": 589135,
              "parentId": 588538,
              "name": "汉源县"
            },
            {
              "cityId": 589376,
              "parentId": 588538,
              "name": "石棉县"
            },
            {
              "cityId": 589494,
              "parentId": 588538,
              "name": "天全县"
            },
            {
              "cityId": 589654,
              "parentId": 588538,
              "name": "芦山县"
            },
            {
              "cityId": 589711,
              "parentId": 588538,
              "name": "宝兴县"
            }
          ]
        },
        {
          "cityId": 589779,
          "parentId": 545532,
          "name": "巴中市",
          "child": [{
              "cityId": 589780,
              "parentId": 589779,
              "name": "市辖区"
            },
            {
              "cityId": 589781,
              "parentId": 589779,
              "name": "巴州区"
            },
            {
              "cityId": 590246,
              "parentId": 589779,
              "name": "恩阳区"
            },
            {
              "cityId": 590712,
              "parentId": 589779,
              "name": "通江县"
            },
            {
              "cityId": 591330,
              "parentId": 589779,
              "name": "南江县"
            },
            {
              "cityId": 592003,
              "parentId": 589779,
              "name": "平昌县"
            }
          ]
        },
        {
          "cityId": 592598,
          "parentId": 545532,
          "name": "资阳市",
          "child": [{
              "cityId": 592599,
              "parentId": 592598,
              "name": "市辖区"
            },
            {
              "cityId": 592600,
              "parentId": 592598,
              "name": "雁江区"
            },
            {
              "cityId": 593157,
              "parentId": 592598,
              "name": "安岳县"
            },
            {
              "cityId": 594205,
              "parentId": 592598,
              "name": "乐至县"
            }
          ]
        },
        {
          "cityId": 594864,
          "parentId": 545532,
          "name": "阿坝藏族羌族自治州",
          "child": [{
              "cityId": 594865,
              "parentId": 594864,
              "name": "马尔康市"
            },
            {
              "cityId": 594988,
              "parentId": 594864,
              "name": "汶川县"
            },
            {
              "cityId": 595128,
              "parentId": 594864,
              "name": "理县"
            },
            {
              "cityId": 595230,
              "parentId": 594864,
              "name": "茂县"
            },
            {
              "cityId": 595404,
              "parentId": 594864,
              "name": "松潘县"
            },
            {
              "cityId": 595579,
              "parentId": 594864,
              "name": "九寨沟县"
            },
            {
              "cityId": 595731,
              "parentId": 594864,
              "name": "金川县"
            },
            {
              "cityId": 595864,
              "parentId": 594864,
              "name": "小金县"
            },
            {
              "cityId": 596022,
              "parentId": 594864,
              "name": "黑水县"
            },
            {
              "cityId": 596167,
              "parentId": 594864,
              "name": "壤塘县"
            },
            {
              "cityId": 596243,
              "parentId": 594864,
              "name": "阿坝县"
            },
            {
              "cityId": 596354,
              "parentId": 594864,
              "name": "若尔盖县"
            },
            {
              "cityId": 596475,
              "parentId": 594864,
              "name": "红原县"
            }
          ]
        },
        {
          "cityId": 596525,
          "parentId": 545532,
          "name": "甘孜藏族自治州",
          "child": [{
              "cityId": 596526,
              "parentId": 596525,
              "name": "康定市"
            },
            {
              "cityId": 596792,
              "parentId": 596525,
              "name": "泸定县"
            },
            {
              "cityId": 596957,
              "parentId": 596525,
              "name": "丹巴县"
            },
            {
              "cityId": 597158,
              "parentId": 596525,
              "name": "九龙县"
            },
            {
              "cityId": 597242,
              "parentId": 596525,
              "name": "雅江县"
            },
            {
              "cityId": 597374,
              "parentId": 596525,
              "name": "道孚县"
            },
            {
              "cityId": 597557,
              "parentId": 596525,
              "name": "炉霍县"
            },
            {
              "cityId": 597749,
              "parentId": 596525,
              "name": "甘孜县"
            },
            {
              "cityId": 597992,
              "parentId": 596525,
              "name": "新龙县"
            },
            {
              "cityId": 598162,
              "parentId": 596525,
              "name": "德格县"
            },
            {
              "cityId": 598363,
              "parentId": 596525,
              "name": "白玉县"
            },
            {
              "cityId": 598540,
              "parentId": 596525,
              "name": "石渠县"
            },
            {
              "cityId": 598733,
              "parentId": 596525,
              "name": "色达县"
            },
            {
              "cityId": 598889,
              "parentId": 596525,
              "name": "理塘县"
            },
            {
              "cityId": 599129,
              "parentId": 596525,
              "name": "巴塘县"
            },
            {
              "cityId": 599272,
              "parentId": 596525,
              "name": "乡城县"
            },
            {
              "cityId": 599377,
              "parentId": 596525,
              "name": "稻城县"
            },
            {
              "cityId": 599516,
              "parentId": 596525,
              "name": "得荣县"
            }
          ]
        },
        {
          "cityId": 599659,
          "parentId": 545532,
          "name": "凉山彝族自治州",
          "child": [{
              "cityId": 599660,
              "parentId": 599659,
              "name": "西昌市"
            },
            {
              "cityId": 599974,
              "parentId": 599659,
              "name": "木里藏族自治县"
            },
            {
              "cityId": 600127,
              "parentId": 599659,
              "name": "盐源县"
            },
            {
              "cityId": 600415,
              "parentId": 599659,
              "name": "德昌县"
            },
            {
              "cityId": 600579,
              "parentId": 599659,
              "name": "会理县"
            },
            {
              "cityId": 600938,
              "parentId": 599659,
              "name": "会东县"
            },
            {
              "cityId": 601284,
              "parentId": 599659,
              "name": "宁南县"
            },
            {
              "cityId": 601444,
              "parentId": 599659,
              "name": "普格县"
            },
            {
              "cityId": 601640,
              "parentId": 599659,
              "name": "布拖县"
            },
            {
              "cityId": 601863,
              "parentId": 599659,
              "name": "金阳县"
            },
            {
              "cityId": 602076,
              "parentId": 599659,
              "name": "昭觉县"
            },
            {
              "cityId": 602396,
              "parentId": 599659,
              "name": "喜德县"
            },
            {
              "cityId": 602594,
              "parentId": 599659,
              "name": "冕宁县"
            },
            {
              "cityId": 602865,
              "parentId": 599659,
              "name": "越西县"
            },
            {
              "cityId": 603201,
              "parentId": 599659,
              "name": "甘洛县"
            },
            {
              "cityId": 603460,
              "parentId": 599659,
              "name": "美姑县"
            },
            {
              "cityId": 603792,
              "parentId": 599659,
              "name": "雷波县"
            }
          ]
        }
      ]
    },
    {
      "cityId": 604131,
      "parentId": 0,
      "name": "贵州省",
      "child": [{
          "cityId": 604132,
          "parentId": 604131,
          "name": "贵阳市",
          "child": [{
              "cityId": 604133,
              "parentId": 604132,
              "name": "市辖区"
            },
            {
              "cityId": 604134,
              "parentId": 604132,
              "name": "南明区"
            },
            {
              "cityId": 604342,
              "parentId": 604132,
              "name": "云岩区"
            },
            {
              "cityId": 604530,
              "parentId": 604132,
              "name": "花溪区"
            },
            {
              "cityId": 604769,
              "parentId": 604132,
              "name": "乌当区"
            },
            {
              "cityId": 604883,
              "parentId": 604132,
              "name": "白云区"
            },
            {
              "cityId": 604989,
              "parentId": 604132,
              "name": "观山湖区"
            },
            {
              "cityId": 605114,
              "parentId": 604132,
              "name": "开阳县"
            },
            {
              "cityId": 605264,
              "parentId": 604132,
              "name": "息烽县"
            },
            {
              "cityId": 605453,
              "parentId": 604132,
              "name": "修文县"
            },
            {
              "cityId": 605593,
              "parentId": 604132,
              "name": "清镇市"
            }
          ]
        },
        {
          "cityId": 605834,
          "parentId": 604131,
          "name": "六盘水市",
          "child": [{
              "cityId": 605835,
              "parentId": 605834,
              "name": "钟山区"
            },
            {
              "cityId": 605994,
              "parentId": 605834,
              "name": "六枝特区"
            },
            {
              "cityId": 606258,
              "parentId": 605834,
              "name": "水城县"
            },
            {
              "cityId": 606493,
              "parentId": 605834,
              "name": "盘县"
            }
          ]
        },
        {
          "cityId": 607029,
          "parentId": 604131,
          "name": "遵义市",
          "child": [{
              "cityId": 607030,
              "parentId": 607029,
              "name": "市辖区"
            },
            {
              "cityId": 607031,
              "parentId": 607029,
              "name": "红花岗区"
            },
            {
              "cityId": 607235,
              "parentId": 607029,
              "name": "汇川区"
            },
            {
              "cityId": 607385,
              "parentId": 607029,
              "name": "播州区"
            },
            {
              "cityId": 607584,
              "parentId": 607029,
              "name": "桐梓县"
            },
            {
              "cityId": 607833,
              "parentId": 607029,
              "name": "绥阳县"
            },
            {
              "cityId": 607966,
              "parentId": 607029,
              "name": "正安县"
            },
            {
              "cityId": 608138,
              "parentId": 607029,
              "name": "道真仡佬族苗族自治县"
            },
            {
              "cityId": 608236,
              "parentId": 607029,
              "name": "务川仡佬族苗族自治县"
            },
            {
              "cityId": 608370,
              "parentId": 607029,
              "name": "凤冈县"
            },
            {
              "cityId": 608471,
              "parentId": 607029,
              "name": "湄潭县"
            },
            {
              "cityId": 608620,
              "parentId": 607029,
              "name": "余庆县"
            },
            {
              "cityId": 608701,
              "parentId": 607029,
              "name": "习水县"
            },
            {
              "cityId": 608974,
              "parentId": 607029,
              "name": "赤水市"
            },
            {
              "cityId": 609114,
              "parentId": 607029,
              "name": "仁怀市"
            }
          ]
        },
        {
          "cityId": 609309,
          "parentId": 604131,
          "name": "安顺市",
          "child": [{
              "cityId": 609310,
              "parentId": 609309,
              "name": "市辖区"
            },
            {
              "cityId": 609311,
              "parentId": 609309,
              "name": "西秀区"
            },
            {
              "cityId": 609686,
              "parentId": 609309,
              "name": "平坝区"
            },
            {
              "cityId": 609845,
              "parentId": 609309,
              "name": "普定县"
            },
            {
              "cityId": 610030,
              "parentId": 609309,
              "name": "镇宁布依族苗族自治县"
            },
            {
              "cityId": 610256,
              "parentId": 609309,
              "name": "关岭布依族苗族自治县"
            },
            {
              "cityId": 610426,
              "parentId": 609309,
              "name": "紫云苗族布依族自治县"
            }
          ]
        },
        {
          "cityId": 610607,
          "parentId": 604131,
          "name": "毕节市",
          "child": [{
              "cityId": 610608,
              "parentId": 610607,
              "name": "市辖区"
            },
            {
              "cityId": 610609,
              "parentId": 610607,
              "name": "七星关区"
            },
            {
              "cityId": 611207,
              "parentId": 610607,
              "name": "大方县"
            },
            {
              "cityId": 611627,
              "parentId": 610607,
              "name": "黔西县"
            },
            {
              "cityId": 612043,
              "parentId": 610607,
              "name": "金沙县"
            },
            {
              "cityId": 612308,
              "parentId": 610607,
              "name": "织金县"
            },
            {
              "cityId": 612915,
              "parentId": 610607,
              "name": "纳雍县"
            },
            {
              "cityId": 613369,
              "parentId": 610607,
              "name": "威宁彝族回族苗族自治县"
            },
            {
              "cityId": 614035,
              "parentId": 610607,
              "name": "赫章县"
            }
          ]
        },
        {
          "cityId": 614530,
          "parentId": 604131,
          "name": "铜仁市",
          "child": [{
              "cityId": 614531,
              "parentId": 614530,
              "name": "市辖区"
            },
            {
              "cityId": 614532,
              "parentId": 614530,
              "name": "碧江区"
            },
            {
              "cityId": 614636,
              "parentId": 614530,
              "name": "万山区"
            },
            {
              "cityId": 614733,
              "parentId": 614530,
              "name": "江口县"
            },
            {
              "cityId": 614900,
              "parentId": 614530,
              "name": "玉屏侗族自治县"
            },
            {
              "cityId": 615001,
              "parentId": 614530,
              "name": "石阡县"
            },
            {
              "cityId": 615331,
              "parentId": 614530,
              "name": "思南县"
            },
            {
              "cityId": 615886,
              "parentId": 614530,
              "name": "印江土家族苗族自治县"
            },
            {
              "cityId": 616278,
              "parentId": 614530,
              "name": "德江县"
            },
            {
              "cityId": 616644,
              "parentId": 614530,
              "name": "沿河土家族自治县"
            },
            {
              "cityId": 617116,
              "parentId": 614530,
              "name": "松桃苗族自治县"
            }
          ]
        },
        {
          "cityId": 617670,
          "parentId": 604131,
          "name": "黔西南布依族苗族自治州",
          "child": [{
              "cityId": 617671,
              "parentId": 617670,
              "name": "兴义市"
            },
            {
              "cityId": 617923,
              "parentId": 617670,
              "name": "兴仁县"
            },
            {
              "cityId": 618102,
              "parentId": 617670,
              "name": "普安县"
            },
            {
              "cityId": 618205,
              "parentId": 617670,
              "name": "晴隆县"
            },
            {
              "cityId": 618316,
              "parentId": 617670,
              "name": "贞丰县"
            },
            {
              "cityId": 618489,
              "parentId": 617670,
              "name": "望谟县"
            },
            {
              "cityId": 618671,
              "parentId": 617670,
              "name": "册亨县"
            },
            {
              "cityId": 618816,
              "parentId": 617670,
              "name": "安龙县"
            }
          ]
        },
        {
          "cityId": 619017,
          "parentId": 604131,
          "name": "黔东南苗族侗族自治州",
          "child": [{
              "cityId": 619018,
              "parentId": 619017,
              "name": "凯里市"
            },
            {
              "cityId": 619308,
              "parentId": 619017,
              "name": "黄平县"
            },
            {
              "cityId": 619574,
              "parentId": 619017,
              "name": "施秉县"
            },
            {
              "cityId": 619657,
              "parentId": 619017,
              "name": "三穗县"
            },
            {
              "cityId": 619831,
              "parentId": 619017,
              "name": "镇远县"
            },
            {
              "cityId": 619970,
              "parentId": 619017,
              "name": "岑巩县"
            },
            {
              "cityId": 620122,
              "parentId": 619017,
              "name": "天柱县"
            },
            {
              "cityId": 620465,
              "parentId": 619017,
              "name": "锦屏县"
            },
            {
              "cityId": 620673,
              "parentId": 619017,
              "name": "剑河县"
            },
            {
              "cityId": 620996,
              "parentId": 619017,
              "name": "台江县"
            },
            {
              "cityId": 621166,
              "parentId": 619017,
              "name": "黎平县"
            },
            {
              "cityId": 621616,
              "parentId": 619017,
              "name": "榕江县"
            },
            {
              "cityId": 621906,
              "parentId": 619017,
              "name": "从江县"
            },
            {
              "cityId": 622312,
              "parentId": 619017,
              "name": "雷山县"
            },
            {
              "cityId": 622484,
              "parentId": 619017,
              "name": "麻江县"
            },
            {
              "cityId": 622561,
              "parentId": 619017,
              "name": "丹寨县"
            }
          ]
        },
        {
          "cityId": 622734,
          "parentId": 604131,
          "name": "黔南布依族苗族自治州",
          "child": [{
              "cityId": 622735,
              "parentId": 622734,
              "name": "都匀市"
            },
            {
              "cityId": 622888,
              "parentId": 622734,
              "name": "福泉市"
            },
            {
              "cityId": 622973,
              "parentId": 622734,
              "name": "荔波县"
            },
            {
              "cityId": 623082,
              "parentId": 622734,
              "name": "贵定县"
            },
            {
              "cityId": 623205,
              "parentId": 622734,
              "name": "瓮安县"
            },
            {
              "cityId": 623318,
              "parentId": 622734,
              "name": "独山县"
            },
            {
              "cityId": 623391,
              "parentId": 622734,
              "name": "平塘县"
            },
            {
              "cityId": 623530,
              "parentId": 622734,
              "name": "罗甸县"
            },
            {
              "cityId": 623723,
              "parentId": 622734,
              "name": "长顺县"
            },
            {
              "cityId": 623813,
              "parentId": 622734,
              "name": "龙里县"
            },
            {
              "cityId": 623901,
              "parentId": 622734,
              "name": "惠水县"
            },
            {
              "cityId": 624122,
              "parentId": 622734,
              "name": "三都水族自治县"
            }
          ]
        }
      ]
    },
    {
      "cityId": 624372,
      "parentId": 0,
      "name": "云南省",
      "child": [{
          "cityId": 624373,
          "parentId": 624372,
          "name": "昆明市",
          "child": [{
              "cityId": 624374,
              "parentId": 624373,
              "name": "市辖区"
            },
            {
              "cityId": 624375,
              "parentId": 624373,
              "name": "五华区"
            },
            {
              "cityId": 624486,
              "parentId": 624373,
              "name": "盘龙区"
            },
            {
              "cityId": 624599,
              "parentId": 624373,
              "name": "官渡区"
            },
            {
              "cityId": 624730,
              "parentId": 624373,
              "name": "西山区"
            },
            {
              "cityId": 624860,
              "parentId": 624373,
              "name": "东川区"
            },
            {
              "cityId": 625034,
              "parentId": 624373,
              "name": "呈贡区"
            },
            {
              "cityId": 625111,
              "parentId": 624373,
              "name": "晋宁县"
            },
            {
              "cityId": 625255,
              "parentId": 624373,
              "name": "富民县"
            },
            {
              "cityId": 625337,
              "parentId": 624373,
              "name": "宜良县"
            },
            {
              "cityId": 625486,
              "parentId": 624373,
              "name": "石林彝族自治县"
            },
            {
              "cityId": 625585,
              "parentId": 624373,
              "name": "嵩明县"
            },
            {
              "cityId": 625669,
              "parentId": 624373,
              "name": "禄劝彝族苗族自治县"
            },
            {
              "cityId": 625883,
              "parentId": 624373,
              "name": "寻甸回族彝族自治县"
            },
            {
              "cityId": 626074,
              "parentId": 624373,
              "name": "安宁市"
            }
          ]
        },
        {
          "cityId": 626181,
          "parentId": 624372,
          "name": "曲靖市",
          "child": [{
              "cityId": 626182,
              "parentId": 626181,
              "name": "市辖区"
            },
            {
              "cityId": 626183,
              "parentId": 626181,
              "name": "麒麟区"
            },
            {
              "cityId": 626343,
              "parentId": 626181,
              "name": "沾益区"
            },
            {
              "cityId": 626485,
              "parentId": 626181,
              "name": "马龙县"
            },
            {
              "cityId": 626569,
              "parentId": 626181,
              "name": "陆良县"
            },
            {
              "cityId": 626731,
              "parentId": 626181,
              "name": "师宗县"
            },
            {
              "cityId": 626852,
              "parentId": 626181,
              "name": "罗平县"
            },
            {
              "cityId": 627020,
              "parentId": 626181,
              "name": "富源县"
            },
            {
              "cityId": 627194,
              "parentId": 626181,
              "name": "会泽县"
            },
            {
              "cityId": 627596,
              "parentId": 626181,
              "name": "宣威市"
            }
          ]
        },
        {
          "cityId": 627981,
          "parentId": 624372,
          "name": "玉溪市",
          "child": [{
              "cityId": 627982,
              "parentId": 627981,
              "name": "市辖区"
            },
            {
              "cityId": 627983,
              "parentId": 627981,
              "name": "红塔区"
            },
            {
              "cityId": 628099,
              "parentId": 627981,
              "name": "江川区"
            },
            {
              "cityId": 628181,
              "parentId": 627981,
              "name": "澄江县"
            },
            {
              "cityId": 628228,
              "parentId": 627981,
              "name": "通海县"
            },
            {
              "cityId": 628314,
              "parentId": 627981,
              "name": "华宁县"
            },
            {
              "cityId": 628397,
              "parentId": 627981,
              "name": "易门县"
            },
            {
              "cityId": 628463,
              "parentId": 627981,
              "name": "峨山彝族自治县"
            },
            {
              "cityId": 628548,
              "parentId": 627981,
              "name": "新平彝族傣族自治县"
            },
            {
              "cityId": 628684,
              "parentId": 627981,
              "name": "元江哈尼族彝族傣族自治县"
            }
          ]
        },
        {
          "cityId": 628776,
          "parentId": 624372,
          "name": "保山市",
          "child": [{
              "cityId": 628777,
              "parentId": 628776,
              "name": "市辖区"
            },
            {
              "cityId": 628778,
              "parentId": 628776,
              "name": "隆阳区"
            },
            {
              "cityId": 629117,
              "parentId": 628776,
              "name": "施甸县"
            },
            {
              "cityId": 629271,
              "parentId": 628776,
              "name": "龙陵县"
            },
            {
              "cityId": 629403,
              "parentId": 628776,
              "name": "昌宁县"
            },
            {
              "cityId": 629541,
              "parentId": 628776,
              "name": "腾冲市"
            }
          ]
        },
        {
          "cityId": 629781,
          "parentId": 624372,
          "name": "昭通市",
          "child": [{
              "cityId": 629782,
              "parentId": 629781,
              "name": "市辖区"
            },
            {
              "cityId": 629783,
              "parentId": 629781,
              "name": "昭阳区"
            },
            {
              "cityId": 629988,
              "parentId": 629781,
              "name": "鲁甸县"
            },
            {
              "cityId": 630098,
              "parentId": 629781,
              "name": "巧家县"
            },
            {
              "cityId": 630299,
              "parentId": 629781,
              "name": "盐津县"
            },
            {
              "cityId": 630404,
              "parentId": 629781,
              "name": "大关县"
            },
            {
              "cityId": 630498,
              "parentId": 629781,
              "name": "永善县"
            },
            {
              "cityId": 630656,
              "parentId": 629781,
              "name": "绥江县"
            },
            {
              "cityId": 630704,
              "parentId": 629781,
              "name": "镇雄县"
            },
            {
              "cityId": 630989,
              "parentId": 629781,
              "name": "彝良县"
            },
            {
              "cityId": 631144,
              "parentId": 629781,
              "name": "威信县"
            },
            {
              "cityId": 631242,
              "parentId": 629781,
              "name": "水富县"
            }
          ]
        },
        {
          "cityId": 631276,
          "parentId": 624372,
          "name": "丽江市",
          "child": [{
              "cityId": 631277,
              "parentId": 631276,
              "name": "市辖区"
            },
            {
              "cityId": 631278,
              "parentId": 631276,
              "name": "古城区"
            },
            {
              "cityId": 631349,
              "parentId": 631276,
              "name": "玉龙纳西族自治县"
            },
            {
              "cityId": 631469,
              "parentId": 631276,
              "name": "永胜县"
            },
            {
              "cityId": 631634,
              "parentId": 631276,
              "name": "华坪县"
            },
            {
              "cityId": 631704,
              "parentId": 631276,
              "name": "宁蒗彝族自治县"
            }
          ]
        },
        {
          "cityId": 631811,
          "parentId": 624372,
          "name": "普洱市",
          "child": [{
              "cityId": 631812,
              "parentId": 631811,
              "name": "市辖区"
            },
            {
              "cityId": 631813,
              "parentId": 631811,
              "name": "思茅区"
            },
            {
              "cityId": 631894,
              "parentId": 631811,
              "name": "宁洱哈尼族彝族自治县"
            },
            {
              "cityId": 631993,
              "parentId": 631811,
              "name": "墨江哈尼族自治县"
            },
            {
              "cityId": 632177,
              "parentId": 631811,
              "name": "景东彝族自治县"
            },
            {
              "cityId": 632361,
              "parentId": 631811,
              "name": "景谷傣族彝族自治县"
            },
            {
              "cityId": 632513,
              "parentId": 631811,
              "name": "镇沅彝族哈尼族拉祜族自治县"
            },
            {
              "cityId": 632634,
              "parentId": 631811,
              "name": "江城哈尼族彝族自治县"
            },
            {
              "cityId": 632693,
              "parentId": 631811,
              "name": "孟连傣族拉祜族佤族自治县"
            },
            {
              "cityId": 632742,
              "parentId": 631811,
              "name": "澜沧拉祜族自治县"
            },
            {
              "cityId": 632924,
              "parentId": 631811,
              "name": "西盟佤族自治县"
            }
          ]
        },
        {
          "cityId": 632971,
          "parentId": 624372,
          "name": "临沧市",
          "child": [{
              "cityId": 632972,
              "parentId": 632971,
              "name": "市辖区"
            },
            {
              "cityId": 632973,
              "parentId": 632971,
              "name": "临翔区"
            },
            {
              "cityId": 633086,
              "parentId": 632971,
              "name": "凤庆县"
            },
            {
              "cityId": 633287,
              "parentId": 632971,
              "name": "云县"
            },
            {
              "cityId": 633498,
              "parentId": 632971,
              "name": "永德县"
            },
            {
              "cityId": 633629,
              "parentId": 632971,
              "name": "镇康县"
            },
            {
              "cityId": 633711,
              "parentId": 632971,
              "name": "双江拉祜族佤族布朗族傣族自治县"
            },
            {
              "cityId": 633793,
              "parentId": 632971,
              "name": "耿马傣族佤族自治县"
            },
            {
              "cityId": 633898,
              "parentId": 632971,
              "name": "沧源佤族自治县"
            }
          ]
        },
        {
          "cityId": 634006,
          "parentId": 624372,
          "name": "楚雄彝族自治州",
          "child": [{
              "cityId": 634007,
              "parentId": 634006,
              "name": "楚雄市"
            },
            {
              "cityId": 634176,
              "parentId": 634006,
              "name": "双柏县"
            },
            {
              "cityId": 634269,
              "parentId": 634006,
              "name": "牟定县"
            },
            {
              "cityId": 634366,
              "parentId": 634006,
              "name": "南华县"
            },
            {
              "cityId": 634505,
              "parentId": 634006,
              "name": "姚安县"
            },
            {
              "cityId": 634592,
              "parentId": 634006,
              "name": "大姚县"
            },
            {
              "cityId": 634734,
              "parentId": 634006,
              "name": "永仁县"
            },
            {
              "cityId": 634805,
              "parentId": 634006,
              "name": "元谋县"
            },
            {
              "cityId": 634894,
              "parentId": 634006,
              "name": "武定县"
            },
            {
              "cityId": 635039,
              "parentId": 634006,
              "name": "禄丰县"
            }
          ]
        },
        {
          "cityId": 635219,
          "parentId": 624372,
          "name": "红河哈尼族彝族自治州",
          "child": [{
              "cityId": 635220,
              "parentId": 635219,
              "name": "个旧市"
            },
            {
              "cityId": 635347,
              "parentId": 635219,
              "name": "开远市"
            },
            {
              "cityId": 635435,
              "parentId": 635219,
              "name": "蒙自市"
            },
            {
              "cityId": 635548,
              "parentId": 635219,
              "name": "弥勒市"
            },
            {
              "cityId": 635698,
              "parentId": 635219,
              "name": "屏边苗族自治县"
            },
            {
              "cityId": 635786,
              "parentId": 635219,
              "name": "建水县"
            },
            {
              "cityId": 635955,
              "parentId": 635219,
              "name": "石屏县"
            },
            {
              "cityId": 636080,
              "parentId": 635219,
              "name": "泸西县"
            },
            {
              "cityId": 636176,
              "parentId": 635219,
              "name": "元阳县"
            },
            {
              "cityId": 636329,
              "parentId": 635219,
              "name": "红河县"
            },
            {
              "cityId": 636434,
              "parentId": 635219,
              "name": "金平苗族瑶族傣族自治县"
            },
            {
              "cityId": 636546,
              "parentId": 635219,
              "name": "绿春县"
            },
            {
              "cityId": 636647,
              "parentId": 635219,
              "name": "河口瑶族自治县"
            }
          ]
        },
        {
          "cityId": 636688,
          "parentId": 624372,
          "name": "文山壮族苗族自治州",
          "child": [{
              "cityId": 636689,
              "parentId": 636688,
              "name": "文山市"
            },
            {
              "cityId": 636850,
              "parentId": 636688,
              "name": "砚山县"
            },
            {
              "cityId": 636970,
              "parentId": 636688,
              "name": "西畴县"
            },
            {
              "cityId": 637052,
              "parentId": 636688,
              "name": "麻栗坡县"
            },
            {
              "cityId": 637166,
              "parentId": 636688,
              "name": "马关县"
            },
            {
              "cityId": 637312,
              "parentId": 636688,
              "name": "丘北县"
            },
            {
              "cityId": 637426,
              "parentId": 636688,
              "name": "广南县"
            },
            {
              "cityId": 637619,
              "parentId": 636688,
              "name": "富宁县"
            }
          ]
        },
        {
          "cityId": 637778,
          "parentId": 624372,
          "name": "西双版纳傣族自治州",
          "child": [{
              "cityId": 637779,
              "parentId": 637778,
              "name": "景洪市"
            },
            {
              "cityId": 637910,
              "parentId": 637778,
              "name": "勐海县"
            },
            {
              "cityId": 638015,
              "parentId": 637778,
              "name": "勐腊县"
            }
          ]
        },
        {
          "cityId": 638092,
          "parentId": 624372,
          "name": "大理白族自治州",
          "child": [{
              "cityId": 638093,
              "parentId": 638092,
              "name": "大理市"
            },
            {
              "cityId": 638249,
              "parentId": 638092,
              "name": "漾濞彝族自治县"
            },
            {
              "cityId": 638325,
              "parentId": 638092,
              "name": "祥云县"
            },
            {
              "cityId": 638475,
              "parentId": 638092,
              "name": "宾川县"
            },
            {
              "cityId": 638576,
              "parentId": 638092,
              "name": "弥渡县"
            },
            {
              "cityId": 638674,
              "parentId": 638092,
              "name": "南涧彝族自治县"
            },
            {
              "cityId": 638764,
              "parentId": 638092,
              "name": "巍山彝族回族自治县"
            },
            {
              "cityId": 638858,
              "parentId": 638092,
              "name": "永平县"
            },
            {
              "cityId": 638941,
              "parentId": 638092,
              "name": "云龙县"
            },
            {
              "cityId": 639039,
              "parentId": 638092,
              "name": "洱源县"
            },
            {
              "cityId": 639139,
              "parentId": 638092,
              "name": "剑川县"
            },
            {
              "cityId": 639241,
              "parentId": 638092,
              "name": "鹤庆县"
            }
          ]
        },
        {
          "cityId": 639368,
          "parentId": 624372,
          "name": "德宏傣族景颇族自治州",
          "child": [{
              "cityId": 639369,
              "parentId": 639368,
              "name": "瑞丽市"
            },
            {
              "cityId": 639416,
              "parentId": 639368,
              "name": "芒市"
            },
            {
              "cityId": 639524,
              "parentId": 639368,
              "name": "梁河县"
            },
            {
              "cityId": 639600,
              "parentId": 639368,
              "name": "盈江县"
            },
            {
              "cityId": 639719,
              "parentId": 639368,
              "name": "陇川县"
            }
          ]
        },
        {
          "cityId": 639802,
          "parentId": 624372,
          "name": "怒江傈僳族自治州",
          "child": [{
              "cityId": 639803,
              "parentId": 639802,
              "name": "泸水市"
            },
            {
              "cityId": 639889,
              "parentId": 639802,
              "name": "福贡县"
            },
            {
              "cityId": 639956,
              "parentId": 639802,
              "name": "贡山独龙族怒族自治县"
            },
            {
              "cityId": 639990,
              "parentId": 639802,
              "name": "兰坪白族普米族自治县"
            }
          ]
        },
        {
          "cityId": 640107,
          "parentId": 624372,
          "name": "迪庆藏族自治州",
          "child": [{
              "cityId": 640108,
              "parentId": 640107,
              "name": "香格里拉市"
            },
            {
              "cityId": 640184,
              "parentId": 640107,
              "name": "德钦县"
            },
            {
              "cityId": 640239,
              "parentId": 640107,
              "name": "维西傈僳族自治县"
            }
          ]
        }
      ]
    },
    {
      "cityId": 640332,
      "parentId": 0,
      "name": "西藏自治区",
      "child": [{
          "cityId": 640333,
          "parentId": 640332,
          "name": "拉萨市",
          "child": [{
              "cityId": 640334,
              "parentId": 640333,
              "name": "市辖区"
            },
            {
              "cityId": 640335,
              "parentId": 640333,
              "name": "城关区"
            },
            {
              "cityId": 640399,
              "parentId": 640333,
              "name": "堆龙德庆区"
            },
            {
              "cityId": 640441,
              "parentId": 640333,
              "name": "林周县"
            },
            {
              "cityId": 640497,
              "parentId": 640333,
              "name": "当雄县"
            },
            {
              "cityId": 640534,
              "parentId": 640333,
              "name": "尼木县"
            },
            {
              "cityId": 640575,
              "parentId": 640333,
              "name": "曲水县"
            },
            {
              "cityId": 640599,
              "parentId": 640333,
              "name": "达孜县"
            },
            {
              "cityId": 640626,
              "parentId": 640333,
              "name": "墨竹工卡县"
            }
          ]
        },
        {
          "cityId": 640675,
          "parentId": 640332,
          "name": "日喀则市",
          "child": [{
              "cityId": 640676,
              "parentId": 640675,
              "name": "桑珠孜区"
            },
            {
              "cityId": 640865,
              "parentId": 640675,
              "name": "南木林县"
            },
            {
              "cityId": 641029,
              "parentId": 640675,
              "name": "江孜县"
            },
            {
              "cityId": 641204,
              "parentId": 640675,
              "name": "定日县"
            },
            {
              "cityId": 641393,
              "parentId": 640675,
              "name": "萨迦县"
            },
            {
              "cityId": 641512,
              "parentId": 640675,
              "name": "拉孜县"
            },
            {
              "cityId": 641622,
              "parentId": 640675,
              "name": "昂仁县"
            },
            {
              "cityId": 641825,
              "parentId": 640675,
              "name": "谢通门县"
            },
            {
              "cityId": 641940,
              "parentId": 640675,
              "name": "白朗县"
            },
            {
              "cityId": 642063,
              "parentId": 640675,
              "name": "仁布县"
            },
            {
              "cityId": 642146,
              "parentId": 640675,
              "name": "康马县"
            },
            {
              "cityId": 642203,
              "parentId": 640675,
              "name": "定结县"
            },
            {
              "cityId": 642284,
              "parentId": 640675,
              "name": "仲巴县"
            },
            {
              "cityId": 642356,
              "parentId": 640675,
              "name": "亚东县"
            },
            {
              "cityId": 642389,
              "parentId": 640675,
              "name": "吉隆县"
            },
            {
              "cityId": 642437,
              "parentId": 640675,
              "name": "聂拉木县"
            },
            {
              "cityId": 642489,
              "parentId": 640675,
              "name": "萨嘎县"
            },
            {
              "cityId": 642536,
              "parentId": 640675,
              "name": "岗巴县"
            }
          ]
        },
        {
          "cityId": 642571,
          "parentId": 640332,
          "name": "昌都市",
          "child": [{
              "cityId": 642572,
              "parentId": 642571,
              "name": "卡若区"
            },
            {
              "cityId": 642755,
              "parentId": 642571,
              "name": "江达县"
            },
            {
              "cityId": 642864,
              "parentId": 642571,
              "name": "贡觉县"
            },
            {
              "cityId": 643026,
              "parentId": 642571,
              "name": "类乌齐县"
            },
            {
              "cityId": 643119,
              "parentId": 642571,
              "name": "丁青县"
            },
            {
              "cityId": 643197,
              "parentId": 642571,
              "name": "察雅县"
            },
            {
              "cityId": 643349,
              "parentId": 642571,
              "name": "八宿县"
            },
            {
              "cityId": 643474,
              "parentId": 642571,
              "name": "左贡县"
            },
            {
              "cityId": 643613,
              "parentId": 642571,
              "name": "芒康县"
            },
            {
              "cityId": 643691,
              "parentId": 642571,
              "name": "洛隆县"
            },
            {
              "cityId": 643769,
              "parentId": 642571,
              "name": "边坝县"
            }
          ]
        },
        {
          "cityId": 643863,
          "parentId": 640332,
          "name": "林芝市",
          "child": [{
              "cityId": 643864,
              "parentId": 643863,
              "name": "巴宜区"
            },
            {
              "cityId": 643943,
              "parentId": 643863,
              "name": "工布江达县"
            },
            {
              "cityId": 644033,
              "parentId": 643863,
              "name": "米林县"
            },
            {
              "cityId": 644109,
              "parentId": 643863,
              "name": "墨脱县"
            },
            {
              "cityId": 644164,
              "parentId": 643863,
              "name": "波密县"
            },
            {
              "cityId": 644260,
              "parentId": 643863,
              "name": "察隅县"
            },
            {
              "cityId": 644364,
              "parentId": 643863,
              "name": "朗县"
            }
          ]
        },
        {
          "cityId": 644423,
          "parentId": 640332,
          "name": "山南市",
          "child": [{
              "cityId": 644424,
              "parentId": 644423,
              "name": "市辖区"
            },
            {
              "cityId": 644425,
              "parentId": 644423,
              "name": "乃东区"
            },
            {
              "cityId": 644480,
              "parentId": 644423,
              "name": "扎囊县"
            },
            {
              "cityId": 644548,
              "parentId": 644423,
              "name": "贡嘎县"
            },
            {
              "cityId": 644600,
              "parentId": 644423,
              "name": "桑日县"
            },
            {
              "cityId": 644647,
              "parentId": 644423,
              "name": "琼结县"
            },
            {
              "cityId": 644672,
              "parentId": 644423,
              "name": "曲松县"
            },
            {
              "cityId": 644699,
              "parentId": 644423,
              "name": "措美县"
            },
            {
              "cityId": 644720,
              "parentId": 644423,
              "name": "洛扎县"
            },
            {
              "cityId": 644755,
              "parentId": 644423,
              "name": "加查县"
            },
            {
              "cityId": 644840,
              "parentId": 644423,
              "name": "隆子县"
            },
            {
              "cityId": 644932,
              "parentId": 644423,
              "name": "错那县"
            },
            {
              "cityId": 644967,
              "parentId": 644423,
              "name": "浪卡子县"
            }
          ]
        },
        {
          "cityId": 645076,
          "parentId": 640332,
          "name": "那曲地区",
          "child": [{
              "cityId": 645077,
              "parentId": 645076,
              "name": "那曲县"
            },
            {
              "cityId": 645231,
              "parentId": 645076,
              "name": "嘉黎县"
            },
            {
              "cityId": 645364,
              "parentId": 645076,
              "name": "比如县"
            },
            {
              "cityId": 645550,
              "parentId": 645076,
              "name": "聂荣县"
            },
            {
              "cityId": 645703,
              "parentId": 645076,
              "name": "安多县"
            },
            {
              "cityId": 645791,
              "parentId": 645076,
              "name": "申扎县"
            },
            {
              "cityId": 645862,
              "parentId": 645076,
              "name": "索县"
            },
            {
              "cityId": 645997,
              "parentId": 645076,
              "name": "班戈县"
            },
            {
              "cityId": 646094,
              "parentId": 645076,
              "name": "巴青县"
            },
            {
              "cityId": 646261,
              "parentId": 645076,
              "name": "尼玛县"
            },
            {
              "cityId": 646353,
              "parentId": 645076,
              "name": "双湖县"
            }
          ]
        },
        {
          "cityId": 646392,
          "parentId": 640332,
          "name": "阿里地区",
          "child": [{
              "cityId": 646393,
              "parentId": 646392,
              "name": "普兰县"
            },
            {
              "cityId": 646407,
              "parentId": 646392,
              "name": "札达县"
            },
            {
              "cityId": 646431,
              "parentId": 646392,
              "name": "噶尔县"
            },
            {
              "cityId": 646451,
              "parentId": 646392,
              "name": "日土县"
            },
            {
              "cityId": 646470,
              "parentId": 646392,
              "name": "革吉县"
            },
            {
              "cityId": 646495,
              "parentId": 646392,
              "name": "改则县"
            },
            {
              "cityId": 646551,
              "parentId": 646392,
              "name": "措勤县"
            }
          ]
        }
      ]
    },
    {
      "cityId": 646578,
      "parentId": 0,
      "name": "陕西省",
      "child": [{
          "cityId": 646579,
          "parentId": 646578,
          "name": "西安市",
          "child": [{
              "cityId": 646580,
              "parentId": 646579,
              "name": "市辖区"
            },
            {
              "cityId": 646581,
              "parentId": 646579,
              "name": "新城区"
            },
            {
              "cityId": 646694,
              "parentId": 646579,
              "name": "碑林区"
            },
            {
              "cityId": 646800,
              "parentId": 646579,
              "name": "莲湖区"
            },
            {
              "cityId": 646944,
              "parentId": 646579,
              "name": "灞桥区"
            },
            {
              "cityId": 647214,
              "parentId": 646579,
              "name": "未央区"
            },
            {
              "cityId": 647486,
              "parentId": 646579,
              "name": "雁塔区"
            },
            {
              "cityId": 647710,
              "parentId": 646579,
              "name": "阎良区"
            },
            {
              "cityId": 647821,
              "parentId": 646579,
              "name": "临潼区"
            },
            {
              "cityId": 648113,
              "parentId": 646579,
              "name": "长安区"
            },
            {
              "cityId": 648645,
              "parentId": 646579,
              "name": "高陵区"
            },
            {
              "cityId": 648756,
              "parentId": 646579,
              "name": "蓝田县"
            },
            {
              "cityId": 649123,
              "parentId": 646579,
              "name": "周至县"
            },
            {
              "cityId": 649424,
              "parentId": 646579,
              "name": "户县"
            }
          ]
        },
        {
          "cityId": 649979,
          "parentId": 646578,
          "name": "铜川市",
          "child": [{
              "cityId": 649980,
              "parentId": 649979,
              "name": "市辖区"
            },
            {
              "cityId": 649981,
              "parentId": 649979,
              "name": "王益区"
            },
            {
              "cityId": 650048,
              "parentId": 649979,
              "name": "印台区"
            },
            {
              "cityId": 650189,
              "parentId": 649979,
              "name": "耀州区"
            },
            {
              "cityId": 650386,
              "parentId": 649979,
              "name": "宜君县"
            }
          ]
        },
        {
          "cityId": 650575,
          "parentId": 646578,
          "name": "宝鸡市",
          "child": [{
              "cityId": 650576,
              "parentId": 650575,
              "name": "市辖区"
            },
            {
              "cityId": 650577,
              "parentId": 650575,
              "name": "渭滨区"
            },
            {
              "cityId": 650747,
              "parentId": 650575,
              "name": "金台区"
            },
            {
              "cityId": 650913,
              "parentId": 650575,
              "name": "陈仓区"
            },
            {
              "cityId": 651278,
              "parentId": 650575,
              "name": "凤翔县"
            },
            {
              "cityId": 651528,
              "parentId": 650575,
              "name": "岐山县"
            },
            {
              "cityId": 651697,
              "parentId": 650575,
              "name": "扶风县"
            },
            {
              "cityId": 651872,
              "parentId": 650575,
              "name": "眉县"
            },
            {
              "cityId": 652014,
              "parentId": 650575,
              "name": "陇县"
            },
            {
              "cityId": 652186,
              "parentId": 650575,
              "name": "千阳县"
            },
            {
              "cityId": 652294,
              "parentId": 650575,
              "name": "麟游县"
            },
            {
              "cityId": 652387,
              "parentId": 650575,
              "name": "凤县"
            },
            {
              "cityId": 652505,
              "parentId": 650575,
              "name": "太白县"
            }
          ]
        },
        {
          "cityId": 652581,
          "parentId": 646578,
          "name": "咸阳市",
          "child": [{
              "cityId": 652582,
              "parentId": 652581,
              "name": "市辖区"
            },
            {
              "cityId": 652583,
              "parentId": 652581,
              "name": "秦都区"
            },
            {
              "cityId": 652779,
              "parentId": 652581,
              "name": "杨陵区"
            },
            {
              "cityId": 652863,
              "parentId": 652581,
              "name": "渭城区"
            },
            {
              "cityId": 653042,
              "parentId": 652581,
              "name": "三原县"
            },
            {
              "cityId": 653234,
              "parentId": 652581,
              "name": "泾阳县"
            },
            {
              "cityId": 653470,
              "parentId": 652581,
              "name": "乾县"
            },
            {
              "cityId": 653679,
              "parentId": 652581,
              "name": "礼泉县"
            },
            {
              "cityId": 653918,
              "parentId": 652581,
              "name": "永寿县"
            },
            {
              "cityId": 654101,
              "parentId": 652581,
              "name": "彬县"
            },
            {
              "cityId": 654369,
              "parentId": 652581,
              "name": "长武县"
            },
            {
              "cityId": 654525,
              "parentId": 652581,
              "name": "旬邑县"
            },
            {
              "cityId": 654661,
              "parentId": 652581,
              "name": "淳化县"
            },
            {
              "cityId": 654876,
              "parentId": 652581,
              "name": "武功县"
            },
            {
              "cityId": 655081,
              "parentId": 652581,
              "name": "兴平市"
            }
          ]
        },
        {
          "cityId": 655295,
          "parentId": 646578,
          "name": "渭南市",
          "child": [{
              "cityId": 655296,
              "parentId": 655295,
              "name": "市辖区"
            },
            {
              "cityId": 655297,
              "parentId": 655295,
              "name": "临渭区"
            },
            {
              "cityId": 655724,
              "parentId": 655295,
              "name": "华州区"
            },
            {
              "cityId": 655885,
              "parentId": 655295,
              "name": "潼关县"
            },
            {
              "cityId": 655919,
              "parentId": 655295,
              "name": "大荔县"
            },
            {
              "cityId": 656260,
              "parentId": 655295,
              "name": "合阳县"
            },
            {
              "cityId": 656494,
              "parentId": 655295,
              "name": "澄城县"
            },
            {
              "cityId": 656682,
              "parentId": 655295,
              "name": "蒲城县"
            },
            {
              "cityId": 656988,
              "parentId": 655295,
              "name": "白水县"
            },
            {
              "cityId": 657132,
              "parentId": 655295,
              "name": "富平县"
            },
            {
              "cityId": 657425,
              "parentId": 655295,
              "name": "韩城市"
            },
            {
              "cityId": 657706,
              "parentId": 655295,
              "name": "华阴市"
            }
          ]
        },
        {
          "cityId": 657848,
          "parentId": 646578,
          "name": "延安市",
          "child": [{
              "cityId": 657849,
              "parentId": 657848,
              "name": "市辖区"
            },
            {
              "cityId": 657850,
              "parentId": 657848,
              "name": "宝塔区"
            },
            {
              "cityId": 658235,
              "parentId": 657848,
              "name": "安塞区"
            },
            {
              "cityId": 658462,
              "parentId": 657848,
              "name": "延长县"
            },
            {
              "cityId": 658765,
              "parentId": 657848,
              "name": "延川县"
            },
            {
              "cityId": 658951,
              "parentId": 657848,
              "name": "子长县"
            },
            {
              "cityId": 659327,
              "parentId": 657848,
              "name": "志丹县"
            },
            {
              "cityId": 659450,
              "parentId": 657848,
              "name": "吴起县"
            },
            {
              "cityId": 659554,
              "parentId": 657848,
              "name": "甘泉县"
            },
            {
              "cityId": 659632,
              "parentId": 657848,
              "name": "富县"
            },
            {
              "cityId": 659777,
              "parentId": 657848,
              "name": "洛川县"
            },
            {
              "cityId": 660160,
              "parentId": 657848,
              "name": "宜川县"
            },
            {
              "cityId": 660375,
              "parentId": 657848,
              "name": "黄龙县"
            },
            {
              "cityId": 660433,
              "parentId": 657848,
              "name": "黄陵县"
            }
          ]
        },
        {
          "cityId": 660643,
          "parentId": 646578,
          "name": "汉中市",
          "child": [{
              "cityId": 660644,
              "parentId": 660643,
              "name": "市辖区"
            },
            {
              "cityId": 660645,
              "parentId": 660643,
              "name": "汉台区"
            },
            {
              "cityId": 660909,
              "parentId": 660643,
              "name": "南郑县"
            },
            {
              "cityId": 661244,
              "parentId": 660643,
              "name": "城固县"
            },
            {
              "cityId": 661535,
              "parentId": 660643,
              "name": "洋县"
            },
            {
              "cityId": 661839,
              "parentId": 660643,
              "name": "西乡县"
            },
            {
              "cityId": 662072,
              "parentId": 660643,
              "name": "勉县"
            },
            {
              "cityId": 662289,
              "parentId": 660643,
              "name": "宁强县"
            },
            {
              "cityId": 662521,
              "parentId": 660643,
              "name": "略阳县"
            },
            {
              "cityId": 662704,
              "parentId": 660643,
              "name": "镇巴县"
            },
            {
              "cityId": 662908,
              "parentId": 660643,
              "name": "留坝县"
            },
            {
              "cityId": 662993,
              "parentId": 660643,
              "name": "佛坪县"
            }
          ]
        },
        {
          "cityId": 663046,
          "parentId": 646578,
          "name": "榆林市",
          "child": [{
              "cityId": 663047,
              "parentId": 663046,
              "name": "市辖区"
            },
            {
              "cityId": 663048,
              "parentId": 663046,
              "name": "榆阳区"
            },
            {
              "cityId": 663445,
              "parentId": 663046,
              "name": "横山区"
            },
            {
              "cityId": 663827,
              "parentId": 663046,
              "name": "神木县"
            },
            {
              "cityId": 664184,
              "parentId": 663046,
              "name": "府谷县"
            },
            {
              "cityId": 664441,
              "parentId": 663046,
              "name": "靖边县"
            },
            {
              "cityId": 664686,
              "parentId": 663046,
              "name": "定边县"
            },
            {
              "cityId": 665047,
              "parentId": 663046,
              "name": "绥德县"
            },
            {
              "cityId": 665732,
              "parentId": 663046,
              "name": "米脂县"
            },
            {
              "cityId": 665953,
              "parentId": 663046,
              "name": "佳县"
            },
            {
              "cityId": 666627,
              "parentId": 663046,
              "name": "吴堡县"
            },
            {
              "cityId": 666741,
              "parentId": 663046,
              "name": "清涧县"
            },
            {
              "cityId": 667106,
              "parentId": 663046,
              "name": "子洲县"
            }
          ]
        },
        {
          "cityId": 667681,
          "parentId": 646578,
          "name": "安康市",
          "child": [{
              "cityId": 667682,
              "parentId": 667681,
              "name": "市辖区"
            },
            {
              "cityId": 667683,
              "parentId": 667681,
              "name": "汉滨区"
            },
            {
              "cityId": 668237,
              "parentId": 667681,
              "name": "汉阴县"
            },
            {
              "cityId": 668397,
              "parentId": 667681,
              "name": "石泉县"
            },
            {
              "cityId": 668570,
              "parentId": 667681,
              "name": "宁陕县"
            },
            {
              "cityId": 668662,
              "parentId": 667681,
              "name": "紫阳县"
            },
            {
              "cityId": 668877,
              "parentId": 667681,
              "name": "岚皋县"
            },
            {
              "cityId": 669026,
              "parentId": 667681,
              "name": "平利县"
            },
            {
              "cityId": 669181,
              "parentId": 667681,
              "name": "镇坪县"
            },
            {
              "cityId": 669251,
              "parentId": 667681,
              "name": "旬阳县"
            },
            {
              "cityId": 669578,
              "parentId": 667681,
              "name": "白河县"
            }
          ]
        },
        {
          "cityId": 669707,
          "parentId": 646578,
          "name": "商洛市",
          "child": [{
              "cityId": 669708,
              "parentId": 669707,
              "name": "市辖区"
            },
            {
              "cityId": 669709,
              "parentId": 669707,
              "name": "商州区"
            },
            {
              "cityId": 670030,
              "parentId": 669707,
              "name": "洛南县"
            },
            {
              "cityId": 670291,
              "parentId": 669707,
              "name": "丹凤县"
            },
            {
              "cityId": 670459,
              "parentId": 669707,
              "name": "商南县"
            },
            {
              "cityId": 670594,
              "parentId": 669707,
              "name": "山阳县"
            },
            {
              "cityId": 670852,
              "parentId": 669707,
              "name": "镇安县"
            },
            {
              "cityId": 671028,
              "parentId": 669707,
              "name": "柞水县"
            }
          ]
        }
      ]
    },
    {
      "cityId": 671119,
      "parentId": 0,
      "name": "甘肃省",
      "child": [{
          "cityId": 671120,
          "parentId": 671119,
          "name": "兰州市",
          "child": [{
              "cityId": 671121,
              "parentId": 671120,
              "name": "市辖区"
            },
            {
              "cityId": 671122,
              "parentId": 671120,
              "name": "城关区"
            },
            {
              "cityId": 671324,
              "parentId": 671120,
              "name": "七里河区"
            },
            {
              "cityId": 671477,
              "parentId": 671120,
              "name": "西固区"
            },
            {
              "cityId": 671606,
              "parentId": 671120,
              "name": "安宁区"
            },
            {
              "cityId": 671675,
              "parentId": 671120,
              "name": "红古区"
            },
            {
              "cityId": 671740,
              "parentId": 671120,
              "name": "永登县"
            },
            {
              "cityId": 671967,
              "parentId": 671120,
              "name": "皋兰县"
            },
            {
              "cityId": 672034,
              "parentId": 671120,
              "name": "榆中县"
            }
          ]
        },
        {
          "cityId": 672330,
          "parentId": 671119,
          "name": "嘉峪关市",
          "child": [{
            "cityId": 672331,
            "parentId": 672330,
            "name": "市辖区"
          }]
        },
        {
          "cityId": 672385,
          "parentId": 671119,
          "name": "金昌市",
          "child": [{
              "cityId": 672386,
              "parentId": 672385,
              "name": "市辖区"
            },
            {
              "cityId": 672387,
              "parentId": 672385,
              "name": "金川区"
            },
            {
              "cityId": 672439,
              "parentId": 672385,
              "name": "永昌县"
            }
          ]
        },
        {
          "cityId": 672577,
          "parentId": 671119,
          "name": "白银市",
          "child": [{
              "cityId": 672578,
              "parentId": 672577,
              "name": "市辖区"
            },
            {
              "cityId": 672579,
              "parentId": 672577,
              "name": "白银区"
            },
            {
              "cityId": 672670,
              "parentId": 672577,
              "name": "平川区"
            },
            {
              "cityId": 672773,
              "parentId": 672577,
              "name": "靖远县"
            },
            {
              "cityId": 672978,
              "parentId": 672577,
              "name": "会宁县"
            },
            {
              "cityId": 673307,
              "parentId": 672577,
              "name": "景泰县"
            }
          ]
        },
        {
          "cityId": 673463,
          "parentId": 671119,
          "name": "天水市",
          "child": [{
              "cityId": 673464,
              "parentId": 673463,
              "name": "市辖区"
            },
            {
              "cityId": 673465,
              "parentId": 673463,
              "name": "秦州区"
            },
            {
              "cityId": 673952,
              "parentId": 673463,
              "name": "麦积区"
            },
            {
              "cityId": 674399,
              "parentId": 673463,
              "name": "清水县"
            },
            {
              "cityId": 674684,
              "parentId": 673463,
              "name": "秦安县"
            },
            {
              "cityId": 675138,
              "parentId": 673463,
              "name": "甘谷县"
            },
            {
              "cityId": 675568,
              "parentId": 673463,
              "name": "武山县"
            },
            {
              "cityId": 675938,
              "parentId": 673463,
              "name": "张家川回族自治县"
            }
          ]
        },
        {
          "cityId": 676215,
          "parentId": 671119,
          "name": "武威市",
          "child": [{
              "cityId": 676216,
              "parentId": 676215,
              "name": "市辖区"
            },
            {
              "cityId": 676217,
              "parentId": 676215,
              "name": "凉州区"
            },
            {
              "cityId": 676751,
              "parentId": 676215,
              "name": "民勤县"
            },
            {
              "cityId": 677055,
              "parentId": 676215,
              "name": "古浪县"
            },
            {
              "cityId": 677344,
              "parentId": 676215,
              "name": "天祝藏族自治县"
            }
          ]
        },
        {
          "cityId": 677568,
          "parentId": 671119,
          "name": "张掖市",
          "child": [{
              "cityId": 677569,
              "parentId": 677568,
              "name": "市辖区"
            },
            {
              "cityId": 677570,
              "parentId": 677568,
              "name": "甘州区"
            },
            {
              "cityId": 677865,
              "parentId": 677568,
              "name": "肃南裕固族自治县"
            },
            {
              "cityId": 677983,
              "parentId": 677568,
              "name": "民乐县"
            },
            {
              "cityId": 678174,
              "parentId": 677568,
              "name": "临泽县"
            },
            {
              "cityId": 678270,
              "parentId": 677568,
              "name": "高台县"
            },
            {
              "cityId": 678427,
              "parentId": 677568,
              "name": "山丹县"
            }
          ]
        },
        {
          "cityId": 678560,
          "parentId": 671119,
          "name": "平凉市",
          "child": [{
              "cityId": 678561,
              "parentId": 678560,
              "name": "市辖区"
            },
            {
              "cityId": 678562,
              "parentId": 678560,
              "name": "崆峒区"
            },
            {
              "cityId": 678825,
              "parentId": 678560,
              "name": "泾川县"
            },
            {
              "cityId": 679066,
              "parentId": 678560,
              "name": "灵台县"
            },
            {
              "cityId": 679271,
              "parentId": 678560,
              "name": "崇信县"
            },
            {
              "cityId": 679368,
              "parentId": 678560,
              "name": "华亭县"
            },
            {
              "cityId": 679507,
              "parentId": 678560,
              "name": "庄浪县"
            },
            {
              "cityId": 679825,
              "parentId": 678560,
              "name": "静宁县"
            }
          ]
        },
        {
          "cityId": 680191,
          "parentId": 671119,
          "name": "酒泉市",
          "child": [{
              "cityId": 680192,
              "parentId": 680191,
              "name": "市辖区"
            },
            {
              "cityId": 680193,
              "parentId": 680191,
              "name": "肃州区"
            },
            {
              "cityId": 680365,
              "parentId": 680191,
              "name": "金塔县"
            },
            {
              "cityId": 680478,
              "parentId": 680191,
              "name": "瓜州县"
            },
            {
              "cityId": 680582,
              "parentId": 680191,
              "name": "肃北蒙古族自治县"
            },
            {
              "cityId": 680615,
              "parentId": 680191,
              "name": "阿克塞哈萨克族自治县"
            },
            {
              "cityId": 680634,
              "parentId": 680191,
              "name": "玉门市"
            },
            {
              "cityId": 680736,
              "parentId": 680191,
              "name": "敦煌市"
            }
          ]
        },
        {
          "cityId": 680815,
          "parentId": 671119,
          "name": "庆阳市",
          "child": [{
              "cityId": 680816,
              "parentId": 680815,
              "name": "市辖区"
            },
            {
              "cityId": 680817,
              "parentId": 680815,
              "name": "西峰区"
            },
            {
              "cityId": 680943,
              "parentId": 680815,
              "name": "庆城县"
            },
            {
              "cityId": 681132,
              "parentId": 680815,
              "name": "环县"
            },
            {
              "cityId": 681414,
              "parentId": 680815,
              "name": "华池县"
            },
            {
              "cityId": 681559,
              "parentId": 680815,
              "name": "合水县"
            },
            {
              "cityId": 681665,
              "parentId": 680815,
              "name": "正宁县"
            },
            {
              "cityId": 681784,
              "parentId": 680815,
              "name": "宁县"
            },
            {
              "cityId": 682081,
              "parentId": 680815,
              "name": "镇原县"
            }
          ]
        },
        {
          "cityId": 682322,
          "parentId": 671119,
          "name": "定西市",
          "child": [{
              "cityId": 682323,
              "parentId": 682322,
              "name": "市辖区"
            },
            {
              "cityId": 682324,
              "parentId": 682322,
              "name": "安定区"
            },
            {
              "cityId": 682673,
              "parentId": 682322,
              "name": "通渭县"
            },
            {
              "cityId": 683034,
              "parentId": 682322,
              "name": "陇西县"
            },
            {
              "cityId": 683278,
              "parentId": 682322,
              "name": "渭源县"
            },
            {
              "cityId": 683515,
              "parentId": 682322,
              "name": "临洮县"
            },
            {
              "cityId": 683869,
              "parentId": 682322,
              "name": "漳县"
            },
            {
              "cityId": 684024,
              "parentId": 682322,
              "name": "岷县"
            }
          ]
        },
        {
          "cityId": 684415,
          "parentId": 671119,
          "name": "陇南市",
          "child": [{
              "cityId": 684416,
              "parentId": 684415,
              "name": "市辖区"
            },
            {
              "cityId": 684417,
              "parentId": 684415,
              "name": "武都区"
            },
            {
              "cityId": 685162,
              "parentId": 684415,
              "name": "成县"
            },
            {
              "cityId": 685439,
              "parentId": 684415,
              "name": "文县"
            },
            {
              "cityId": 685772,
              "parentId": 684415,
              "name": "宕昌县"
            },
            {
              "cityId": 686138,
              "parentId": 684415,
              "name": "康县"
            },
            {
              "cityId": 686518,
              "parentId": 684415,
              "name": "西和县"
            },
            {
              "cityId": 686933,
              "parentId": 684415,
              "name": "礼县"
            },
            {
              "cityId": 687542,
              "parentId": 684415,
              "name": "徽县"
            },
            {
              "cityId": 687781,
              "parentId": 684415,
              "name": "两当县"
            }
          ]
        },
        {
          "cityId": 687916,
          "parentId": 671119,
          "name": "临夏回族自治州",
          "child": [{
              "cityId": 687917,
              "parentId": 687916,
              "name": "临夏市"
            },
            {
              "cityId": 687999,
              "parentId": 687916,
              "name": "临夏县"
            },
            {
              "cityId": 688248,
              "parentId": 687916,
              "name": "康乐县"
            },
            {
              "cityId": 688417,
              "parentId": 687916,
              "name": "永靖县"
            },
            {
              "cityId": 688588,
              "parentId": 687916,
              "name": "广河县"
            },
            {
              "cityId": 688701,
              "parentId": 687916,
              "name": "和政县"
            },
            {
              "cityId": 688841,
              "parentId": 687916,
              "name": "东乡族自治县"
            },
            {
              "cityId": 689096,
              "parentId": 687916,
              "name": "积石山保安族东乡族撒拉族自治县"
            }
          ]
        },
        {
          "cityId": 689265,
          "parentId": 671119,
          "name": "甘南藏族自治州",
          "child": [{
              "cityId": 689266,
              "parentId": 689265,
              "name": "合作市"
            },
            {
              "cityId": 689327,
              "parentId": 689265,
              "name": "临潭县"
            },
            {
              "cityId": 689488,
              "parentId": 689265,
              "name": "卓尼县"
            },
            {
              "cityId": 689610,
              "parentId": 689265,
              "name": "舟曲县"
            },
            {
              "cityId": 689845,
              "parentId": 689265,
              "name": "迭部县"
            },
            {
              "cityId": 689913,
              "parentId": 689265,
              "name": "玛曲县"
            },
            {
              "cityId": 689966,
              "parentId": 689265,
              "name": "碌曲县"
            },
            {
              "cityId": 690005,
              "parentId": 689265,
              "name": "夏河县"
            }
          ]
        }
      ]
    },
    {
      "cityId": 690094,
      "parentId": 0,
      "name": "青海省",
      "child": [{
          "cityId": 690095,
          "parentId": 690094,
          "name": "西宁市",
          "child": [{
              "cityId": 690096,
              "parentId": 690095,
              "name": "市辖区"
            },
            {
              "cityId": 690097,
              "parentId": 690095,
              "name": "城东区"
            },
            {
              "cityId": 690164,
              "parentId": 690095,
              "name": "城中区"
            },
            {
              "cityId": 690235,
              "parentId": 690095,
              "name": "城西区"
            },
            {
              "cityId": 690283,
              "parentId": 690095,
              "name": "城北区"
            },
            {
              "cityId": 690357,
              "parentId": 690095,
              "name": "大通回族土族自治县"
            },
            {
              "cityId": 690689,
              "parentId": 690095,
              "name": "湟中县"
            },
            {
              "cityId": 691114,
              "parentId": 690095,
              "name": "湟源县"
            }
          ]
        },
        {
          "cityId": 691278,
          "parentId": 690094,
          "name": "海东市",
          "child": [{
              "cityId": 691279,
              "parentId": 691278,
              "name": "乐都区"
            },
            {
              "cityId": 691667,
              "parentId": 691278,
              "name": "平安区"
            },
            {
              "cityId": 691796,
              "parentId": 691278,
              "name": "民和回族土族自治县"
            },
            {
              "cityId": 692148,
              "parentId": 691278,
              "name": "互助土族自治县"
            },
            {
              "cityId": 692479,
              "parentId": 691278,
              "name": "化隆回族自治县"
            },
            {
              "cityId": 692870,
              "parentId": 691278,
              "name": "循化撒拉族自治县"
            }
          ]
        },
        {
          "cityId": 693040,
          "parentId": 690094,
          "name": "海北藏族自治州",
          "child": [{
              "cityId": 693041,
              "parentId": 693040,
              "name": "门源回族自治县"
            },
            {
              "cityId": 693186,
              "parentId": 693040,
              "name": "祁连县"
            },
            {
              "cityId": 693243,
              "parentId": 693040,
              "name": "海晏县"
            },
            {
              "cityId": 693286,
              "parentId": 693040,
              "name": "刚察县"
            }
          ]
        },
        {
          "cityId": 693342,
          "parentId": 690094,
          "name": "黄南藏族自治州",
          "child": [{
              "cityId": 693343,
              "parentId": 693342,
              "name": "同仁县"
            },
            {
              "cityId": 693437,
              "parentId": 693342,
              "name": "尖扎县"
            },
            {
              "cityId": 693541,
              "parentId": 693342,
              "name": "泽库县"
            },
            {
              "cityId": 693623,
              "parentId": 693342,
              "name": "河南蒙古族自治县"
            }
          ]
        },
        {
          "cityId": 693672,
          "parentId": 690094,
          "name": "海南藏族自治州",
          "child": [{
              "cityId": 693673,
              "parentId": 693672,
              "name": "共和县"
            },
            {
              "cityId": 693808,
              "parentId": 693672,
              "name": "同德县"
            },
            {
              "cityId": 693906,
              "parentId": 693672,
              "name": "贵德县"
            },
            {
              "cityId": 694046,
              "parentId": 693672,
              "name": "兴海县"
            },
            {
              "cityId": 694121,
              "parentId": 693672,
              "name": "贵南县"
            }
          ]
        },
        {
          "cityId": 694220,
          "parentId": 690094,
          "name": "果洛藏族自治州",
          "child": [{
              "cityId": 694221,
              "parentId": 694220,
              "name": "玛沁县"
            },
            {
              "cityId": 694272,
              "parentId": 694220,
              "name": "班玛县"
            },
            {
              "cityId": 694315,
              "parentId": 694220,
              "name": "甘德县"
            },
            {
              "cityId": 694361,
              "parentId": 694220,
              "name": "达日县"
            },
            {
              "cityId": 694407,
              "parentId": 694220,
              "name": "久治县"
            },
            {
              "cityId": 694440,
              "parentId": 694220,
              "name": "玛多县"
            }
          ]
        },
        {
          "cityId": 694478,
          "parentId": 690094,
          "name": "玉树藏族自治州",
          "child": [{
              "cityId": 694479,
              "parentId": 694478,
              "name": "玉树市"
            },
            {
              "cityId": 694572,
              "parentId": 694478,
              "name": "杂多县"
            },
            {
              "cityId": 694622,
              "parentId": 694478,
              "name": "称多县"
            },
            {
              "cityId": 694695,
              "parentId": 694478,
              "name": "治多县"
            },
            {
              "cityId": 694728,
              "parentId": 694478,
              "name": "囊谦县"
            },
            {
              "cityId": 694816,
              "parentId": 694478,
              "name": "曲麻莱县"
            }
          ]
        },
        {
          "cityId": 694846,
          "parentId": 690094,
          "name": "海西蒙古族藏族自治州",
          "child": [{
              "cityId": 694847,
              "parentId": 694846,
              "name": "格尔木市"
            },
            {
              "cityId": 694932,
              "parentId": 694846,
              "name": "德令哈市"
            },
            {
              "cityId": 695009,
              "parentId": 694846,
              "name": "乌兰县"
            },
            {
              "cityId": 695060,
              "parentId": 694846,
              "name": "都兰县"
            },
            {
              "cityId": 695184,
              "parentId": 694846,
              "name": "天峻县"
            }
          ]
        }
      ]
    },
    {
      "cityId": 695259,
      "parentId": 0,
      "name": "宁夏回族自治区",
      "child": [{
          "cityId": 695260,
          "parentId": 695259,
          "name": "银川市",
          "child": [{
              "cityId": 695261,
              "parentId": 695260,
              "name": "市辖区"
            },
            {
              "cityId": 695262,
              "parentId": 695260,
              "name": "兴庆区"
            },
            {
              "cityId": 695412,
              "parentId": 695260,
              "name": "西夏区"
            },
            {
              "cityId": 695500,
              "parentId": 695260,
              "name": "金凤区"
            },
            {
              "cityId": 695575,
              "parentId": 695260,
              "name": "永宁县"
            },
            {
              "cityId": 695675,
              "parentId": 695260,
              "name": "贺兰县"
            },
            {
              "cityId": 695767,
              "parentId": 695260,
              "name": "灵武市"
            }
          ]
        },
        {
          "cityId": 695883,
          "parentId": 695259,
          "name": "石嘴山市",
          "child": [{
              "cityId": 695884,
              "parentId": 695883,
              "name": "市辖区"
            },
            {
              "cityId": 695885,
              "parentId": 695883,
              "name": "大武口区"
            },
            {
              "cityId": 695959,
              "parentId": 695883,
              "name": "惠农区"
            },
            {
              "cityId": 696058,
              "parentId": 695883,
              "name": "平罗县"
            }
          ]
        },
        {
          "cityId": 696252,
          "parentId": 695259,
          "name": "吴忠市",
          "child": [{
              "cityId": 696253,
              "parentId": 696252,
              "name": "市辖区"
            },
            {
              "cityId": 696254,
              "parentId": 696252,
              "name": "利通区"
            },
            {
              "cityId": 696427,
              "parentId": 696252,
              "name": "红寺堡区"
            },
            {
              "cityId": 696505,
              "parentId": 696252,
              "name": "盐池县"
            },
            {
              "cityId": 696633,
              "parentId": 696252,
              "name": "同心县"
            },
            {
              "cityId": 696803,
              "parentId": 696252,
              "name": "青铜峡市"
            }
          ]
        },
        {
          "cityId": 696928,
          "parentId": 695259,
          "name": "固原市",
          "child": [{
              "cityId": 696929,
              "parentId": 696928,
              "name": "市辖区"
            },
            {
              "cityId": 696930,
              "parentId": 696928,
              "name": "原州区"
            },
            {
              "cityId": 697133,
              "parentId": 696928,
              "name": "西吉县"
            },
            {
              "cityId": 697457,
              "parentId": 696928,
              "name": "隆德县"
            },
            {
              "cityId": 697594,
              "parentId": 696928,
              "name": "泾源县"
            },
            {
              "cityId": 697714,
              "parentId": 696928,
              "name": "彭阳县"
            }
          ]
        },
        {
          "cityId": 697887,
          "parentId": 695259,
          "name": "中卫市",
          "child": [{
              "cityId": 697888,
              "parentId": 697887,
              "name": "市辖区"
            },
            {
              "cityId": 697889,
              "parentId": 697887,
              "name": "沙坡头区"
            },
            {
              "cityId": 698087,
              "parentId": 697887,
              "name": "中宁县"
            },
            {
              "cityId": 698240,
              "parentId": 697887,
              "name": "海原县"
            }
          ]
        }
      ]
    },
    {
      "cityId": 698440,
      "parentId": 0,
      "name": "新疆维吾尔自治区",
      "child": [{
          "cityId": 698441,
          "parentId": 698440,
          "name": "乌鲁木齐市",
          "child": [{
              "cityId": 698442,
              "parentId": 698441,
              "name": "市辖区"
            },
            {
              "cityId": 698443,
              "parentId": 698441,
              "name": "天山区"
            },
            {
              "cityId": 698654,
              "parentId": 698441,
              "name": "沙依巴克区"
            },
            {
              "cityId": 698884,
              "parentId": 698441,
              "name": "新市区"
            },
            {
              "cityId": 699054,
              "parentId": 698441,
              "name": "水磨沟区"
            },
            {
              "cityId": 699229,
              "parentId": 698441,
              "name": "头屯河区"
            },
            {
              "cityId": 699313,
              "parentId": 698441,
              "name": "达坂城区"
            },
            {
              "cityId": 699363,
              "parentId": 698441,
              "name": "米东区"
            },
            {
              "cityId": 699535,
              "parentId": 698441,
              "name": "乌鲁木齐县"
            }
          ]
        },
        {
          "cityId": 699583,
          "parentId": 698440,
          "name": "克拉玛依市",
          "child": [{
              "cityId": 699584,
              "parentId": 699583,
              "name": "市辖区"
            },
            {
              "cityId": 699585,
              "parentId": 699583,
              "name": "独山子区"
            },
            {
              "cityId": 699606,
              "parentId": 699583,
              "name": "克拉玛依区"
            },
            {
              "cityId": 699730,
              "parentId": 699583,
              "name": "白碱滩区"
            },
            {
              "cityId": 699752,
              "parentId": 699583,
              "name": "乌尔禾区"
            }
          ]
        },
        {
          "cityId": 699773,
          "parentId": 698440,
          "name": "吐鲁番市",
          "child": [{
              "cityId": 699774,
              "parentId": 699773,
              "name": "高昌区"
            },
            {
              "cityId": 699884,
              "parentId": 699773,
              "name": "鄯善县"
            },
            {
              "cityId": 700000,
              "parentId": 699773,
              "name": "托克逊县"
            }
          ]
        },
        {
          "cityId": 700070,
          "parentId": 698440,
          "name": "哈密市",
          "child": [{
              "cityId": 700071,
              "parentId": 700070,
              "name": "伊州区"
            },
            {
              "cityId": 700350,
              "parentId": 700070,
              "name": "巴里坤哈萨克自治县"
            },
            {
              "cityId": 700438,
              "parentId": 700070,
              "name": "伊吾县"
            }
          ]
        },
        {
          "cityId": 700489,
          "parentId": 698440,
          "name": "昌吉回族自治州",
          "child": [{
              "cityId": 700490,
              "parentId": 700489,
              "name": "昌吉市"
            },
            {
              "cityId": 700685,
              "parentId": 700489,
              "name": "阜康市"
            },
            {
              "cityId": 700805,
              "parentId": 700489,
              "name": "呼图壁县"
            },
            {
              "cityId": 700956,
              "parentId": 700489,
              "name": "玛纳斯县"
            },
            {
              "cityId": 701230,
              "parentId": 700489,
              "name": "奇台县"
            },
            {
              "cityId": 701375,
              "parentId": 700489,
              "name": "吉木萨尔县"
            },
            {
              "cityId": 701474,
              "parentId": 700489,
              "name": "木垒哈萨克自治县"
            }
          ]
        },
        {
          "cityId": 701559,
          "parentId": 698440,
          "name": "博尔塔拉蒙古自治州",
          "child": [{
              "cityId": 701560,
              "parentId": 701559,
              "name": "博乐市"
            },
            {
              "cityId": 701834,
              "parentId": 701559,
              "name": "阿拉山口市"
            },
            {
              "cityId": 701839,
              "parentId": 701559,
              "name": "精河县"
            },
            {
              "cityId": 701978,
              "parentId": 701559,
              "name": "温泉县"
            }
          ]
        },
        {
          "cityId": 702104,
          "parentId": 698440,
          "name": "巴音郭楞蒙古自治州",
          "child": [{
              "cityId": 702105,
              "parentId": 702104,
              "name": "库尔勒市"
            },
            {
              "cityId": 702357,
              "parentId": 702104,
              "name": "轮台县"
            },
            {
              "cityId": 702447,
              "parentId": 702104,
              "name": "尉犁县"
            },
            {
              "cityId": 702573,
              "parentId": 702104,
              "name": "若羌县"
            },
            {
              "cityId": 702634,
              "parentId": 702104,
              "name": "且末县"
            },
            {
              "cityId": 702727,
              "parentId": 702104,
              "name": "焉耆回族自治县"
            },
            {
              "cityId": 702821,
              "parentId": 702104,
              "name": "和静县"
            },
            {
              "cityId": 702995,
              "parentId": 702104,
              "name": "和硕县"
            },
            {
              "cityId": 703063,
              "parentId": 702104,
              "name": "博湖县"
            }
          ]
        },
        {
          "cityId": 703119,
          "parentId": 698440,
          "name": "阿克苏地区",
          "child": [{
              "cityId": 703120,
              "parentId": 703119,
              "name": "阿克苏市"
            },
            {
              "cityId": 703353,
              "parentId": 703119,
              "name": "温宿县"
            },
            {
              "cityId": 703576,
              "parentId": 703119,
              "name": "库车县"
            },
            {
              "cityId": 703858,
              "parentId": 703119,
              "name": "沙雅县"
            },
            {
              "cityId": 704059,
              "parentId": 703119,
              "name": "新和县"
            },
            {
              "cityId": 704209,
              "parentId": 703119,
              "name": "拜城县"
            },
            {
              "cityId": 704413,
              "parentId": 703119,
              "name": "乌什县"
            },
            {
              "cityId": 704556,
              "parentId": 703119,
              "name": "阿瓦提县"
            },
            {
              "cityId": 704733,
              "parentId": 703119,
              "name": "柯坪县"
            }
          ]
        },
        {
          "cityId": 704779,
          "parentId": 698440,
          "name": "克孜勒苏柯尔克孜自治州",
          "child": [{
              "cityId": 704780,
              "parentId": 704779,
              "name": "阿图什市"
            },
            {
              "cityId": 704893,
              "parentId": 704779,
              "name": "阿克陶县"
            },
            {
              "cityId": 705047,
              "parentId": 704779,
              "name": "阿合奇县"
            },
            {
              "cityId": 705084,
              "parentId": 704779,
              "name": "乌恰县"
            }
          ]
        },
        {
          "cityId": 705145,
          "parentId": 698440,
          "name": "喀什地区",
          "child": [{
              "cityId": 705146,
              "parentId": 705145,
              "name": "喀什市"
            },
            {
              "cityId": 705344,
              "parentId": 705145,
              "name": "疏附县"
            },
            {
              "cityId": 705496,
              "parentId": 705145,
              "name": "疏勒县"
            },
            {
              "cityId": 705764,
              "parentId": 705145,
              "name": "英吉沙县"
            },
            {
              "cityId": 705961,
              "parentId": 705145,
              "name": "泽普县"
            },
            {
              "cityId": 706133,
              "parentId": 705145,
              "name": "莎车县"
            },
            {
              "cityId": 706753,
              "parentId": 705145,
              "name": "叶城县"
            },
            {
              "cityId": 707143,
              "parentId": 705145,
              "name": "麦盖提县"
            },
            {
              "cityId": 707342,
              "parentId": 705145,
              "name": "岳普湖县"
            },
            {
              "cityId": 707464,
              "parentId": 705145,
              "name": "伽师县"
            },
            {
              "cityId": 707806,
              "parentId": 705145,
              "name": "巴楚县"
            },
            {
              "cityId": 708055,
              "parentId": 705145,
              "name": "塔什库尔干塔吉克自治县"
            }
          ]
        },
        {
          "cityId": 708117,
          "parentId": 698440,
          "name": "和田地区",
          "child": [{
              "cityId": 708118,
              "parentId": 708117,
              "name": "和田市"
            },
            {
              "cityId": 708305,
              "parentId": 708117,
              "name": "和田县"
            },
            {
              "cityId": 708528,
              "parentId": 708117,
              "name": "墨玉县"
            },
            {
              "cityId": 708940,
              "parentId": 708117,
              "name": "皮山县"
            },
            {
              "cityId": 709155,
              "parentId": 708117,
              "name": "洛浦县"
            },
            {
              "cityId": 709393,
              "parentId": 708117,
              "name": "策勒县"
            },
            {
              "cityId": 709538,
              "parentId": 708117,
              "name": "于田县"
            },
            {
              "cityId": 709746,
              "parentId": 708117,
              "name": "民丰县"
            }
          ]
        },
        {
          "cityId": 709792,
          "parentId": 698440,
          "name": "伊犁哈萨克自治州",
          "child": [{
              "cityId": 709793,
              "parentId": 709792,
              "name": "伊宁市"
            },
            {
              "cityId": 709978,
              "parentId": 709792,
              "name": "奎屯市"
            },
            {
              "cityId": 710057,
              "parentId": 709792,
              "name": "霍尔果斯市"
            },
            {
              "cityId": 710090,
              "parentId": 709792,
              "name": "伊宁县"
            },
            {
              "cityId": 710275,
              "parentId": 709792,
              "name": "察布查尔锡伯自治县"
            },
            {
              "cityId": 710410,
              "parentId": 709792,
              "name": "霍城县"
            },
            {
              "cityId": 710612,
              "parentId": 709792,
              "name": "巩留县"
            },
            {
              "cityId": 710730,
              "parentId": 709792,
              "name": "新源县"
            },
            {
              "cityId": 710868,
              "parentId": 709792,
              "name": "昭苏县"
            },
            {
              "cityId": 711013,
              "parentId": 709792,
              "name": "特克斯县"
            },
            {
              "cityId": 711105,
              "parentId": 709792,
              "name": "尼勒克县"
            }
          ]
        },
        {
          "cityId": 711218,
          "parentId": 698440,
          "name": "塔城地区",
          "child": [{
              "cityId": 711219,
              "parentId": 711218,
              "name": "塔城市"
            },
            {
              "cityId": 711436,
              "parentId": 711218,
              "name": "乌苏市"
            },
            {
              "cityId": 711820,
              "parentId": 711218,
              "name": "额敏县"
            },
            {
              "cityId": 712103,
              "parentId": 711218,
              "name": "沙湾县"
            },
            {
              "cityId": 712601,
              "parentId": 711218,
              "name": "托里县"
            },
            {
              "cityId": 712700,
              "parentId": 711218,
              "name": "裕民县"
            },
            {
              "cityId": 712777,
              "parentId": 711218,
              "name": "和布克赛尔蒙古自治县"
            }
          ]
        },
        {
          "cityId": 712893,
          "parentId": 698440,
          "name": "阿勒泰地区",
          "child": [{
              "cityId": 712894,
              "parentId": 712893,
              "name": "阿勒泰市"
            },
            {
              "cityId": 713072,
              "parentId": 712893,
              "name": "布尔津县"
            },
            {
              "cityId": 713161,
              "parentId": 712893,
              "name": "富蕴县"
            },
            {
              "cityId": 713261,
              "parentId": 712893,
              "name": "福海县"
            },
            {
              "cityId": 713426,
              "parentId": 712893,
              "name": "哈巴河县"
            },
            {
              "cityId": 713568,
              "parentId": 712893,
              "name": "青河县"
            },
            {
              "cityId": 713635,
              "parentId": 712893,
              "name": "吉木乃县"
            }
          ]
        },
        {
          "cityId": 713697,
          "parentId": 698440,
          "name": "直辖县",
          "child": [{
              "cityId": 713698,
              "parentId": 713697,
              "name": "石河子市"
            },
            {
              "cityId": 713842,
              "parentId": 713697,
              "name": "阿拉尔市"
            },
            {
              "cityId": 714172,
              "parentId": 713697,
              "name": "图木舒克市"
            },
            {
              "cityId": 714310,
              "parentId": 713697,
              "name": "五家渠市"
            },
            {
              "cityId": 714363,
              "parentId": 713697,
              "name": "铁门关市"
            }
          ]
        }
      ]
    },
    {
      "cityId": 714368,
      "parentId": 0,
      "name": "香港特别行政区",
      "child": [{
        "cityId": 752150,
        "parentId": 714368,
        "name": "香港特别行政区",
        "child": [{
            "cityId": 752151,
            "parentId": 752150,
            "name": "中西区"
          },
          {
            "cityId": 752152,
            "parentId": 752150,
            "name": "东区"
          },
          {
            "cityId": 752153,
            "parentId": 752150,
            "name": "九龙城区"
          },
          {
            "cityId": 752154,
            "parentId": 752150,
            "name": "观塘区"
          },
          {
            "cityId": 752155,
            "parentId": 752150,
            "name": "南区"
          },
          {
            "cityId": 752156,
            "parentId": 752150,
            "name": "深水埗区"
          },
          {
            "cityId": 752157,
            "parentId": 752150,
            "name": "湾仔区"
          },
          {
            "cityId": 752158,
            "parentId": 752150,
            "name": "黄大仙区"
          },
          {
            "cityId": 752159,
            "parentId": 752150,
            "name": "油尖旺区"
          },
          {
            "cityId": 752160,
            "parentId": 752150,
            "name": "离岛区"
          },
          {
            "cityId": 752161,
            "parentId": 752150,
            "name": "葵青区"
          },
          {
            "cityId": 752162,
            "parentId": 752150,
            "name": "北区"
          },
          {
            "cityId": 752163,
            "parentId": 752150,
            "name": "西贡区"
          },
          {
            "cityId": 752164,
            "parentId": 752150,
            "name": "沙田区"
          },
          {
            "cityId": 752165,
            "parentId": 752150,
            "name": "屯门区"
          },
          {
            "cityId": 752166,
            "parentId": 752150,
            "name": "大埔区"
          },
          {
            "cityId": 752167,
            "parentId": 752150,
            "name": "荃湾区"
          },
          {
            "cityId": 752168,
            "parentId": 752150,
            "name": "元朗区"
          }
        ]
      }]
    },
    {
      "cityId": 714390,
      "parentId": 0,
      "name": "澳门特别行政区",
      "child": [{
        "cityId": 752169,
        "parentId": 714390,
        "name": "澳门特别行政区",
        "child": [{
            "cityId": 752170,
            "parentId": 752169,
            "name": "澳门半岛"
          },
          {
            "cityId": 752171,
            "parentId": 752169,
            "name": "凼仔"
          },
          {
            "cityId": 752172,
            "parentId": 752169,
            "name": "路凼城"
          },
          {
            "cityId": 752173,
            "parentId": 752169,
            "name": "路环"
          }
        ]
      }]
    },
    {
      "cityId": 714401,
      "parentId": 0,
      "name": "台湾",
      "child": [{
          "cityId": 714402,
          "parentId": 714401,
          "name": "彰化县",
          "child": [{
              "cityId": 714403,
              "parentId": 714402,
              "name": "芳苑乡"
            },
            {
              "cityId": 714632,
              "parentId": 714402,
              "name": "芬园乡"
            },
            {
              "cityId": 714701,
              "parentId": 714402,
              "name": "福兴乡"
            },
            {
              "cityId": 714777,
              "parentId": 714402,
              "name": "和美镇"
            },
            {
              "cityId": 715055,
              "parentId": 714402,
              "name": "花坛乡"
            },
            {
              "cityId": 715172,
              "parentId": 714402,
              "name": "鹿港镇"
            },
            {
              "cityId": 715490,
              "parentId": 714402,
              "name": "埤头乡"
            },
            {
              "cityId": 715602,
              "parentId": 714402,
              "name": "埔心乡"
            },
            {
              "cityId": 715745,
              "parentId": 714402,
              "name": "埔盐乡"
            },
            {
              "cityId": 715795,
              "parentId": 714402,
              "name": "伸港乡"
            },
            {
              "cityId": 715960,
              "parentId": 714402,
              "name": "社头乡"
            },
            {
              "cityId": 716105,
              "parentId": 714402,
              "name": "田尾乡"
            },
            {
              "cityId": 716202,
              "parentId": 714402,
              "name": "田中镇"
            },
            {
              "cityId": 716341,
              "parentId": 714402,
              "name": "线西乡"
            },
            {
              "cityId": 716421,
              "parentId": 714402,
              "name": "溪湖镇"
            },
            {
              "cityId": 716750,
              "parentId": 714402,
              "name": "秀水乡"
            },
            {
              "cityId": 716874,
              "parentId": 714402,
              "name": "溪州乡"
            },
            {
              "cityId": 717107,
              "parentId": 714402,
              "name": "永靖乡"
            },
            {
              "cityId": 717238,
              "parentId": 714402,
              "name": "员林市"
            },
            {
              "cityId": 717447,
              "parentId": 714402,
              "name": "竹塘乡"
            }
          ]
        },
        {
          "cityId": 717531,
          "parentId": 714401,
          "name": "新北市",
          "child": [{
              "cityId": 717532,
              "parentId": 717531,
              "name": "八里区"
            },
            {
              "cityId": 717645,
              "parentId": 717531,
              "name": "板桥区"
            },
            {
              "cityId": 717902,
              "parentId": 717531,
              "name": "贡寮区"
            },
            {
              "cityId": 717955,
              "parentId": 717531,
              "name": "金山区"
            },
            {
              "cityId": 718036,
              "parentId": 717531,
              "name": "林口区"
            },
            {
              "cityId": 718195,
              "parentId": 717531,
              "name": "芦洲区"
            },
            {
              "cityId": 718266,
              "parentId": 717531,
              "name": "坪林区"
            },
            {
              "cityId": 718327,
              "parentId": 717531,
              "name": "平溪区"
            },
            {
              "cityId": 718375,
              "parentId": 717531,
              "name": "瑞芳区"
            },
            {
              "cityId": 718490,
              "parentId": 717531,
              "name": "三重区"
            },
            {
              "cityId": 718786,
              "parentId": 717531,
              "name": "三峡区"
            },
            {
              "cityId": 718879,
              "parentId": 717531,
              "name": "三芝区"
            },
            {
              "cityId": 718980,
              "parentId": 717531,
              "name": "深坑区"
            },
            {
              "cityId": 719023,
              "parentId": 717531,
              "name": "石碇区"
            },
            {
              "cityId": 719115,
              "parentId": 717531,
              "name": "石门区"
            },
            {
              "cityId": 719155,
              "parentId": 717531,
              "name": "双溪区"
            },
            {
              "cityId": 719243,
              "parentId": 717531,
              "name": "树林区"
            },
            {
              "cityId": 719382,
              "parentId": 717531,
              "name": "泰山区"
            },
            {
              "cityId": 719498,
              "parentId": 717531,
              "name": "淡水区"
            },
            {
              "cityId": 719731,
              "parentId": 717531,
              "name": "土城区"
            }
          ]
        },
        {
          "cityId": 719868,
          "parentId": 714401,
          "name": "澎湖县",
          "child": [{
              "cityId": 719869,
              "parentId": 719868,
              "name": "白沙乡"
            },
            {
              "cityId": 719890,
              "parentId": 719868,
              "name": "湖西乡"
            },
            {
              "cityId": 719916,
              "parentId": 719868,
              "name": "马公市"
            },
            {
              "cityId": 720065,
              "parentId": 719868,
              "name": "七美乡"
            },
            {
              "cityId": 720090,
              "parentId": 719868,
              "name": "望安乡"
            },
            {
              "cityId": 720102,
              "parentId": 719868,
              "name": "西屿乡"
            }
          ]
        },
        {
          "cityId": 720118,
          "parentId": 714401,
          "name": "屏东县",
          "child": [{
              "cityId": 720119,
              "parentId": 720118,
              "name": "三地门乡"
            },
            {
              "cityId": 720142,
              "parentId": 720118,
              "name": "狮子乡"
            },
            {
              "cityId": 720163,
              "parentId": 720118,
              "name": "泰武乡"
            },
            {
              "cityId": 720186,
              "parentId": 720118,
              "name": "万丹乡"
            },
            {
              "cityId": 720415,
              "parentId": 720118,
              "name": "万峦乡"
            },
            {
              "cityId": 720480,
              "parentId": 720118,
              "name": "雾臺乡"
            },
            {
              "cityId": 720502,
              "parentId": 720118,
              "name": "新埤乡"
            },
            {
              "cityId": 720553,
              "parentId": 720118,
              "name": "新园乡"
            },
            {
              "cityId": 720649,
              "parentId": 720118,
              "name": "盐埔乡"
            },
            {
              "cityId": 720748,
              "parentId": 720118,
              "name": "竹田乡"
            },
            {
              "cityId": 720835,
              "parentId": 720118,
              "name": "长治乡"
            },
            {
              "cityId": 720975,
              "parentId": 720118,
              "name": "潮州镇"
            },
            {
              "cityId": 721293,
              "parentId": 720118,
              "name": "车城乡"
            },
            {
              "cityId": 721335,
              "parentId": 720118,
              "name": "春日乡"
            },
            {
              "cityId": 721344,
              "parentId": 720118,
              "name": "东港镇"
            },
            {
              "cityId": 721490,
              "parentId": 720118,
              "name": "枋寮乡"
            },
            {
              "cityId": 721617,
              "parentId": 720118,
              "name": "枋山乡"
            },
            {
              "cityId": 721638,
              "parentId": 720118,
              "name": "高树乡"
            },
            {
              "cityId": 721805,
              "parentId": 720118,
              "name": "恆春镇"
            },
            {
              "cityId": 721930,
              "parentId": 720118,
              "name": "佳冬乡"
            }
          ]
        },
        {
          "cityId": 722024,
          "parentId": 714401,
          "name": "臺中市",
          "child": [{
              "cityId": 722025,
              "parentId": 722024,
              "name": "梧栖区"
            },
            {
              "cityId": 722212,
              "parentId": 722024,
              "name": "乌日区"
            },
            {
              "cityId": 722402,
              "parentId": 722024,
              "name": "新社区"
            },
            {
              "cityId": 722474,
              "parentId": 722024,
              "name": "西屯区"
            },
            {
              "cityId": 722699,
              "parentId": 722024,
              "name": "北屯区"
            },
            {
              "cityId": 722879,
              "parentId": 722024,
              "name": "中区"
            },
            {
              "cityId": 722923,
              "parentId": 722024,
              "name": "大肚区"
            },
            {
              "cityId": 723021,
              "parentId": 722024,
              "name": "大甲区"
            },
            {
              "cityId": 723211,
              "parentId": 722024,
              "name": "大里区"
            },
            {
              "cityId": 723592,
              "parentId": 722024,
              "name": "大雅区"
            },
            {
              "cityId": 723756,
              "parentId": 722024,
              "name": "大安区"
            },
            {
              "cityId": 723802,
              "parentId": 722024,
              "name": "东势区"
            },
            {
              "cityId": 723966,
              "parentId": 722024,
              "name": "东区"
            },
            {
              "cityId": 724148,
              "parentId": 722024,
              "name": "丰原区"
            },
            {
              "cityId": 724424,
              "parentId": 722024,
              "name": "和平区"
            },
            {
              "cityId": 724504,
              "parentId": 722024,
              "name": "后里区"
            },
            {
              "cityId": 724656,
              "parentId": 722024,
              "name": "龙井区"
            },
            {
              "cityId": 724797,
              "parentId": 722024,
              "name": "南屯区"
            },
            {
              "cityId": 724872,
              "parentId": 722024,
              "name": "北区"
            },
            {
              "cityId": 725199,
              "parentId": 722024,
              "name": "清水区"
            }
          ]
        },
        {
          "cityId": 725488,
          "parentId": 714401,
          "name": "臺南市",
          "child": [{
              "cityId": 725489,
              "parentId": 725488,
              "name": "佳里区"
            },
            {
              "cityId": 725588,
              "parentId": 725488,
              "name": "将军区"
            },
            {
              "cityId": 725620,
              "parentId": 725488,
              "name": "六甲区"
            },
            {
              "cityId": 725679,
              "parentId": 725488,
              "name": "柳营区"
            },
            {
              "cityId": 725795,
              "parentId": 725488,
              "name": "龙崎区"
            },
            {
              "cityId": 725841,
              "parentId": 725488,
              "name": "麻豆区"
            },
            {
              "cityId": 725927,
              "parentId": 725488,
              "name": "南化区"
            },
            {
              "cityId": 725938,
              "parentId": 725488,
              "name": "楠西区"
            },
            {
              "cityId": 725973,
              "parentId": 725488,
              "name": "北区"
            },
            {
              "cityId": 726300,
              "parentId": 725488,
              "name": "七股区"
            },
            {
              "cityId": 726338,
              "parentId": 725488,
              "name": "仁德区"
            },
            {
              "cityId": 726539,
              "parentId": 725488,
              "name": "善化区"
            },
            {
              "cityId": 726675,
              "parentId": 725488,
              "name": "山上区"
            },
            {
              "cityId": 726691,
              "parentId": 725488,
              "name": "南区"
            },
            {
              "cityId": 727041,
              "parentId": 725488,
              "name": "中西区"
            },
            {
              "cityId": 727251,
              "parentId": 725488,
              "name": "下营区"
            },
            {
              "cityId": 727339,
              "parentId": 725488,
              "name": "西港区"
            },
            {
              "cityId": 727375,
              "parentId": 725488,
              "name": "新化区"
            },
            {
              "cityId": 727425,
              "parentId": 725488,
              "name": "新市区"
            },
            {
              "cityId": 727529,
              "parentId": 725488,
              "name": "新营区"
            }
          ]
        },
        {
          "cityId": 727730,
          "parentId": 714401,
          "name": "臺北市",
          "child": [{
              "cityId": 727731,
              "parentId": 727730,
              "name": "北投区"
            },
            {
              "cityId": 727897,
              "parentId": 727730,
              "name": "大同区"
            },
            {
              "cityId": 728070,
              "parentId": 727730,
              "name": "大安区"
            },
            {
              "cityId": 728116,
              "parentId": 727730,
              "name": "南港区"
            },
            {
              "cityId": 728220,
              "parentId": 727730,
              "name": "内湖区"
            },
            {
              "cityId": 728340,
              "parentId": 727730,
              "name": "士林区"
            },
            {
              "cityId": 728550,
              "parentId": 727730,
              "name": "松山区"
            },
            {
              "cityId": 728713,
              "parentId": 727730,
              "name": "万华区"
            },
            {
              "cityId": 728920,
              "parentId": 727730,
              "name": "文山区"
            },
            {
              "cityId": 729073,
              "parentId": 727730,
              "name": "信义区"
            },
            {
              "cityId": 729277,
              "parentId": 727730,
              "name": "中山区"
            },
            {
              "cityId": 729583,
              "parentId": 727730,
              "name": "中正区"
            }
          ]
        },
        {
          "cityId": 729928,
          "parentId": 714401,
          "name": "臺东县",
          "child": [{
              "cityId": 729929,
              "parentId": 729928,
              "name": "卑南乡"
            },
            {
              "cityId": 729994,
              "parentId": 729928,
              "name": "长滨乡"
            },
            {
              "cityId": 730033,
              "parentId": 729928,
              "name": "成功镇"
            },
            {
              "cityId": 730107,
              "parentId": 729928,
              "name": "池上乡"
            },
            {
              "cityId": 730196,
              "parentId": 729928,
              "name": "达仁乡"
            },
            {
              "cityId": 730219,
              "parentId": 729928,
              "name": "大武乡"
            },
            {
              "cityId": 730268,
              "parentId": 729928,
              "name": "东河乡"
            },
            {
              "cityId": 730308,
              "parentId": 729928,
              "name": "关山镇"
            },
            {
              "cityId": 730384,
              "parentId": 729928,
              "name": "海端乡"
            },
            {
              "cityId": 730409,
              "parentId": 729928,
              "name": "金峰乡"
            },
            {
              "cityId": 730416,
              "parentId": 729928,
              "name": "兰屿乡"
            },
            {
              "cityId": 730423,
              "parentId": 729928,
              "name": "绿岛乡"
            },
            {
              "cityId": 730438,
              "parentId": 729928,
              "name": "鹿野乡"
            },
            {
              "cityId": 730510,
              "parentId": 729928,
              "name": "太麻里乡"
            },
            {
              "cityId": 730565,
              "parentId": 729928,
              "name": "臺东市"
            },
            {
              "cityId": 730832,
              "parentId": 729928,
              "name": "延平乡"
            }
          ]
        },
        {
          "cityId": 730843,
          "parentId": 714401,
          "name": "桃园市",
          "child": [{
              "cityId": 730844,
              "parentId": 730843,
              "name": "八德区"
            },
            {
              "cityId": 731212,
              "parentId": 730843,
              "name": "大溪区"
            },
            {
              "cityId": 731471,
              "parentId": 730843,
              "name": "大园区"
            },
            {
              "cityId": 731767,
              "parentId": 730843,
              "name": "復兴区"
            },
            {
              "cityId": 731835,
              "parentId": 730843,
              "name": "观音区"
            },
            {
              "cityId": 732079,
              "parentId": 730843,
              "name": "龟山区"
            },
            {
              "cityId": 732469,
              "parentId": 730843,
              "name": "龙潭区"
            },
            {
              "cityId": 732800,
              "parentId": 730843,
              "name": "芦竹区"
            },
            {
              "cityId": 733144,
              "parentId": 730843,
              "name": "平镇区"
            },
            {
              "cityId": 733179,
              "parentId": 730843,
              "name": "桃园区"
            },
            {
              "cityId": 733390,
              "parentId": 730843,
              "name": "新屋区"
            },
            {
              "cityId": 733537,
              "parentId": 730843,
              "name": "杨梅区"
            },
            {
              "cityId": 733876,
              "parentId": 730843,
              "name": "中坜区"
            }
          ]
        },
        {
          "cityId": 734179,
          "parentId": 714401,
          "name": "宜兰县",
          "child": [{
              "cityId": 734180,
              "parentId": 734179,
              "name": "大同乡"
            },
            {
              "cityId": 734246,
              "parentId": 734179,
              "name": "钓鱼臺"
            },
            {
              "cityId": 734248,
              "parentId": 734179,
              "name": "冬山乡"
            },
            {
              "cityId": 734579,
              "parentId": 734179,
              "name": "礁溪乡"
            },
            {
              "cityId": 734681,
              "parentId": 734179,
              "name": "罗东镇"
            },
            {
              "cityId": 734842,
              "parentId": 734179,
              "name": "南澳乡"
            },
            {
              "cityId": 734865,
              "parentId": 734179,
              "name": "三星乡"
            },
            {
              "cityId": 735104,
              "parentId": 734179,
              "name": "苏澳镇"
            },
            {
              "cityId": 735319,
              "parentId": 734179,
              "name": "头城镇"
            },
            {
              "cityId": 735419,
              "parentId": 734179,
              "name": "五结乡"
            },
            {
              "cityId": 735620,
              "parentId": 734179,
              "name": "宜兰市"
            },
            {
              "cityId": 735851,
              "parentId": 734179,
              "name": "员山乡"
            },
            {
              "cityId": 735970,
              "parentId": 734179,
              "name": "壮围乡"
            }
          ]
        },
        {
          "cityId": 736051,
          "parentId": 714401,
          "name": "南投县",
          "child": [{
              "cityId": 736052,
              "parentId": 736051,
              "name": "草屯镇"
            },
            {
              "cityId": 736305,
              "parentId": 736051,
              "name": "国姓乡"
            },
            {
              "cityId": 736356,
              "parentId": 736051,
              "name": "集集镇"
            },
            {
              "cityId": 736449,
              "parentId": 736051,
              "name": "鹿谷乡"
            },
            {
              "cityId": 736522,
              "parentId": 736051,
              "name": "名间乡"
            },
            {
              "cityId": 736622,
              "parentId": 736051,
              "name": "南投市"
            },
            {
              "cityId": 736887,
              "parentId": 736051,
              "name": "埔里镇"
            },
            {
              "cityId": 737266,
              "parentId": 736051,
              "name": "仁爱乡"
            },
            {
              "cityId": 737337,
              "parentId": 736051,
              "name": "水里乡"
            },
            {
              "cityId": 737496,
              "parentId": 736051,
              "name": "信义乡"
            },
            {
              "cityId": 737533,
              "parentId": 736051,
              "name": "鱼池乡"
            },
            {
              "cityId": 737591,
              "parentId": 736051,
              "name": "中寮乡"
            },
            {
              "cityId": 737625,
              "parentId": 736051,
              "name": "竹山镇"
            }
          ]
        },
        {
          "cityId": 737856,
          "parentId": 714401,
          "name": "南海岛",
          "child": [{
              "cityId": 737857,
              "parentId": 737856,
              "name": "东沙群岛"
            },
            {
              "cityId": 737859,
              "parentId": 737856,
              "name": "南沙群岛"
            }
          ]
        },
        {
          "cityId": 737861,
          "parentId": 714401,
          "name": "苗栗县",
          "child": [{
              "cityId": 737862,
              "parentId": 737861,
              "name": "头屋乡"
            },
            {
              "cityId": 737894,
              "parentId": 737861,
              "name": "西湖乡"
            },
            {
              "cityId": 737948,
              "parentId": 737861,
              "name": "苑里镇"
            },
            {
              "cityId": 738050,
              "parentId": 737861,
              "name": "造桥乡"
            },
            {
              "cityId": 738158,
              "parentId": 737861,
              "name": "竹南镇"
            },
            {
              "cityId": 738454,
              "parentId": 737861,
              "name": "卓兰镇"
            },
            {
              "cityId": 738528,
              "parentId": 737861,
              "name": "大湖乡"
            },
            {
              "cityId": 738619,
              "parentId": 737861,
              "name": "公馆乡"
            },
            {
              "cityId": 738695,
              "parentId": 737861,
              "name": "后龙镇"
            },
            {
              "cityId": 738882,
              "parentId": 737861,
              "name": "苗栗市"
            },
            {
              "cityId": 739250,
              "parentId": 737861,
              "name": "南庄乡"
            },
            {
              "cityId": 739302,
              "parentId": 737861,
              "name": "三湾乡"
            },
            {
              "cityId": 739369,
              "parentId": 737861,
              "name": "三义乡"
            },
            {
              "cityId": 739419,
              "parentId": 737861,
              "name": "狮潭乡"
            },
            {
              "cityId": 739465,
              "parentId": 737861,
              "name": "泰安乡"
            },
            {
              "cityId": 739487,
              "parentId": 737861,
              "name": "铜锣乡"
            },
            {
              "cityId": 739564,
              "parentId": 737861,
              "name": "通霄镇"
            },
            {
              "cityId": 739642,
              "parentId": 737861,
              "name": "头份市"
            }
          ]
        },
        {
          "cityId": 739957,
          "parentId": 714401,
          "name": "嘉义市",
          "child": [{
              "cityId": 739958,
              "parentId": 739957,
              "name": "东区"
            },
            {
              "cityId": 740140,
              "parentId": 739957,
              "name": "西区"
            }
          ]
        },
        {
          "cityId": 740510,
          "parentId": 714401,
          "name": "嘉义县",
          "child": [{
              "cityId": 740511,
              "parentId": 740510,
              "name": "阿里山乡"
            },
            {
              "cityId": 740536,
              "parentId": 740510,
              "name": "布袋镇"
            },
            {
              "cityId": 740625,
              "parentId": 740510,
              "name": "大林镇"
            },
            {
              "cityId": 740746,
              "parentId": 740510,
              "name": "大埔乡"
            },
            {
              "cityId": 740792,
              "parentId": 740510,
              "name": "东石乡"
            },
            {
              "cityId": 740845,
              "parentId": 740510,
              "name": "番路乡"
            },
            {
              "cityId": 740943,
              "parentId": 740510,
              "name": "六脚乡"
            },
            {
              "cityId": 740975,
              "parentId": 740510,
              "name": "鹿草乡"
            },
            {
              "cityId": 741010,
              "parentId": 740510,
              "name": "梅山乡"
            },
            {
              "cityId": 741137,
              "parentId": 740510,
              "name": "民雄乡"
            },
            {
              "cityId": 741312,
              "parentId": 740510,
              "name": "朴子市"
            },
            {
              "cityId": 741451,
              "parentId": 740510,
              "name": "水上乡"
            },
            {
              "cityId": 741550,
              "parentId": 740510,
              "name": "太保市"
            },
            {
              "cityId": 741646,
              "parentId": 740510,
              "name": "溪口乡"
            },
            {
              "cityId": 741688,
              "parentId": 740510,
              "name": "新港乡"
            },
            {
              "cityId": 741750,
              "parentId": 740510,
              "name": "义竹乡"
            },
            {
              "cityId": 741785,
              "parentId": 740510,
              "name": "中埔乡"
            },
            {
              "cityId": 741936,
              "parentId": 740510,
              "name": "竹崎乡"
            }
          ]
        },
        {
          "cityId": 742126,
          "parentId": 714401,
          "name": "新竹市",
          "child": [{
              "cityId": 742127,
              "parentId": 742126,
              "name": "东区"
            },
            {
              "cityId": 742309,
              "parentId": 742126,
              "name": "北区"
            }
          ]
        },
        {
          "cityId": 742636,
          "parentId": 714401,
          "name": "新竹县",
          "child": [{
              "cityId": 742637,
              "parentId": 742636,
              "name": "峨眉乡"
            },
            {
              "cityId": 742674,
              "parentId": 742636,
              "name": "关西镇"
            },
            {
              "cityId": 742797,
              "parentId": 742636,
              "name": "横山乡"
            },
            {
              "cityId": 742852,
              "parentId": 742636,
              "name": "湖口乡"
            },
            {
              "cityId": 743201,
              "parentId": 742636,
              "name": "尖石乡"
            },
            {
              "cityId": 743246,
              "parentId": 742636,
              "name": "芎林乡"
            },
            {
              "cityId": 743298,
              "parentId": 742636,
              "name": "五峰乡"
            },
            {
              "cityId": 743319,
              "parentId": 742636,
              "name": "新丰乡"
            },
            {
              "cityId": 743414,
              "parentId": 742636,
              "name": "新埔镇"
            },
            {
              "cityId": 743527,
              "parentId": 742636,
              "name": "竹北市"
            },
            {
              "cityId": 743565,
              "parentId": 742636,
              "name": "竹东镇"
            },
            {
              "cityId": 743725,
              "parentId": 742636,
              "name": "宝山乡"
            },
            {
              "cityId": 743888,
              "parentId": 742636,
              "name": "北埔乡"
            }
          ]
        },
        {
          "cityId": 743938,
          "parentId": 714401,
          "name": "花莲县",
          "child": [{
              "cityId": 743939,
              "parentId": 743938,
              "name": "卓溪乡"
            },
            {
              "cityId": 743956,
              "parentId": 743938,
              "name": "丰滨乡"
            },
            {
              "cityId": 743993,
              "parentId": 743938,
              "name": "凤林镇"
            },
            {
              "cityId": 744128,
              "parentId": 743938,
              "name": "富里乡"
            },
            {
              "cityId": 744185,
              "parentId": 743938,
              "name": "光復乡"
            },
            {
              "cityId": 744246,
              "parentId": 743938,
              "name": "花莲市"
            },
            {
              "cityId": 744625,
              "parentId": 743938,
              "name": "吉安乡"
            },
            {
              "cityId": 745050,
              "parentId": 743938,
              "name": "瑞穗乡"
            },
            {
              "cityId": 745196,
              "parentId": 743938,
              "name": "寿丰乡"
            },
            {
              "cityId": 745354,
              "parentId": 743938,
              "name": "万荣乡"
            },
            {
              "cityId": 745363,
              "parentId": 743938,
              "name": "新城乡"
            },
            {
              "cityId": 745486,
              "parentId": 743938,
              "name": "秀林乡"
            },
            {
              "cityId": 745532,
              "parentId": 743938,
              "name": "玉里镇"
            }
          ]
        },
        {
          "cityId": 745674,
          "parentId": 714401,
          "name": "高雄市",
          "child": [{
              "cityId": 745675,
              "parentId": 745674,
              "name": "阿莲区"
            },
            {
              "cityId": 745715,
              "parentId": 745674,
              "name": "大寮区"
            },
            {
              "cityId": 746083,
              "parentId": 745674,
              "name": "大社区"
            },
            {
              "cityId": 746199,
              "parentId": 745674,
              "name": "大树区"
            },
            {
              "cityId": 746294,
              "parentId": 745674,
              "name": "凤山区"
            },
            {
              "cityId": 746624,
              "parentId": 745674,
              "name": "冈山区"
            },
            {
              "cityId": 746906,
              "parentId": 745674,
              "name": "鼓山区"
            },
            {
              "cityId": 747053,
              "parentId": 745674,
              "name": "湖内区"
            },
            {
              "cityId": 747108,
              "parentId": 745674,
              "name": "甲仙区"
            },
            {
              "cityId": 747150,
              "parentId": 745674,
              "name": "苓雅区"
            },
            {
              "cityId": 747342,
              "parentId": 745674,
              "name": "林园区"
            },
            {
              "cityId": 747481,
              "parentId": 745674,
              "name": "六龟区"
            },
            {
              "cityId": 747536,
              "parentId": 745674,
              "name": "路竹区"
            },
            {
              "cityId": 747643,
              "parentId": 745674,
              "name": "茂林区"
            },
            {
              "cityId": 747647,
              "parentId": 745674,
              "name": "美浓区"
            },
            {
              "cityId": 747764,
              "parentId": 745674,
              "name": "弥陀区"
            },
            {
              "cityId": 747894,
              "parentId": 745674,
              "name": "那玛夏区"
            },
            {
              "cityId": 747902,
              "parentId": 745674,
              "name": "楠梓区"
            },
            {
              "cityId": 748258,
              "parentId": 745674,
              "name": "内门区"
            },
            {
              "cityId": 748344,
              "parentId": 745674,
              "name": "鸟松区"
            }
          ]
        },
        {
          "cityId": 748553,
          "parentId": 714401,
          "name": "基隆市",
          "child": [{
              "cityId": 748554,
              "parentId": 748553,
              "name": "安乐区"
            },
            {
              "cityId": 748581,
              "parentId": 748553,
              "name": "暖暖区"
            },
            {
              "cityId": 748599,
              "parentId": 748553,
              "name": "七堵区"
            },
            {
              "cityId": 748670,
              "parentId": 748553,
              "name": "仁爱区"
            },
            {
              "cityId": 748716,
              "parentId": 748553,
              "name": "信义区"
            },
            {
              "cityId": 748920,
              "parentId": 748553,
              "name": "中山区"
            },
            {
              "cityId": 749226,
              "parentId": 748553,
              "name": "中正区"
            }
          ]
        },
        {
          "cityId": 749571,
          "parentId": 714401,
          "name": "金门县",
          "child": [{
              "cityId": 749572,
              "parentId": 749571,
              "name": "金城镇"
            },
            {
              "cityId": 749647,
              "parentId": 749571,
              "name": "金湖镇"
            },
            {
              "cityId": 749752,
              "parentId": 749571,
              "name": "金宁乡"
            },
            {
              "cityId": 749810,
              "parentId": 749571,
              "name": "金沙镇"
            },
            {
              "cityId": 749894,
              "parentId": 749571,
              "name": "烈屿乡"
            },
            {
              "cityId": 749928,
              "parentId": 749571,
              "name": "乌坵乡"
            }
          ]
        },
        {
          "cityId": 749930,
          "parentId": 714401,
          "name": "连江县",
          "child": [{
              "cityId": 749931,
              "parentId": 749930,
              "name": "北竿乡"
            },
            {
              "cityId": 749938,
              "parentId": 749930,
              "name": "东引乡"
            },
            {
              "cityId": 749941,
              "parentId": 749930,
              "name": "莒光乡"
            },
            {
              "cityId": 749947,
              "parentId": 749930,
              "name": "南竿乡"
            }
          ]
        },
        {
          "cityId": 749957,
          "parentId": 714401,
          "name": "云林县",
          "child": [{
              "cityId": 749958,
              "parentId": 749957,
              "name": "褒忠乡"
            },
            {
              "cityId": 749991,
              "parentId": 749957,
              "name": "北港镇"
            },
            {
              "cityId": 750170,
              "parentId": 749957,
              "name": "莿桐乡"
            },
            {
              "cityId": 750218,
              "parentId": 749957,
              "name": "大埤乡"
            },
            {
              "cityId": 750291,
              "parentId": 749957,
              "name": "东势乡"
            },
            {
              "cityId": 750363,
              "parentId": 749957,
              "name": "斗六市"
            },
            {
              "cityId": 750795,
              "parentId": 749957,
              "name": "斗南镇"
            },
            {
              "cityId": 751009,
              "parentId": 749957,
              "name": "二崙乡"
            },
            {
              "cityId": 751071,
              "parentId": 749957,
              "name": "古坑乡"
            },
            {
              "cityId": 751147,
              "parentId": 749957,
              "name": "虎尾镇"
            },
            {
              "cityId": 751400,
              "parentId": 749957,
              "name": "口湖乡"
            },
            {
              "cityId": 751493,
              "parentId": 749957,
              "name": "林内乡"
            },
            {
              "cityId": 751555,
              "parentId": 749957,
              "name": "崙背乡"
            },
            {
              "cityId": 751674,
              "parentId": 749957,
              "name": "麦寮乡"
            },
            {
              "cityId": 751764,
              "parentId": 749957,
              "name": "水林乡"
            },
            {
              "cityId": 751832,
              "parentId": 749957,
              "name": "四湖乡"
            },
            {
              "cityId": 751907,
              "parentId": 749957,
              "name": "臺西乡"
            },
            {
              "cityId": 751956,
              "parentId": 749957,
              "name": "土库镇"
            },
            {
              "cityId": 752034,
              "parentId": 749957,
              "name": "西螺镇"
            },
            {
              "cityId": 752149,
              "parentId": 749957,
              "name": "元长乡"
            }
          ]
        }
      ]
    }
  ]