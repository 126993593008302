<template>
  <div>
    <el-dialog
      title="支付"
      :visible.sync="show"
      :close-on-click-modal="false"
      @close="cancelPay(3)"
      custom-class="dialog-minwidth"
      :modal-append-to-body="false"
      width="700px"
    >
    <div class="top">
        <h2>微信支付</h2> 二维码有效期5分钟，请尽快支付
    </div>
    <div class="info">
        <div class="infoItem">
            <img class="baseImage" :src="Image" alt="" style="width: 200px; height: 200px" />
            <div class="infoItemBot">
                <img :src="require('@/assets/codesss.png')" alt="">
                <span>请使用微信扫一扫<br>扫码二维码支付</span>
            </div>
        </div>
        <div class="infoItem infoItemMoney" v-if="type==2">
            <div class="money">支付金额：<span>￥</span>{{price}}</div> 
            <div class="oeder">订单号：{{orderNo}}</div>
        </div>
    </div>
    <div style="height:30px"></div>
    </el-dialog>
  </div>
</template>
<script>
export default {
    data(){
        return{
            secendNo: 60,
            timer: null
        }
    },
    props:['orderNo','Image','price','show','type','totalInfo'],
    watch:{
        show(val){
            if(val){
                if (!this.timer) {
                    this.secendNo = 60;
                    this.timer = setInterval(() => {
                    if (this.secendNo > 0 && this.secendNo <= 60) {
                        this.getResult()
                        this.secendNo--;
                    } else {
                        this.$emit('finshed',1)
                        clearInterval(this.timer);
                        this.timer = null;
                    }
                    }, 5000);
                }
            }
        }
    },
    methods:{
        getResult(){
            this.https.get('/api/front/pay/queryPayResult?orderNo='+this.orderNo).then(res=>{
                if(res.data.code == 200){
                    this.$emit('finshed',2)
                    clearInterval(this.timer);
                }
            })
        },
        cancelPay(index){
            this.$emit('finshed',index)
            clearInterval(this.timer);
        }
    }
}
</script>
<style lang="scss" scoped>
.top{
    line-height: 50px;
    display: flex;
    h2{
        font-size: 18px;
        margin-right: 10px;
    }
}
.info{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    .infoItem{
        width: 50%;
        &:first-child{
            border-right: 1px dashed #ddd;
        }
        .baseImage{
            border: 1px solid #ddd;
            padding: 15px;
        }
        .infoItemBot{
            margin: 20px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                width: 40px;
                height: 40px;
                margin-right: 10px;
            }
            line-height: 24px;
            color: #999;
        }
    }
    .infoItemMoney{
        text-align: left;
        padding-left: 50px;
        line-height: 40px;
        flex-direction: column;
        .name{
            font-size: 16px;
        }
        .money{
            font-size: 20px;
            span{
                font-size: 14px;
            }
        }
        .order{
            color: #999;
        }
    }
}
</style>